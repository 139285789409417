import { reduxStore } from "../../Redux/ReduxStore";
import environment from "../../Utils/env";

export const MARKET_PLACES = [
  { id: "CA", name: "Canada" },
  { id: "US", name: "United States" },
];
export const getUserLocationCountry = () => {
  const userLocale = navigator.language || navigator.userLanguage;
  const defaultCountry = window.location.hostname.includes(
    environment.CADANA_DOMAIN
  )
    ? environment.CADANA_DOMAIN
    : environment.USA_DOMAIN;
  // Extract the country code from the locale
  const countryCode = MARKET_PLACES.find(
    ({ id }) => id === userLocale.split("-")[1]
  )
    ? userLocale.split("-")[1]
    : defaultCountry; // Default to 'US' if no country code is present

  return countryCode;
};
export const getDomainCountryCode = () => {
  return window.location.hostname.includes(environment.CADANA_DOMAIN)
    ? "CA"
    : "US";
};
export const getUserDefaultCountry = () => {
  return reduxStore.getState((state) => state.location.defaultCountry);
};
