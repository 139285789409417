import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "../../Utils/AuthContext";
import { COLORS } from "../../Utils/context/ThemeContext";

export default function ForgotPasswordForm(props) {
  const { handleClose, handleOpen, open } = props;
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [btnDisable, setBtnDisable] = useState("");

  const handleChange = (e) => {
    setErrMsg("");
    setSuccessMsg("");
    setEmail(e.target.value);
  };
  const { resetPassword } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setBtnDisable(true);
      await resetPassword(email);
      setSuccessMsg("Check your inbox/spam folder for further instruction");
      setEmail("");
    } catch (err) {
      setErrMsg(err.code.split("/")[1]);
    }
    setBtnDisable(false);
  };
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => handleClose("forgotPassword")}
      aria-labelledby="console.log-dialog-slide-title"
      aria-describedby="console.log-dialog-slide-description"
    >
      <Form className="ForgotPasswordForm" onSubmit={handleSubmit}>
        <i
          className="fa fa-arrow-circle-left"
          aria-hidden="true"
          title="Back to Login"
          onClick={() => {
            handleOpen("login");
            handleClose("forgotPassword");
          }}
          style={{color:COLORS.primary.main}}
        ></i>
        {errMsg && <Alert variant="danger">{errMsg}</Alert>}
        {successMsg && <Alert variant="success">{successMsg}</Alert>}
        <Form.Group>
          <Form.Label className="text-center">
            Please enter your registered email address and we will help you to
            reset your password.
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Button
          disabled={btnDisable}
          type="submit"
          className="w-100"
          style={{
            backgroundColor: COLORS.primary.main,
            color:'white'
          }}
        >
          Reset Password
        </Button>
        <div className="otherFormOptions">
          <p
            className="loginFormLink"
            onClick={() => {
              handleOpen("register");
              handleClose("forgotPassword");
            }}
          >
            New User ? Sign Up here !
          </p>
        </div>
      </Form>
    </Dialog>
  );
}
