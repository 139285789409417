import React from "react";

function TermsOfUse() {
  return (
    <>
      <div class="wpb_wrapper mt-4">
        <div className="d-flex justify-content-center">
          <h2 className="mt-4">Terms of Use</h2>
        </div>
        <p>
          These terms contain legal obligations and form an agreement between
          you and SHOPIADS Inc, as applicable (collectively, the “SHOPIADS,”
          “us,” “we,” or “our”). Please read these terms carefully before using,
          accessing or browsing any of our properties.
        </p>
        <p>These terms were last updated on: Mar 7, 2022.</p>
        <p>
          <strong>Access and Use</strong>
        </p>
        <p>
          These are the main terms of use for the SHOPIADS companies and
          community brands, and cover all related websites, properties,
          offerings, applications and services that do not have a separate set
          of terms. By accessing, browsing, downloading, enabling, installing or
          otherwise using any SHOPIADS Company website, digital property, audio
          offering, mobile application and/or mobile enabled site that does not
          have a separate set of terms, including SHOPIADS.ca, and any related
          applications (collectively, the <b>"Properties"</b> and each a{" "}
          <b>"Property"</b>); or any product, content, feature, software or
          service available in or on a Property (each a <b>"Service,"</b> and
          collectively as defined below, the <b>"Services"</b>), you agree
          without limitation or qualification to be bound by these terms and
          such other terms and conditions, rules and policies which you may be
          directed to or are displayed in connection with the use of a Property
          or Service, all as amended and updated from time to time
          (collectively, the <b>“Terms”</b>). If you have not read, or do not
          understand, or do not agree to these Terms, you may not use the
          Properties or Services.
        </p>
        <p>
          We reserve the right, in our sole discretion, to change these Terms,
          in whole or part, at any time. Except as otherwise provided in these
          Terms, any change will be effective when notice of the change is
          posted on a property. Please check back regularly for updates by
          checking the date of the last update above. Your continued use of any
          Property or Service following the posting of amended Terms will mean
          you accept those changes. We reserve the right to modify, suspend,
          remove or discontinue any of the Properties, Services, or Content (as
          defined below) or any part of them from time to time without notice or
          liability for any reason whatsoever. We also reserve the right at any
          time to charge fees for access to, and to change any limits applicable
          to obtain free access to, all or any portion of the Properties,
          Services or Content. In such event, although your access to the
          applicable Property, Service or Content may be restricted or denied,
          you will not be charged for access unless we obtain your prior
          agreement to pay such charges.
        </p>
        <p>
          You may not access or use, or attempt to access or use, the Properties
          or Services to take any action that could harm us or a third party.
          You may not access parts of the Properties or Services which you are
          not authorized to access. You may not attempt to circumvent any
          restriction or condition imposed on your use or access, or do anything
          that could disable or damage the functioning or appearance of the
          Properties, Services, or Content, including the presentation or
          display of advertising. Being exposed to advertising is a condition of
          your accessing the Properties, Services, and Content.
        </p>
        <p>
          <strong>Intellectual Property and Use of Materials</strong>
        </p>
        <p>
          The Properties and Services and all content made available thereon,
          including articles, stories, text, photographs, images, illustrations,
          audio and video clips and other content (collectively, the "Content")
          are the property of the applicable SHOPIADS Company or its licensors
          and are protected, without limitation, pursuant to copyright,
          trademark and other laws. Subject to your compliance with these Terms,
          the SHOPIADS Companies grant you a limited, personal, non-exclusive,
          revocable, non-assignable and non-transferable license to download,
          display or use a single copy of the applicable Property, Service and
          Content on your computer or other personal electronic device. This
          license is for personal, non-commercial, and educational purposes
          only. You cannot use any Property, Service or Content in connection
          with any commercial purpose, unless you obtain our written consent in
          advance. You are not permitted to alter or modify, or remove any
          copyright notice from, any Property, Service or Content. No other use
          is permitted and all other rights not expressly granted in these Terms
          are reserved. Nothing contained herein shall grant any license or
          right to use any Content in any manner other than as explicitly set
          forth in these Terms without the prior written consent of the
          applicable SHOPIADS Company or such third party that may own the
          Content displayed on the Property or Service. Any unauthorized use of
          any of the Content is strictly prohibited. Except as provided herein,
          you agree not to reproduce, sell, republish, broadcast, distribute,
          make derivative works of or otherwise make available any Content,
          including by framing, caching or other similar means, without the
          prior written consent of the applicable SHOPIADS Company, and, if
          applicable, the copyright owner of such Content.
        </p>
        <p>
          The Properties and Services and all content made available thereon,
          including articles, stories, text, photographs, images, illustrations,
          audio and video clips and other content (collectively, the{" "}
          <b>"Content"</b>) are the property of the applicable SHOPIADS Company
          or its licensors and are protected, without limitation, pursuant to
          copyright, trademark and other laws. Subject to your compliance with
          these Terms, the SHOPIADS Companies grant you a limited, personal,
          non-exclusive, revocable, non-assignable and non-transferable license
          to download, display or use a single copy of the applicable Property,
          Service and Content on your computer or other personal electronic
          device. This license is for personal, non-commercial, and educational
          purposes only. You cannot use any Property, Service or Content in
          connection with any commercial purpose, unless you obtain our written
          consent in advance. You are not permitted to alter or modify, or
          remove any copyright notice from, any Property, Service or Content. No
          other use is permitted and all other rights not expressly granted in
          these Terms are reserved. Nothing contained herein shall grant any
          license or right to use any Content in any manner other than as
          explicitly set forth in these Terms without the prior written consent
          of the applicable SHOPIADS Company or such third party that may own
          the Content displayed on the Property or Service. Any unauthorized use
          of any of the Content is strictly prohibited. Except as provided
          herein, you agree not to reproduce, sell, republish, broadcast,
          distribute, make derivative works of or otherwise make available any
          Content, including by framing, caching or other similar means, without
          the prior written consent of the applicable SHOPIADS Company, and, if
          applicable, the copyright owner of such Content.
        </p>
        <p>
          <strong>Trademarks</strong>
        </p>
        <p>
          All trademarks and trade names used, mentioned or displayed on the
          Properties or in the Services are trademarks or registered trademarks
          of the applicable SHOPIADS Company or of their respective owners. The
          display of trademarks or trade names on each of the Properties does
          not convey or create any license or other rights in these marks or
          names. Any unauthorized use of these trademarks and trade names is
          strictly prohibited.
        </p>
        <p>
          <strong>Registration</strong>
        </p>
        <p>
          To post comments, make submissions or access Content on, or to use
          some of the Services and Properties, you may be required to register.
          Should you choose to register, you agree to provide accurate, complete
          and current information about yourself as required by the registration
          process, and to promptly update such information as necessary to
          ensure that it is kept accurate and complete.
        </p>
        <p>
          We allow you to register using certain social media login credentials,
          such as Facebook Google and Apple. Should you choose to do so, you
          agree that we may collect, use and disclose your information from the
          social media account in accordance with these Terms and our Privacy
          Policy. Please refer to the privacy settings of the social media
          website or application to determine how you may adjust our permissions
          and manage the interactivity between us and your social media account.
        </p>
        <p>
          You agree to be responsible for: (a) maintaining the confidentiality
          of any passwords or other account identifiers which you choose or are
          assigned as a result of any registration on the Properties, and (b)
          all activities, transactions, subscriptions and purchases that are
          affected, made or incurred on third party sites is their
          responsibility and you should follow third party terms and conditions.
        </p>
        <p>
          If you provide us with any information that is untrue or inaccurate or
          we have reasonable grounds to suspect that such information is untrue
          or inaccurate, we have the right to suspend or terminate your account
          and/or your access to any or all of the Properties or Services. Any
          fraudulent, abusive, or otherwise illegal activity may be grounds for
          termination of your account, at our sole discretion, and we may refer
          you to appropriate law enforcement agencies.
        </p>
        <p>
          Accounts are offered for individual use only. Registration is strictly
          restricted to one account per person. You may not pretend to be
          someone else or register on behalf of other people. You may not use
          registration credentials that we deem offensive or otherwise
          inappropriate, in our sole discretion. You may not share your
          registration information with or give your account login credentials
          to anyone else. Your account may be suspended or terminated and
          services, products or coupons you have requested or obtained may be
          withheld, deleted, withdrawn or invalidated without notice if there is
          any suspicion that you or anyone in your household has misused your
          account, registered for multiple or fraudulent accounts, shared login
          credentials or breached any other portion of these Terms.
        </p>
        <p>
          Our servers are placed on google cloud in jurisdiction of Canada, United States.
          However, You acknowledge that, due to the nature of the Services,
          information or data uploaded to the Services may be hosted on servers
          residing in jurisdictions other than Canada, United States over which SHOPIADS has
          no direct control. During the period that they are hosted on such
          servers, subject to the laws of the jurisdiction in which those
          servers reside and/or to the terms of agreements respecting the
          hosting of data on such servers. Although SHOPIADS has made reasonable
          efforts to verify that its agreements with such server providers are
          reasonably protective of your data, you acknowledge that SHOPIADS has
          no liability for any acts or omissions of third parties in relation to
          such servers and the data stored on them. You therefore hereby release
          SHOPIADS from all liability for any governmental or third-party action
          taken in such jurisdictions with respect to such data and/or the
          servers on which such data resides.
        </p>
        <p>
          <strong>Termination</strong>
        </p>
        <p>
          You may terminate this Agreement at any time upon notice to SHOPIADS.
          Upon any termination of this Agreement for any reason (whether by you
          or by SHOPIADS), you must cease all use of the Services and Software
          and destroy and/or permanently delete all copies of the Software in
          your possession. of Paid Services will be governed by the applicable
          subscription terms.
        </p>
        <p>
          You agree that we, in our sole discretion, may temporarily suspend or
          permanently terminate your password, account, or use of any or all of
          the Properties or Services and remove and discard any User Postings
          (as defined below) at our convenience or for any reason, including if
          we believe you have violated or may violate these Terms. You agree
          that any suspension and/or termination of your access to or use of the
          Properties or the Services may be affected without prior notice and
          that we will not be liable to you or to any other person as a result
          of any such suspension or termination.
        </p>
        <p>
          <strong>User Control and Uninstall</strong>
        </p>
        <p>
          You can access and change certain user settings for the Properties and
          Services by following the instructions applicable to your device.
          Uninstallation methods will vary depending on your device. For further
          information on user settings or uninstallation or if you have other
          questions or concerns about the Properties or Services or these Terms,
          please consult your device manual/troubleshooting guide for reference
          or contact us at{" "}
          <a href="mailto:support@shopiads.com">support@shopiads.com</a>
        </p>
        <p>
          <strong>User Representations and Warranties</strong>
        </p>
        <p>
          You represent and warrant that: (i) you are not a minor and you have
          the legal right and capacity to enter into these Terms in your
          jurisdiction and to comply with these Terms or, if you are a minor,
          your parent or legal guardian has read and agreed to these Terms on
          your behalf; (ii) all information you provide to us, directly or
          indirectly, is accurate and complete; (iii) you hold and will continue
          to hold all rights necessary to enter into and perform your
          obligations under these Terms; (iv) You are not listed on Canadian
          Government list of prohibited or restricted parties; and (v) you are
          complying and will continue to comply with any applicable third party
          terms of agreement when accessing or using any Property or Service
          (e.g., you have not and will not violate your wireless provider’s data
          service agreement when accessing or using the Services or Properties).
        </p>
        <p>
          In cases where you have authorized a minor to use a Property or
          Service you recognize that you are fully responsible for: (i) the
          conduct of such minor; (ii) controlling the minor's access to and use
          of a Property or Service; and (iii) the consequences of any misuse by
          the minor. You acknowledge that some of the areas or portions of a
          Property or Service, as well as some content other users may post on
          or within the Properties or Services, may contain material that is
          inappropriate for minors.
        </p>
        <p>
          <strong>User Conduct, Indemnification and License Granted</strong>
        </p>
        <p>
          All information, data, text, software, graphics, images, avatars,
          video, audio, applications, code, photographs, messages, ideas,
          reviews, opinions, comments, submissions, suggestions or other
          materials created by users on any part of the Properties or Services,
          including any comment forums or chat boards, (<b>"User Postings"</b>)
          are the responsibility of the user creating the User Postings.{" "}
          <b>
            THE SHOPIADS COMPANIES HAVE NO RESPONSIBILITY FOR USER POSTINGS AND
            DO NOT GUARANTEE OR PROVIDE ANY WARRANTY HOWEVER WE AUDIT THE BILL
            OF PURCHASE FROM THE POSTER
          </b>
        </p>
        <p>
          You acknowledge and agree that we may preserve User Postings and may
          also disclose any related information as necessary to satisfy any law,
          regulation or governmental request, comply with legal process, and
          enforce these Terms or to protect ourselves, our users and/or the
          public.
        </p>
        <p>
          You hereby represent and warrant that you have all necessary rights in
          and to all User Postings you provide, and that such User Postings
          shall not infringe any proprietary or other rights of third parties or
          contain any libelous, tortious, or otherwise unlawful information.
          Without limiting the generality of the foregoing, you agree not to
          post, transmit, link to, upload or otherwise submit or distribute any
          User Postings that:
        </p>
        <ul style={{ marginLeft: "40px" }}>
          <li>
            infringe any proprietary or other rights of third parties, including
            any copyright, trademark, patent, trade secret, privacy or other
            proprietary or property right;
          </li>
          <li>
            defame, abuse, stalk, harass, threaten or otherwise violate the
            legal rights of others, including rights relating to privacy and
            publicity;
          </li>
          <li>
            include any material that is inappropriate, profane, defamatory,
            infringing, obscene, pornographic, indecent, abusive, violent,
            exploitative, tortious or otherwise unlawful;
          </li>
          <li>
            denigrate a class of people because of their race, religion, country
            of origin, sexual orientation, disability, age or gender;
          </li>
          <li>
            depict violent or criminal acts, or seek to incite violence or
            crime;
          </li>
          <li>
            include any material that relates or pertains to any "hate group,"
            for example, groups that are organized in part to promote the
            oppression of or assert the supremacy of any class of people;
          </li>
          <li>
            include a photograph of another person or personal information of
            another person or use that person's correspondence, diaries or
            personal documents without that person's consent;
          </li>
          <li>
            involve or promote commercial activities or seek solicitations on
            behalf of charities or donations of any kind, including promoting
            any contests, sweepstakes, barter, advertising or franchise, pyramid
            scheme, multi-level marketing opportunity, "club membership",
            distributorship or sales representative agency arrangement or other
            business opportunity;
          </li>
          <li>
            further or promote any criminal activity or enterprise or provide
            instructional information about illegal activities including making
            or buying illegal weapons, violating someone's privacy, or providing
            or creating computer viruses; or
          </li>
          <li>
            include any material or undertake any activity that could constitute
            a criminal offence, give rise to civil liability or otherwise
            violate any applicable law;
          </li>
          <li>distribute or contain spam, chain letters or pyramid schemes;</li>
          <li>
            harvest or otherwise collect or use any information about other
            users of the applicable Property or Service, including email
            addresses or phone numbers, without their consent and without
            complying with all applicable legal requirements;
          </li>
          <li>
            are false or misleading, including in the locator, sender
            information or subject matter of an electronic message;
          </li>
          <li>
            contain opinions, notices or discussion topics that would be more
            appropriately posted on a social media site;
          </li>
          <li>
            contain any keywords that are not directly related to the item you
            are offering;
          </li>
          <li>
            contain or use any "deep-link", "page-scrape", "robot", "spider" or
            other automatic device, program, algorithm or methodology, or any
            similar or equivalent manual process, to access, acquire, copy or
            monitor any portion of a Property, Service or Content, or in any way
            reproduce or circumvent the navigational structure or presentation
            of such Property, Service or Content, to obtain or attempt to obtain
            any materials, documents or information through any means not
            purposely made available through the Property, Service or Content
            without our express written consent;
          </li>
        </ul>
        <p>
          You do not have permission to add other users of a Property or Service
          to any mailing or e-mail list for commercial purposes, even if a user
          has purchased something from you, unless the user has provided you
          with consent in accordance with all applicable laws.
        </p>
        <p>
          <b>
            YOU AGREE TO INDEMNIFY AND HOLD HARMLESS EACH APPLICABLE SHOPIADS
            COMPANY, ITS PARENT, AFFILIATES, AND RELATED COMPANIES AND THEIR
            RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS FROM AND
            AGAINST ANY AND ALL CLAIMS, ACTIONS OR DEMANDS, INCLUDING REASONABLE
            LEGAL AND ACCOUNTING FEES ARISING FROM OR RELATED TO: ANY BREACH OF
            THESE TERMS; YOUR ACCESS TO OR USE OF ANY PROPERTY, SERVICE, OR
            CONTENT; YOUR BREACH OF ANY APPLICABLE LAW (INCLUDING ANY ANTI-SPAM
            LAW); OR YOUR USE OF OR RELIANCE ON OR PUBLICATION, COMMUNICATION OR
            DISTRIBUTION OF ANYTHING FROM THE PROPERTIES, SERVICES OR CONTENT.
            YOU SHALL USE YOUR BEST EFFORTS TO COOPERATE WITH US IN THE DEFENCE
            OF ANY CLAIM. WE RESERVE THE RIGHT, AT OUR OWN EXPENSE, TO ASSUME
            THE EXCLUSIVE CONTROL OF ANY MATTER OTHERWISE SUBJECT TO
            INDEMNIFICATION BY YOU.
          </b>
        </p>
        <p>
          By contributing User Postings to the Properties, you give (or warrant
          that the owner of such rights has expressly given) to us and our
          affiliates a perpetual, irrevocable, nonexclusive, worldwide,
          royalty-free, sub-licensable right and license to host, cache, store,
          copy, tag, index, encode, use, reproduce, modify, edit, adapt
          (including the right to adapt to streaming, downloading, broadcast,
          mobile, digital, thumbnail, scanning or other technologies), excerpt,
          publish, translate, broadcast, transmit, create derivative works from,
          distribute, sell, re-sell, perform, display, publicly perform and
          publicize such User Postings anywhere, for any purpose and in any
          form, media or technology now known or later developed, without any
          notice or compensation to you. You also grant us the right to
          sub-license, transfer and authorize others to exercise any of the
          rights granted to us under these Terms; and each such third party will
          be entitled to benefit from the rights and licenses granted to us
          under these Terms. You further authorize us to publish your User
          Postings in a searchable format that may be accessed by users of the
          Properties, the Services and the Internet. Such license will apply
          with respect to any form, media or technology now known or later
          developed. In addition, you hereby waive any moral rights in such
          materials and User Postings, or where applicable, warrant they have
          been waived by the appropriate third party. You further agree that we
          are free to use any ideas, concepts, know-how, suggestions, or
          techniques contained in any of the User Postings for any purpose
          whatsoever including developing, manufacturing and marketing products
          using such information, without compensation to you.
        </p>
        <p>
          <strong>Links to Third Party Sites</strong>
        </p>
        <p>
          The Properties, Services and Content may contain links or references
          to other sites, services, offerings or applications that are not part
          of the Properties or Services and which are not controlled by the
          SHOPIADS Companies. The inclusion of a link does not imply endorsement
          by us. If you decide to visit any linked site, service, offering or
          application, you do so at your own risk, and it is your responsibility
          to take all protective measures and to guard against viruses and other
          destructive elements and any collection, use and disclosure of your
          personal information through such site, service, offering or
          application.{" "}
          <b>
            WE WILL NOT BE RESPONSIBLE OR LIABLE IN ANY WAY FOR THE LEGALITY, OR
            DECENCY OF MATERIAL CONTAINED IN OR ON ANY SITE, SERVICE, OFFERING
            OR APPLICATION LINKED FROM A PROPERTY OR FOR ANY INFORMATION OR
            MATERIAL YOU PROVIDE TO ANY THIRD PARTY.
          </b>
        </p>
        <p>
          Unless otherwise indicated on a property, we are not sponsored by or
          affiliated with any linked sites, services, offerings or applications,
          and trademarks used in connection with linked sites, services,
          offerings or applications are not our trademarks.
        </p>
        <p>
          <strong>Professional Information Disclaimer</strong>
        </p>
        <p>
          The Properties, Services and Content may contain certain facts,
          opinions, views, statements, recommendations and information which may
          be provided by third parties including third parties in professional
          fields such as medicine, law, engineering, accounting, architecture,
          financial planning, investments, other health and fitness related
          areas and other professional fields (<b>"Professional Information"</b>
          ). Use of any Property, Service or Content does not replace
          consultations with qualified medical, legal, engineering, accounting,
          architectural, financial planning, investment, or other professionals.
          Professional Information may be subject to change and could be out of
          date. You acknowledge that any reliance on such Professional
          Information is at your sole risk and that we do not provide you with
          or endorse any medical, health, tax, legal, accounting, investment or
          other professional advice or advocate the purchase or sale of any
          security or investment. You further acknowledge that you are solely
          responsible for your own research and decisions, including investment
          and financial planning decisions.
        </p>
        <p>
          You agree that we shall not be responsible or liable in any way for
          any loss or damage of any kind directly or indirectly incurred arising
          out of, relating to, or in connection with the use of, or reliance on,
          any Professional Information.
        </p>
        <p>
          <strong>Third Party Advertisements</strong>
        </p>
        <p>
          Third party advertisers and other third-party content providers,
          including merchants, vendors, sellers, service providers and
          third-party providers of related information, content and videos
          (each, an <b>"Advertiser"</b> and collectively, <b>"Advertisers"</b>)
          may offer goods, services, content, information and other materials to
          you on the Properties. Your correspondence and business dealings with
          Advertisers including the payment for and delivery of goods and
          services, and any terms, conditions, warranties and representations
          associated with such dealings, are solely between you and the
          Advertiser. You agree that we will not be responsible or liable for
          any loss or damage of any sort incurred as the result of any such
          dealings or the offering of such goods, services, content, information
          or other materials on the Properties. Your dealings or communications
          through the Properties with any party other than a SHOPIADS Company
          are solely between you and that third party. If you choose to
          correspond or transact with Advertisers or other third parties through
          our Properties or Services, you do so at your own risk. You and the
          appropriate third party, not the SHOPIADS Companies, are responsible
          for compliance with all laws applicable in any such transaction. It is
          your responsibility to use caution and common sense, and to evaluate
          the accuracy, completeness, reliability or usefulness of any
          information, opinion, advice, claim, representation or other content
          made available by any Advertisers or other third parties through the
          Properties or Services. Under no circumstances will we be liable for
          any goods, services, resources, information or content made available
          by or to any third party or through such third-party dealings or
          communications, or for any harm related thereto. Please review
          carefully any third party's policies and practices and make sure you
          are comfortable with those policies and practices before you engage in
          any transaction. Any complaints, concerns or questions you may have
          relating to materials, products, information, content or services
          provided by third parties should be forwarded directly to the third
          party.
        </p>
        <p>
          <strong>Applicable Law</strong>
        </p>
        <p>These Terms of Use are governed by federal and provincial laws.</p>
        <p>
          <strong>Contact Details</strong>
        </p>
        <p>
          Please contact us if you have any questions or concerns using Contact
          Us form available on our site or write to us at{" "}
          <a href="mailto:compliance@shopiads.com">compliance@shopiads.com</a>
        </p>
      </div>
    </>
  );
}

export default TermsOfUse;
