import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Link,useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';

import googlePlay from "../../Assets/google-play.png";
import appleStore from "../../Assets/app-store.png";




const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function DownloadApp() {
  const classes = useStyles();
  

  

 
  

  return (
    <>

      <div className="container-fluid my-5 pt-5">
       <div className="row">
          <div className="col-md-12">
          <h2 class="mb-3 text-center">Download For</h2>
          <div className="store-type">
            <a href=""><img src={googlePlay}></img></a>
            <a href=""><img src={appleStore}></img></a>
          </div>
          </div>
       </div>

      
      </div>
    </>
  );
}

export default DownloadApp;