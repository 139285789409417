import React, { useState, useEffect } from "react";
import API from "../../../Utils/API";
import { useAuth } from "../../../Utils/AuthContext";
import { getToken } from "../../../Utils/FirebaseConfig";

export default function Notifications() {
  const { userInfo } = useAuth();
  const fetchFCMToken = async () => {
    getToken().then((response) => {
      console.log({
        response,
      });
      if (response && userInfo?.email) {
        API.updateUserWebToken(userInfo.email, response);
      }
    });
  };
  useEffect(() => {
    fetchFCMToken();
  }, []);
return <></>
}
