import React from "react";
import { useSelector } from "react-redux";
import googlePlay from "../../Assets/google-play.png";
import appleStore from "../../Assets/app-store.png";
function MobileHomePage() {
  const { defaultCountry } = useSelector((state) => state.location);
  return (
    <>
      <div className="container-fluid my-5 pt-5">
        <div className="row">
          <div className="col-md-12">
            <div
              class="vertical-stack product"
              data-page-type="universal-link-product"
            >
              <div class="product-card">
                <div>
                  <h3>Mobile users please download App</h3>
                </div>
              </div>

              <div class="universal-link-product-app-details-desktop horizontal-stack">
                <div class="horizontal-stack universal-link-product-store-logos">
                  <a
                    class="universal-link-product-app-store-link js-interaction-button"
                    data-interaction-type="ios-download"
                    href={
                      defaultCountry === "US"
                        ? "https://apps.apple.com/us/app/shopiads/id1601835714"
                        : "https://apps.apple.com/ca/app/shopiads/id1601835714"
                    }
                  >
                    <img class="image" sizes="100vw" src={appleStore} alt="" />
                  </a>
                  <a
                    class="universal-link-product-play-store-link js-interaction-button"
                    data-interaction-type="android-download"
                    href="https://play.google.com/store/apps/details?id=com.apcod.shopiads"
                  >
                    <img class="image" sizes="100vw" src={googlePlay} alt="" />
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileHomePage;
