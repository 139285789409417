import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import { useHistory, useRouteMatch } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ReactPaginate from "react-paginate";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import API from "../../../Utils/API";
import ProductCard from "../product/ProductCard";
import { useAuth } from "../../../Utils/AuthContext";
import "../Container.css";
import { Select } from "@material-ui/core";
import CustomerSelectOption from "../../UI/Input/CustomerSelectOption";
import CustomSelect from "../../UI/Container/CustomSelect";

const leftPaginationArrow = (
  <ArrowBackIosIcon style={{ margin: 0, marginLeft: 8, color: "#1d6042" }} />
);
const rightPaginationArrow = (
  <ArrowForwardIosIcon style={{ margin: 0, color: "#1d6042" }} />
);
;
const filterColumns = [
  { value: "Relevance", key: "relevance,true" },
  { value: "Price : Low to High", key: "offerPrice,true" },
  { value: "Price : High to Low", key: "offerPrice,false" },
  { value: "Rating", key: "avgRating,false" },
  // { value: "Rating: Descending", key: "avgRating,false" },
];
const filledStar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="#ffc902"
    className="bi bi-star-fill"
    viewBox="0 0 16 16"
  >
    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
  </svg>
);
const emptyStar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="#ffc902"
    className="bi bi-star"
    viewBox="0 0 16 16"
  >
    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"></path>
  </svg>
);
function Search(props) {
  const { marketPlace: selectedRefMarketPlace, defaultCountry } = useSelector(
    (state) => state.location
  );
  const { userInfo } = useAuth();
  const { params } = useRouteMatch()
  const { subCategory } = useSelector((state) => state.stores);
  const [subCategories, setSubCategories] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchPageResult, setSearchPageResult] = useState([]);
  const [searchTotalResult, setSearchTotalResult] = useState();
  const [loder, setLoder] = useState(false);
  const [toggle, setMoreToggle] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    text: Buffer.from(params.searchText,'hex').toString(),
    catId: params.searchCategory,
    fromPrice: null,
    toPrice: null,
    rate: null,
  });
  // TODO: NEED TO ADD FROM BCAKEND
  const [order, setOrder] = useState("relevance,true");
  const [totalPages, setTotalPages] = useState(0);
  const [searchMaxPrice, setSearchMaxPrice] = useState();
  const [searchMinPrice, setSearchMinPrice] = useState();
  const [searchCategory, setSearchCategory] = useState();
  const [subcategory, setSubcategory] = useState([]);
  const [resultcategory, setResultcategory] = useState([]);
  const [resultDepartment, setResultDepartment] = useState([]);
  const [selectedRateFilter, setSelectedRateFilter] = useState();
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState();
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(32);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    fetchProducts();
  }, [filterConfig]);
  useEffect(() => {
    setSearchMaxPrice("");
    setSearchMinPrice("");
    setSelectedRateFilter("");
    setSelectedCategoryFilter("");
    setOrder("relevance,true");
    // TODO : LEGACY CODE NEED TO UPGRADE
    var checkboxes = document.querySelectorAll(
      "input[name=catCheckBox]:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkboxes[i].checked = false;
      }
    }
    setFilterConfig({ text: Buffer.from(params.searchText,'hex').toString(), catId: params.catId,orderBy:null,asc:null });
  }, [params]);
  const getCurrentMarketPlace = () => {
    const selectedRefMarket = selectedRefMarketPlace || defaultCountry;
    return selectedRefMarket;
  };
  const generateFetchQuery = (pageId) => {
    const mandatoryFields = ["text", "catId"];
    const queryParams = {
      ...filterConfig,
      recordPerPage: perPage,
      pageId,
      marketPlace: getCurrentMarketPlace(),
      text: btoa(filterConfig.text.replaceAll(/\W/g," "))
    };
    let isValid =
      mandatoryFields.filter((item) => queryParams[item]).length ===
      mandatoryFields.length;
    if (isValid) {
      return Object.keys(queryParams)
        .filter((key) => queryParams[key] !== null)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");
    }
    return null;
  };
  const generateFetchQueryFromFilter = () => {
    const mandatoryFields = ["text", "catId"];
    const queryParams = {
      ...filterConfig,
      recordPerPage: perPage,
      pageId: 0,
      marketPlace: getCurrentMarketPlace(),
      text: btoa(filterConfig.text.replaceAll(/\W/g," ")),
    };
    let isValid =
      mandatoryFields.filter((item) => queryParams[item] !== undefined)
        .length === mandatoryFields.length;
    if (isValid) {
      return Object.keys(queryParams)
        .filter((key) => queryParams[key] !== null)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");
    }
    return null;
  };
  const fetchProducts = async (pageId) => {
    let productResponse = { };
    setLoder(true);
    try {
      if (pageId) {
        productResponse  = await API.getProductSearch(
          generateFetchQuery(pageId)
        );
      } else {
        productResponse = await API.getProductSearch(
          generateFetchQueryFromFilter()
        );
      }
      const { content, totalPages, pageable: { pageNumber } } = productResponse
      setTotalPages(totalPages);
      setCurrentPage(pageNumber)
      if (content.length < 0) return;
      if (content.length > 0) {
        getCategoryMetadata(content);
      }
      setSearchResult(content);
      setSearchPageResult(content);
    } catch (e) {
      productResponse = [];
      setSearchResult([]);
      setSearchPageResult([]);
    } finally {
      setLoder(false);
      window.scrollTo(0, 0);
    }
  };
  const getCategoryMetadata = async (products) => {
    const userId = userInfo?.email || "gust";
    const filterCategories = [];
    for (var i = 0; i < products.length; i++) {
      if (products[i].categories?.length > 0) {
        filterCategories.push(products[i].categories[0]);
      }
    }
    const filterSubCategoryList = await Promise.all(
      filterCategories.map((cat) => API.getCategoriesByIdAsync(userId, cat))
    );
    setResultcategory(filterCategories);
    var dataArr = filterSubCategoryList.map((item) => {
      return [item.name, item];
    });
    var maparr = new Map(dataArr);
    var resultDep = [...maparr.values()];
    setResultDepartment(resultDep);
  };
  const handleSubCategory = (e, id) => {
    var checkboxes = document.querySelectorAll(
      "input[name=catCheckBox]:checked"
    );
    var checkboxesChecked = [];
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkboxesChecked.push(checkboxes[i].value);
      }
    }
    setSubcategory(subCategories.push(id));
    if (checkboxesChecked.length > 0) {
      setFilterConfig((config) => ({
        ...config,
        subCatId: checkboxesChecked.join(),
      }));
      setSelectedCategoryFilter(checkboxesChecked.join());
    } else {
      setFilterConfig((config) => ({ ...config, subCatId: "" }));
      setSelectedCategoryFilter("undefined");
    }
  };
  function handleSortChanage(e) {
    const [orderBy,asc] = e.target.value.split(",")
    if (e.target.value) {
      if (e.target.value === "relevance,true") {
        setFilterConfig((config) => ({ ...config, orderBy: null, asc: null }));
      } else {
        setFilterConfig((config) => ({ ...config, orderBy, asc }));
      }
      setOrder(e.target.value)      
    }
  }

  function getKeyByValue(object, value) {
    for (var prop in object) {
      if (object.hasOwnProperty(prop)) {
        for (var i = 0; i <= object[prop].length; i++) {
          if (
            object[prop][i] != undefined &&
            object[prop][i] != null &&
            object[prop][i].id === value
          ) {
            if (!containsNumbers(prop)) return prop;
          }
        }
      }
    }
  }
  function containsNumbers(str) {
    return /\d/.test(str);
  }
  const handlePageClick = (e) => {
    fetchProducts(e.selected);
  };
  const clearConfig = () => {
    setSearchMaxPrice("");
    setSearchMinPrice("");
    setSelectedRateFilter("");
    setSelectedCategoryFilter("");
    setOrder("relevance,true");
    var checkboxes = document.querySelectorAll(
      "input[name=catCheckBox]:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkboxes[i].checked = false;
      }
    }
    setFilterConfig({ text: Buffer.from(params.searchText,'hex').toString(), catId: params.catId });
  };
  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="row">
              <div className="col-md-2">
                <div className="shopee-filter-panel">
                  <div className="shopee-search-filter-status">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                    >
                      <path
                        fill="none"
                        fill-rule="evenodd"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M20 0H0l8 9.46V16l4 2V9.46z"
                        transform="translate(1 1)"
                      />
                    </svg>
                    <div className="shopee-search-filter-status__text">
                      Search Filter
                    </div>
                  </div>
                  {resultDepartment?.length > 0 && (
                    <div className="shopee-filter-group">
                      <div className="shopee-filter-group__header">
                        by Department
                      </div>
                      <div className="shopeee-filter-group__body">
                        <div className="shopee-filter shopee-checkbox-filter">
                          {params.catId === undefined ||
                          "all" === params.catId ? (
                            <div className="shopee-checkbox">
                              <label className="shopee-checkbox__control">
                                {resultDepartment != undefined &&
                                resultDepartment.length > 0
                                  ? resultDepartment.map((item, index) => (
                                      <li className="category-column-section1">
                                        {subCategory != undefined
                                          ? getKeyByValue(subCategory, item.id)
                                          : ""}

                                        <ul className="category-section-items-list1">
                                          <li>
                                            <Form.Check
                                              onChange={(e) => {
                                                handleSubCategory(e, item.id);
                                              }}
                                              name="catCheckBox"
                                              value={item.id}
                                              type="checkbox"
                                              label={item.name}
                                            />
                                          </li>
                                        </ul>
                                      </li>
                                    ))
                                  : ""}
                              </label>
                            </div>
                          ) : (
                            <div className="shopee-checkbox">
                              <label className="shopee-checkbox__control">
                                {subCategory != undefined &&
                                subCategory[searchCategory] != undefined
                                  ? subCategory[searchCategory].map(
                                      (item, index) =>
                                        subCategory[item.id] != undefined &&
                                        subCategory[item.id]
                                          .map((subitem, index1) =>
                                            resultcategory.includes(subitem.id)
                                          )
                                          .some((item) => item === true) ? (
                                          <li className="category-column-section1">
                                            {/*item.name
                                             */}
                                            <ul className="category-section-items-list1">
                                              {subCategory[item.id] != undefined
                                                ? subCategory[item.id].map(
                                                    (subitem, index1) =>
                                                      resultcategory.includes(
                                                        subitem.id
                                                      ) ? (
                                                        <li>
                                                          <Form.Check
                                                            onChange={(e) => {
                                                              handleSubCategory(
                                                                e,
                                                                subitem.id
                                                              );
                                                            }}
                                                            name="catCheckBox"
                                                            value={subitem.id}
                                                            type="checkbox"
                                                            label={subitem.name}
                                                          />
                                                        </li>
                                                      ) : (
                                                        ""
                                                      )
                                                  )
                                                : ""}
                                            </ul>
                                          </li>
                                        ) : (
                                          ""
                                        )
                                    )
                                  : ""}
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="shopee-filter-group">
                    <div className="shopee-filter-group__header">
                      Price Range
                    </div>
                    <div className="shopeee-filter-group__body">
                      <div className="shopee-filter shopee-input ">
                        <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                          <div>
                            <label className="small">$ MIN</label>
                            <input
                              type="number"
                              value={searchMinPrice}
                              onInput={(e) => {
                                setSearchMinPrice(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  setFilterConfig((config) => ({
                                    ...config,
                                    fromPrice: searchMinPrice,
                                    toPrice: searchMaxPrice,
                                  }));
                                }
                              }}
                              className="form-control"
                              placeholder="$"
                            ></input>
                          </div>
                          <hr style={{ width: "60px" }} className="mx-1" />
                          <div>
                            <label className="small">$ MAX</label>
                            <input
                              type="number"
                              value={searchMaxPrice}
                              onInput={(e) => {
                                setSearchMaxPrice(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  setFilterConfig((config) => ({
                                    ...config,
                                    fromPrice: searchMinPrice,
                                    toPrice: searchMaxPrice,
                                  }));
                                }
                              }}
                              className="form-control"
                              placeholder="$"
                            ></input>
                          </div>
                        </div>
                        <div
                          className="shopee-filter-group border-bottom-0"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{ flex: 1 }}
                            onClick={() => {
                              setFilterConfig((config) => ({
                                ...config,
                                fromPrice: searchMinPrice,
                                toPrice: searchMaxPrice,
                              }));
                            }}
                            color="primary"
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="shopee-filter-group border-bottom-0">
                    <div className="shopee-filter-group__header">
                      Buyer Ratings
                    </div>
                    <div className="shopeee-filter-group__body">
                      {[5, 4, 3, 2, 1].map((item) => (
                        <div
                          onClick={() => {
                            setFilterConfig((config) => ({
                              ...config,
                              rate: item,
                            }));
                          }}
                          className="d-flex align-items-center my-2"
                          style={{ cursor: "pointer" }}
                        >
                          <div
                            className="filter-ratings"
                            style={{
                              borderColor:
                                filterConfig.rate === item
                                  ? "#1d6042"
                                  : "lightgray",
                              borderWidth:
                                filterConfig.rate === item ? "2px" : "1px",
                            }}
                          >
                            {Array(item)
                              .fill("star")
                              .map((item) => filledStar)}
                            {Array(5 - item)
                              .fill("star")
                              .map((item) => emptyStar)}
                            {item !== 5 && (
                              <div className="rating ml-2">& Up</div>
                            )}
                          </div>
                          {/* <div className="rating ml-2">2 Rating</div> */}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="shopee-filter-group border-bottom-0"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={clearConfig}
                      color="primary"
                      style={{ flex: 1 }}
                    >
                      RESET
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-md-10">
                <div className="shopee-sort-bar mb-3">
                  <h1 className="shopee-search-result-header">
                    <svg
                      style={{ height: "32px", width: "46px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      enable-background="new 0 0 64 64"
                      viewBox="0 0 64 64"
                    >
                      <path
                        d="M58.86,37.33h-2.76c-0.79,0-1.43-0.64-1.43-1.43c0-0.79,0.64-1.43,1.43-1.43h1.33V8.81H2.87v25.66h21.54
			c0.79,0,1.43,0.64,1.43,1.43c0,0.79-0.64,1.43-1.43,1.43H1.43C0.64,37.33,0,36.69,0,35.9V7.37c0-0.79,0.64-1.43,1.43-1.43h57.42
			c0.79,0,1.43,0.64,1.43,1.43V35.9C60.29,36.69,59.65,37.33,58.86,37.33z"
                      />
                      <path
                        d="M40.17,47.38c-7.12,0-12.92-5.8-12.92-12.92c0-7.12,5.8-12.92,12.92-12.92c7.12,0,12.92,5.8,12.92,12.92
				C53.09,41.59,47.3,47.38,40.17,47.38z M40.17,24.41c-5.54,0-10.05,4.51-10.05,10.06c0,5.54,4.51,10.05,10.05,10.05
				c5.54,0,10.05-4.51,10.05-10.05C50.23,28.92,45.72,24.41,40.17,24.41z"
                      />
                      <path
                        d="M62.57,58.06c-0.37,0-0.74-0.14-1.02-0.43L47.5,43.36c-0.56-0.56-0.55-1.47,0.02-2.03c0.56-0.55,1.47-0.55,2.03,0.02
				l14.05,14.27c0.56,0.56,0.55,1.47-0.02,2.03C63.29,57.92,62.93,58.06,62.57,58.06z"
                      />
                    </svg>
                    <span className="shopee-search-result-header__text">
                      Results for (
                      <span className="shopee-search-result-header__text-highlight">
                        {Buffer.from(params.searchText, "hex").toString()}
                      </span>
                      )
                    </span>
                  </h1>

                  <div className="sort-options">
                    <span className="shopee-sort-bar__label">Sort by</span>
                    <CustomSelect
                      value={order}
                      onChange={handleSortChanage}
                      className="form-control w-75"
                    >
                      {filterColumns.map((item) => (
                        <CustomerSelectOption
                          selected={item.key === order}
                          value={`${item.key}`}
                        >
                          {item.value}
                        </CustomerSelectOption>
                      ))}
                    </CustomSelect>
                  </div>
                </div>
                <div className="row">
                  {!loder
                    ? searchResult != undefined && searchResult.length > 0
                      ? searchPageResult.map((item, index) => (
                          <div className="col-md-3">
                            <div className="offer-card-main">
                              <ProductCard
                                branchName={item.storeName}
                                storeId={item.storeId}
                                productId={item.id}
                                img={item.images}
                                branchLogo={item.logo}
                                heading={item.heading}
                                avgRating={item.avgRating}
                                discount={item.discountPerc}
                                actualPrice={item.actualPrice}
                                offerPrice={item.offerPrice}
                                totalLikes={item.totalLikes}
                                key={item.id}
                              />
                            </div>
                          </div>
                        ))
                      : "No Search Result Found......."
                    : "Searching................."}
                </div>
              </div>
              {totalPages > 1 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ReactPaginate
                    forcePage={currentPage}
                    pageCount={totalPages}
                    pageRangeDisplayed={3} // Number of pages to display in the pagination
                    marginPagesDisplayed={1} // Number of pages to display on the edges
                    previousLabel={leftPaginationArrow}
                    nextLabel={rightPaginationArrow}
                    breakLabel={"..."}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    disabledClassName={"disabled"}
                    page
                    onPageChange={handlePageClick}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
