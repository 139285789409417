import environment from "../../Utils/env";
import { LocationActionTypes } from "./LocationActionTypes";

const initialState = {
  loading: false,
  location: "",
  cities: [],
  areas: [],
  error: "",
  userId: "guest",
  stateId: "7c3deffa992d4fb7ba80a345c62ca612",
  cityId: "",
  cityName: "",
  areaId: "",
  marketPlace: window.location.host.includes(environment.CADANA_DOMAIN)
    ? "Canada"
    : "United States",
  defaultCountry: null,
};
const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LocationActionTypes.SET_MARKET_PLACE:
      return {
        ...state,
        marketPlace: action.payload,
      };
    case LocationActionTypes.SET_DAFAULT_COUNTRY:
      return {
        ...state,
        defaultCountry: action.payload,
      };
    case LocationActionTypes.MAKE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LocationActionTypes.SET_LOCATION:
      return {
        ...state,
        loading: false,
        location: action.payload,
      };
    case LocationActionTypes.SET_CITIES:
      return {
        ...state,
        loading: false,
        cities: action.payload,
      };
    case LocationActionTypes.SET_CITYID:
      return {
        ...state,
        cityId: action.payload,
      };
    case LocationActionTypes.SET_CITYNAME:
      return {
        ...state,
        cityName: action.payload,
      };
    case LocationActionTypes.SET_STATEID:
      return {
        ...state,
        stateId: action.payload,
      };
    case LocationActionTypes.SET_AREAS:
      return {
        ...state,
        loading: false,
        areas: action.payload,
      };
    case LocationActionTypes.SET_AREAID:
      return {
        ...state,
        areaId: action.payload,
      };
    case LocationActionTypes.ERROR:
      return {
        ...state,
        loading: false,
        location: "",
        cities: [],
        areas: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default locationReducer;
