import { Tooltip } from "antd";
import moment from "moment";
import "moment-timezone";
import React, { useMemo } from "react";
import Moment from "react-moment";
const getTimeStampByHours = (time24hr) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const dateTimeStr = `${currentDate} ${time24hr}`;
  return moment(dateTimeStr, "YYYY-MM-DD HH:mm");
};
const TimeRow = ({ time }) => { 
    const day = useMemo(() => { 
        if (time) { 
            return time.substring(0, time.indexOf(":") + 1);
        } else { 
            return ""
        }
    }, [time])
    const fromHours = useMemo(() => {
      if (time) {
        return time.substring(time.indexOf(":") + 1).split("-")[0].trim();
      } else {
        return "";
      }
    }, [time]);
    const toHours = useMemo(() => {
      if (time) {
        return time
          .substring(time.indexOf(":") + 1)
          .split("-")[1]
          .trim();
      } else {
        return "";
      }
    }, [time]);
    
    return (
      <li style={{ color: "grey" }}>
        ● {day} 
        <Moment tz="CST" format="hh:mm A">
          {getTimeStampByHours(fromHours)}
        </Moment>
        {` - `}
        <Moment tz="CST" format="hh:mm A">
          {getTimeStampByHours(toHours)}
        </Moment>
      </li>
    );
}
export default function TimeCard({ hours, timeNote, closed }) {
  return (
    <>
      <div className="section-item-icon-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="bi bi-stopwatch shopee-svg-icon"
          viewBox="0 0 16 16"
          stroke-width="0"
        >
          <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
          <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
        </svg>
      </div>
      <div className="section-seller-item-text">
        <div className="section-seller-item-text-value">
          {`Timing: `}
          <Moment tz="CST" format="hh:mm A">
            {getTimeStampByHours(hours.opening)}
          </Moment>
          {`  - `}
          <Moment tz="CST" format="hh:mm A">
            {getTimeStampByHours(hours.closing)}
          </Moment>
          <Tooltip
            placement="bottom"
            color={"white"}
            title={
              <React.Fragment>
                {timeNote?.split(",").map((item) => (
                  <TimeRow time={item} />
                ))}
                {closed && (
                  <li style={{ color: "Red" }}>● {`Closed : ${closed.join(", ")}`}</li>
                )}
              </React.Fragment>
            }
          >
            <i className="fa fa-info-circle ml-2"></i>
          </Tooltip>
        </div>
      </div>
    </>
  );
}
