import React, { useEffect, useState } from "react";
import API from "../../../Utils/API";
import { useAuth } from "../../../Utils/AuthContext";
import StoresHeader from "./StoresHeader";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

const StoreFollwing = () => {
  const { userInfo } = useAuth();
  const [followList, setFollowList] = useState([]);
  const [followStoreList, setFollowStoreList] = useState([]);
  const { allStoreList } = useSelector((state) => state.stores);
  const history = useHistory();

  useEffect(() => {
    API.getStoreFollow(
      userInfo != null && userInfo != undefined ? userInfo.email : "gust",
      "follow"
    )
      .then((result) => {
        if (result != undefined && result.length > 0) {
          console.log('data==' , result.data);
          setFollowList(result);
          if (
            allStoreList != undefined &&
            allStoreList.length > 0 &&
            result != undefined &&
            result.length > 0
          ) {
            //const isSameStore = (allStoreList, result) => allStoreList.id == result.storeId;
            const isSameStore = allStoreList.filter(({ id: id1 }) =>
              result.some(({ storeId: id2 }) => id2 == id1)
            );
            setFollowStoreList(isSameStore);
          }
        } else {
          setFollowList([]);
          setFollowStoreList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userInfo.email]);

  const cardClick = (newValue) => {
    history.push(`/storeDetails/` + newValue);
    // console.log("Under Development..............")
  };

  const unFollowClick = (newValue) => {
    if (followList != undefined && followList.length > 0) {
      const obj = followList.filter((item) => item.storeId == newValue);
      if (obj != undefined && obj.length > 0) {
        API.deleteSnip(userInfo?.email, obj[0])
          .then((deleteResult) => {
            API.getStoreFollow(
              userInfo != null && userInfo != undefined
                ? userInfo.email
                : "gust",
              "follow"
            )
              .then((result) => {
                console.log("result.data>>>>>>>>>>>>>>>>>>", result);
                console.log(
                  "allStoreList>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
                  allStoreList
                );
                if (result != undefined && result.length > 0) {
                  //console.log('data=='+result.data);
                  setFollowList(result);
                  if (
                    allStoreList != undefined &&
                    allStoreList.length > 0 &&
                    result != undefined &&
                    result.length > 0
                  ) {
                    //const isSameStore = (allStoreList, result) => allStoreList.id == result.storeId;
                    const isSameStore = allStoreList.filter(({ id: id1 }) =>
                      result.some(({ storeId: id2 }) => id2 == id1)
                    );
                    setFollowStoreList(isSameStore);
                    console.log(
                      "isSameStore>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
                      isSameStore
                    );
                  }
                } else {
                  setFollowList([]);
                  setFollowStoreList([]);
                }
              })
              .catch((error) => {
                console.log(error);
                // console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
            // console.log(error);
          });
      }
    }
    // console.log("Under Development..............")
  };

  return (
    <>
      {/* <StoresHeader /> */}
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="shop-header-section--simple">
              <div class="shop-header-section__header">
                <div class="shop-header-section__header__title">
                  Following Store{" "}
                  <span>
                    <span class="search-user-page__header-text-highlight"></span>
                  </span>
                </div>
              </div>
              <div className="shop-header-section__content">
                <div className="shop-search-user-item mt-4">
                  {followStoreList != undefined && followStoreList.length > 0
                    ? followStoreList.map((item, index) => (
                        <div class="shop-search-user-item shopee-search-user-item--full d-flex">
                          <a
                            class="shop-search-user-item__leading"
                            onClick={() => {
                              cardClick(item.id);
                            }}
                            href="#"
                          >
                            <div class="shop-avatar mr-3">
                              <div class="shop-avatar__placeholder"></div>
                              <div class="_25_r8I shop-avatar__img-wrapper">
                                <div
                                  class="shop-avatar__img _2GchKS"
                                  style={{
                                    backgroundImage:
                                      "url(" + item.companyLogo + ")",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                  }}
                                ></div>
                              </div>
                            </div>
                            {/*} <div class="shop-search-user-item__leading__prefer-badge-wrapper">
         <div class="official-shop-new-badge"><img class="qutJvu" src="https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg//assets/483071c49603aa7163a7f51708bff61b.png" loading="lazy" width="64" height="16"/></div>
              </div>*/}
                          </a>
                          <a
                            class="shop-search-user-item__shop-info"
                            onClick={() => {
                              cardClick(item.id);
                            }}
                            href="#"
                          >
                            <div class="shop-search-user-item__nickname">
                              {item.storeName}
                            </div>
                            <div class="shop-search-user-item__username">
                              {item.address2} {item.address1},{item.cityId}
                            </div>
                            {/* <div class="shop-search-user-item__follow-count"><span class="shop-search-user-item__follow-count-number">93.6k</span><span> followers</span><span> | </span><span class="shop-search-user-item__follow-count-number">1</span><span> following</span></div> */}
                          </a>
                          {/* <div class="shop-search-user-item__statistics">
      <div class="shop-search-user-seller-info-item">
         <div class="shop-search-user-seller-info-item__wrapper">
            <div class="shop-search-user-seller-info-item__header">
               <svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="shop-svg-icon icon-products">
                  <g>
                     <path d="m10 1 4.5 2.5-.5 3h-2v7.5h-9v-7.5h-2l-.5-3 4.6-2.5c.3 1.1 1.3 1.9 2.4 1.9s2.1-.8 2.5-1.9z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></path>
                     <line fill="none" stroke-linecap="round" stroke-miterlimit="10" x1="3" x2="12" y1="11.5" y2="11.5"></line>
                  </g>
               </svg>
               <span class="shop-search-user-seller-info-item__primary-text">2.3k</span>
            </div>
            <div class="shop-search-user-seller-info-item__complement-text">products</div>
         </div>
      </div>
      <div class="shop-search-user-seller-info-item">
         <div class="shop-search-user-seller-info-item__wrapper">
            <div class="shop-search-user-seller-info-item__header">
               <svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="shop-svg-icon icon-rating">
                  <polygon fill="none" points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
               </svg>
               <span class="shop-search-user-seller-info-item__primary-text">{item.avgRating != undefined && Number(item.avgRating) > 0
                    ? item.avgRating
                    : 0}</span>
            </div>
            <div class="shop-search-user-seller-info-item__complement-text">Ratings</div>
         </div>
      </div>
   </div> */}
                          <div class="shop-search-user-item__buttons">
                            <a
                              href="#"
                              onClick={() => {
                                cardClick(item.id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              view store
                            </a>
                            <button
                              onClick={() => {
                                unFollowClick(item.id);
                              }}
                              class="shop-button-outline"
                            >
                              unfollow
                            </button>
                          </div>
                        </div>
                      ))
                    : "No  Result Found......."}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreFollwing;
