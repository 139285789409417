import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import { getUserSnips, removeSnipsData } from "../../Redux/Snips/SnipsAction";
import API from "../../Utils/API";
import { useAuth } from "../../Utils/AuthContext";
import Checkbox from "@material-ui/core/Checkbox";
import StoresHeader from "./Stores/StoresHeader";
import Header from "../UI/Container/Header";

function PrivacyPolicy() {
  return (
    <>
      <div class="wpb_wrapper" style={{paddingTop:'15px'}}>
        <>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Helvetica;\n\tpanose-1:2 11 6 4 2 2 2 2 2 4;}\n@font-face\n\t{font-family:Wingdings;\n\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n /* Style Definitions */\n p.MsoNormal ml-4, li.MsoNormal ml-4, div.MsoNormal ml-4\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:8.0pt;\n\tmargin-left:0cm;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Calibri",sans-serif;}\n.MsoChpDefault\n\t{font-family:"Calibri",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:8.0pt;\n\tline-height:107%;}\n@page WordSection1\n\t{size:612.0pt 792.0pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n',
            }}
          />
          <div className="WordSection1">
            <p
              className="MsoNormal"
              align="center"
              style={{
                marginBottom: "12.0pt",
                textAlign: "center",
                lineHeight: "45.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "30.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Privacy Policy
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                Last updated: March 15, 2024
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                This Privacy Policy describes Our policies and procedures on the
                collection, use and disclosure of Your information when You use
                the Service and tells You about Your privacy rights and how the
                law protects You.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                We use Your Personal data to provide and improve the Service. By
                using the Service, You agree to the collection and use of
                information in accordance with this Privacy Policy.{" "}
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "45.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "30.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Interpretation and Definitions
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "36.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "24.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Interpretation
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "36.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "24.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Definitions
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                For the purposes of this Privacy Policy:
              </span>
            </p>
            <ul style={{ marginTop: "0cm" }} type="disc">
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Account
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;means a unique account created for You to access our
                  Service or parts of our Service.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Affiliate
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;means an entity that controls, is controlled by or is
                  under common control with a party, where "control" means
                  ownership of 50% or more of the shares, equity interest or
                  other securities entitled to vote for election of directors or
                  other managing authority.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Application
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;means the software program provided by the Company
                  downloaded by You on any electronic device, named Shopiads
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Company
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;(referred to as either "the Company", "We", "Us" or
                  "Our" in this Agreement) refers to ShopiAds Inc, Mississauga,
                  ON L5B 3W4.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Country
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;refers to: Canada, United State
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Device
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;means any device that can access the Service such as a
                  computer, a cellphone or a digital tablet.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Personal Data
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;is any information that relates to an identified or
                  identifiable individual.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Service
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;refers to the Application.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Service Provider
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;means any natural or legal person who processes the data
                  on behalf of the Company. It refers to third-party companies
                  or individuals employed by the Company to facilitate the
                  Service, to provide the Service on behalf of the Company, to
                  perform services related to the Service or to assist the
                  Company in analyzing how the Service is used.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    Usage Data
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;refers to data collected automatically, either generated
                  by the use of the Service or from the Service infrastructure
                  itself (for example, the duration of a page visit).
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    You
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;means the individual accessing or using the Service, or
                  the company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </span>
              </li>
            </ul>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "45.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "30.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Collecting and Using Your Personal Data
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "36.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "24.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Types of Data Collected
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "27.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Personal Data
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
              </span>
            </p>
            <ul style={{ marginTop: "0cm" }} type="disc">
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Email address
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  First name and last name
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Usage Data
                </span>
              </li>
            </ul>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "27.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Usage Data
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                Usage Data is collected automatically when using the Service.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                Usage Data may include information such as Your Device's browser
                type, browser version, the pages of our Service that You visit,
                unique device identifiers and other diagnostic data.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, Your mobile
                device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "36.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "24.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Types of Data Shared
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "27.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  As the requirement of the application functionality purchases
                  are done at vendor portal, so we share your following
                  information
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                lineHeight: "20.0pt",
                background: "white",
              }}
            >
              In order to complete your transaction following information will
              be collected by vendors
            </p>
            <ul style={{ marginTop: "0cm" }} type="disc">
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Email address
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Phone number
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Address
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Payment information
                </span>
              </li>
            </ul>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "27.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Information Collected while Using the Application
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                While using Our Application, in order to provide features of Our
                Application, We may collect, with Your prior permission:
              </span>
            </p>
            <ul style={{ marginTop: "0cm" }} type="disc">
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Information regarding your location
                </span>
              </li>
            </ul>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                We use this information to provide features of Our Service, to
                improve and customize Our Service. The information may be
                uploaded to the Company's servers and/or a Service Provider's
                server or it may be simply stored on Your device.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                You can enable or disable access to this information at any
                time, through Your Device settings.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "36.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "24.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Use of Your Personal Data
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                The Company may use Personal Data for the following purposes:
              </span>
            </p>
            <ul style={{ marginTop: "0cm" }} type="disc">
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    To provide and maintain our Service
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  , including to monitor the usage of our Service.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    To manage Your Account:
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;to manage Your registration as a user of the Service.
                  The Personal Data You provide can give You access to different
                  functionalities of the Service that are available to You as a
                  registered user.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    For the performance of a contract:
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;the development, compliance and undertaking of the
                  purchase contract for the products, items or services You have
                  purchased or of any other contract with Us through the
                  Service.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    To contact You:
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;To contact You by email, telephone calls, SMS, or other
                  equivalent forms of electronic communication, such as a mobile
                  application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    To provide You
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;with news, special offers and general information about
                  other goods, services and events which we offer that are
                  similar to those that you have already purchased or enquired
                  about unless You have opted not to receive such information.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    To manage Your requests:
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;To attend and manage Your requests to Us.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    For business transfers:
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;We may use Your information to evaluate or conduct a
                  merger, divestiture, restructuring, reorganization,
                  dissolution, or other sale or transfer of some or all of Our
                  assets, whether as a going concern or as part of bankruptcy,
                  liquidation, or similar proceeding, in which Personal Data
                  held by Us about our Service users is among the assets
                  transferred.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    For other purposes
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  : We may use Your information for other purposes, such as data
                  analysis, identifying usage trends, determining the
                  effectiveness of our promotional campaigns and to evaluate and
                  improve our Service, products, services, marketing and your
                  experience.
                </span>
              </li>
            </ul>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                We may share Your personal information in the following
                situations:
              </span>
            </p>
            <ul style={{ marginTop: "0cm" }} type="disc">
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    With Service Providers:
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;We may share Your personal information with Service
                  Providers to monitor and analyze the use of our Service, to
                  contact You.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    For business transfers:
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;We may share or transfer Your personal information in
                  connection with, or during negotiations of, any merger, sale
                  of Company assets, financing, or acquisition of all or a
                  portion of Our business to another company.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    With Affiliates:
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;We may share Your information with Our affiliates, in
                  which case we will require those affiliates to honor this
                  Privacy Policy. Affiliates include Our parent company and any
                  other subsidiaries, joint venture partners or other companies
                  that We control or that are under common control with Us.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    With business partners:
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;We may share Your information with Our business partners
                  to offer You certain products, services or promotions.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    With other users:
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  &nbsp;when You share personal information or otherwise
                  interact in the public areas with other users, such
                  information may be viewed by all users and may be publicly
                  distributed outside.
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <b>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      fontFamily: '"Helvetica",sans-serif',
                    }}
                  >
                    With Your consent
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  : We may disclose Your personal information for any other
                  purpose with Your consent.
                </span>
              </li>
            </ul>
            <p
              className="MsoNormal"
              id="data-retention"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "36.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "24.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Retention of Your Personal Data
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                The Company will also retain Usage Data for internal analysis
                purposes. Usage Data is generally retained for a shorter period
                of time, except when this data is used to strengthen the
                security or to improve the functionality of Our Service, or We
                are legally obligated to retain this data for longer time
                periods.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                Any procurement records, if applicable, are maintained for a
                duration of three years as mandated by regulatory obligations
                for accounting and tax compliance, in accordance with our
                commercial privacy policy.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "36.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "24.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Transfer of Your Personal Data
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                The Company will take all steps reasonably necessary to ensure
                that Your data is treated securely and in accordance with this
                Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of Your data and other
                personal information.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "36.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "24.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Disclosure of Your Personal Data
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "27.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Business Transactions
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "27.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Law enforcement
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "27.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "18.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Other legal requirements
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </span>
            </p>
            <ul style={{ marginTop: "0cm" }} type="disc">
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Comply with a legal obligation
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Protect and defend the rights or property of the Company
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Protect the personal safety of Users of the Service or the
                  public
                </span>
              </li>
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  Protect against legal liability
                </span>
              </li>
            </ul>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "36.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "24.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Security of Your Personal Data
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "45.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "30.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Children's Privacy
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                Our Service does not address anyone under the age of 13. We do
                not knowingly collect personally identifiable information from
                anyone under the age of 13. If You are a parent or guardian and
                You are aware that Your child has provided Us with Personal
                Data, please contact Us. If We become aware that We have
                collected Personal Data from anyone under the age of 13 without
                verification of parental consent, We take steps to remove that
                information from Our servers.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                If We need to rely on consent as a legal basis for processing
                Your information and Your country requires consent from a
                parent, We may require Your parent's consent before We collect
                and use that information.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "45.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "30.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Links to Other Websites
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party's site. We strongly advise You to
                review the Privacy Policy of every site You visit.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "45.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "30.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Changes to this Privacy Policy
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                We may update Our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Privacy Policy.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </span>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "45.0pt",
                background: "white",
              }}
            >
              <b>
                <span
                  style={{
                    fontSize: "30.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                    color: "black",
                  }}
                >
                  Contact Us
                </span>
              </b>
            </p>
            <p
              className="MsoNormal"
              style={{
                marginBottom: "12.0pt",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "12.0pt",
                  fontFamily: '"Helvetica",sans-serif',
                  color: "black",
                }}
              >
                If you have any questions about this Privacy Policy, you can
                contact us:
              </span>
            </p>
            <ul style={{ marginTop: "0cm" }} type="disc">
              <li
                className="MsoNormal ml-4"
                style={{
                  color: "black",
                  marginBottom: "12.0pt",
                  lineHeight: "normal",
                  background: "white",
                }}
              >
                <span
                  style={{
                    fontSize: "12.0pt",
                    fontFamily: '"Helvetica",sans-serif',
                  }}
                >
                  By email:&nbsp;compliance@shopiads.com
                </span>
              </li>
            </ul>
          </div>
        </>
      </div>
    </>
  );
}

export default PrivacyPolicy;
