import React from "react";
import { useHistory } from "react-router-dom";

const Card = ({ id, items }) => {
    const history = useHistory();
    const cardClick = (newValue) => {
      window.scrollTo(0,0);
        history.push(`/storeDetails/` + newValue);
      };
  return (
    <div className="full-store-list-item" onClick={() => {cardClick(items.id)}}>
      <a className="full-store-list-item-store-cover-image">
        <div className="lazy-image-container full-store-list-item-cover-image">
          <img className="lazy-image" src={items.companyLogo} alt="Logo"></img>
        </div>
        <div className="full-store-list-item-store-name">{items.storeName}</div>
        <div className="full-store-list-item-store-branch-name">{items.code != "Primary" ? items.code : ""}</div>
      </a>
    </div>
  );
};

export default Card;
