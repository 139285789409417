import firebase from "firebase/app";
import "firebase/auth";
import "firebase/messaging";

//import { OAuthProvider } from "firebase/auth";

/*const app = firebase.initializeApp({
  apiKey: "AIzaSyDBeYTU9DffgJzqm11rMI96nCWSVYUVDFk",
  authDomain: "offerdhamaka.firebaseapp.com",
  databaseURL: "https://offerdhamaka.firebaseio.com",
  projectId: "offerdhamaka",
  storageBucket: "offerdhamaka.appspot.com",
  messagingSenderId: "787226436881",
  appId: "1:787226436881:web:f0c97fbd0213a0d46f58b0",
  measurementId: "G-8Q4GBY5EK5",
});*/
const app = firebase.initializeApp({
  apiKey: "AIzaSyCHeLJPFc-n3M9At1HT3wrjFq_j_P1XkNw",
  authDomain: "shopiadsca.firebaseapp.com",
  databaseURL: "https://shopiadsca.firebaseio.com",
  projectId: "shopiadsca",
  storageBucket: "shopiadsca.appspot.com",
  messagingSenderId: "15194957769",
  appId: "1:15194957769:web:09cdaed2adf6536c752a18",
  measurementId: "G-FP6BXMDFFV"
});
export const provider = new firebase.auth.GoogleAuthProvider(); 
export const fbprovider = new firebase.auth.FacebookAuthProvider(); 
export const appleProvider = new firebase.auth.OAuthProvider('apple.com');
export const auth = app.auth();
export default app;

const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null ;

const { REACT_APP_VAPID_KEY } = process.env;
console.log({
  REACT_APP_VAPID_KEY,
});
const publicKey = "BDU-2cUVvYz1RjwvKmMUWffV-oAH0VJ8pAjtiHrCp3yxNvAHXYrH4xyFl5Dg-NZetGQuWtPbFoDoQFf9Udspea8";

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  // try {
  //   currentToken = await messaging && messaging.getToken({ vapidKey: publicKey });
  //   if (currentToken) {
  //     setTokenFound(true);
  //   } else {
  //     setTokenFound(false);
  //   }
  // } catch (error) {
  //   console.log("An error occurred while retrieving token. ", error);
  // }

  return currentToken;
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging && messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
