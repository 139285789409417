const USA_BASE_URL = process.env.REACT_APP_USA_BASE_URL;
const CANADA_BASE_URL = process.env.REACT_APP_CANADA_BASE_URL;
const USA_BACKEND_URL = process.env.REACT_APP_USA_BACKEND_URL;
const CANADA_BACKEND_URL = process.env.REACT_APP_CANADA_BACKEND_URL;
const CADANA_DOMAIN = process.env.REACT_APP_CADANA_DOMAIN;
const USA_DOMAIN = process.env.REACT_APP_USA_DOMAIN;
const ADMIN_USA_URL=process.env.REACT_APP_ADMIN_USA_URL;
const ADMIN_CANADA_URL=process.env.REACT_APP_ADMIN_CANADA_URL;

const environment =  {
  USA_BASE_URL,
  CANADA_BASE_URL,
  USA_BACKEND_URL,
  CANADA_BACKEND_URL,
  CADANA_DOMAIN,
  USA_DOMAIN,
  ADMIN_USA_URL,
  ADMIN_CANADA_URL
};
export default environment;