import { StoresActionTypes } from "./StoresActionTypes";
import API from "../../Utils/API";

const makeStoresRequest = () => {
  return { type: StoresActionTypes.MAKE_STORES_REQUEST };
};

const makeSectionRequest = () => {
  return { type: StoresActionTypes.MAKE_SECTION_REQUEST };
};


const setStoresData = (data, filteredStoresCategories) => {
  return {
    type: StoresActionTypes.SET_STORES_DATA,
    payload: { data, filteredStoresCategories },
  };
};

const setNearByStoresData = (data) => {
  return {
    type: StoresActionTypes.SET_NEAR_BY_STORES,
    payload: data ,
  };
};

const setInStoresData = (data) => {
  return {
    type: StoresActionTypes.SET_IN_STORES,
    payload: data ,
  };
};

const setOnLineStoresData = (data) => {
  return {
    type: StoresActionTypes.SET_ON_STORES,
    payload: data ,
  };
};

const setStoresProductData = (data, totalPages) => {
  return {
    type: StoresActionTypes.SET_STORES_PRODUCT_DATA,
    payload: {items: data,totalPages},
  };
};

const setAllStoresList = (data) => {
  return {
    type: StoresActionTypes.SET_ALL_STORES_LIST_DATA,
    payload: data,
  };
};

const setActiveSectionData = (data, filteredStoresCategories) => {
  return {
    type: StoresActionTypes.SET_ACTIVE_SECTION_DATA,
    payload: data,
  };
};

const setSubCategory = (data) => {
  return {
    type: StoresActionTypes.SET_SUB_CATEGORY,
    payload:data,
  };
};

const setStoresDataCategoryWise = (data) => {
  return {
    type: StoresActionTypes.SET_STORES_DATA_CATEGORYWISE,
    payload: data,
  };
};

const setStoresError = (error) => {
  return {
    type: StoresActionTypes.SET_STORES_ERROR,
    payload: error,
  };
};

// Get All Stores
export const getAllStores = (userId, cityId, areaId,viewType,marketPlace) => {
  return (dispatch) => {
    dispatch(makeStoresRequest());
   // API.getAllStoresAPI(userId, cityId, areaId)
   API.getAllStoresOfferAPI(userId, cityId, areaId,viewType,marketPlace)
      .then((result) => {
        const storesCategories = result.map((item) =>
          item.categories.length > 0
            ? {
                categoryId: item.categories[0].id,
                categoryName: item.categories[0].name,
              }
            : ""
        );

        const filteredStoresCategories = storesCategories.filter(
          (value, index, self) =>
            value &&
            index === self.findIndex((t) => t.categoryId === value.categoryId)
        );
        const keys = ['storeId'];
        let filtered=[];
        filtered = result.filter(
          (s => o => 
              (k => !s.has(k) && s.add(k))
              (keys.map(k => o[k]).join('|'))
          )
          (new Set)
        );
        dispatch(setStoresData(result, filteredStoresCategories));
      })
      .catch((err) => {
        dispatch(setStoresError(err));
      });
  };
};

// TODO : never used this function, we can remove this
export const getNearByStores = (userId, cityId, cityName, areaId, viewType, marketPlace) => {
  return (dispatch) => {
    dispatch(makeStoresRequest());
   API.getNearByStoresAPI(userId, cityId, cityName, areaId,viewType,marketPlace)
      .then((result) => {
        const nearByStoresList = result.map((item) =>
          item.categories.length > 0
            ? {
                categoryId: item.categories[0].id,
                categoryName: item.categories[0].name,
              }
            : ""
        );
        const keys = ['storeId'];
        let filtered=[];
        filtered = result.filter(
          (s => o => 
              (k => !s.has(k) && s.add(k))
              (keys.map(k => o[k]).join('|'))
          )
          (new Set)
        );
        dispatch(setNearByStoresData(result));
      })
      .catch((err) => {
        dispatch(setStoresError(err));
      });
  };
};

export const getInStores = (
  userId,
  cityId,
  cityName,
  areaId,
  viewType,
  marketPlace,
  pageId,
  recordsPerPage
) => {
  return (dispatch) => {
    dispatch(makeStoresRequest());
    // API.getAllStoresAPI(userId, cityId, areaId)
    API.getInStoresAPI(
      userId,
      cityId,
      cityName,
      areaId,
      viewType,
      marketPlace,
      pageId,
      recordsPerPage
    )
      .then((result) => {
        const storesCategories = result.map((item) =>
          item.categories.length > 0
            ? {
                categoryId: item.categories[0].id,
                categoryName: item.categories[0].name,
              }
            : ""
        );
        const keys = ["storeId"];
        let filtered = [];
        filtered = result.filter(
          (
            (s) => (o) =>
              ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
          )(new Set())
        );
        dispatch(setInStoresData(result));
      })
      .catch((err) => {
        dispatch(setStoresError(err));
      });
  };
};

export const getOnLineStores = (userId, cityId, cityName, areaId,viewType,marketPlace) => {
  return (dispatch) => {
    dispatch(makeStoresRequest());
   // API.getAllStoresAPI(userId, cityId, areaId)
   API.getOnLineStoresAPI(userId, cityId, cityName, areaId,viewType,marketPlace)
      .then((result) => {
        const storesCategories = result.map((item) =>
          item.categories.length > 0
            ? {
                categoryId: item.categories[0].id,
                categoryName: item.categories[0].name,
              }
            : ""
        );
        const keys = ['storeId'];
        let filtered=[];
        filtered = result.filter(
          (s => o => 
              (k => !s.has(k) && s.add(k))
              (keys.map(k => o[k]).join('|'))
          )
          (new Set)
        );
        dispatch(setOnLineStoresData(result));
      })
      .catch((err) => {
        dispatch(setStoresError(err));
      });
  };
};

// Get Stores by categories
export const getStoresByCategory = (userId, categoryId, cityId, stateId,viewType,marketPlace) => {
  return (dispatch) => {
    dispatch(makeStoresRequest());
    //API.getStoresByCategoryAPI(userId, categoryId, cityId, areaId)
    API.getStoresOfferByCategoryAPI(userId, categoryId, cityId, stateId,viewType,marketPlace)
      .then((result) => {
        if(result!=undefined && result.length>0)
        {
          const keys = ['storeId'];
          let filtered=[];
          filtered = result.filter(
            (s => o => 
                (k => !s.has(k) && s.add(k))
                (keys.map(k => o[k]).join('|'))
            )
            (new Set)
          );
        dispatch(setStoresData(result));
        }
        else
        dispatch(setStoresData([]));
      })
      .catch((err) => {
        console.log(err)
        dispatch(setStoresError(err));
      });
  };
};

// Get Stores product by categories
export const getStoresProductByCategory = (queryParams) => {
  return (dispatch) => {
    dispatch(makeStoresRequest());
    API.getStoresProductByCategoryAPINew(API.generateQueryString(queryParams))
      .then((result) => {
        const { content, totalPages } = result;
        if (content.length > 0) { 
          dispatch(setStoresProductData(content,totalPages));
        } else {
          dispatch(setStoresProductData([],0));          
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(setStoresError(err));
      });
  };
};

export const getAllStoresList = (marketPlace) => {
  return (dispatch) => {
    dispatch(makeStoresRequest());
    API.getAllStoreList(marketPlace)
      .then((result) => {
    if(result!=undefined && result.length>0)
    dispatch(setAllStoresList(result));
    else
    dispatch(setAllStoresList([]));
    })
    .catch((err) => {
    console.log(err)
        dispatch(setStoresError(err));
    });
  };
};

export const getStoresAllProduct = (queryString) => {
  return (dispatch) => {
    dispatch(makeStoresRequest());
    API.getAllStoresProductAPINew(queryString)
      .then((result) => {
        // TODO
        const { content, totalPages } = result;
        if (content.length > 0) {
          dispatch(setStoresProductData(content, totalPages))
        }
        else { 
          dispatch(setStoresProductData([],0));
        }
      })
      .catch((err) => {
        dispatch(setStoresError(err));
        dispatch(setStoresProductData([],0));
      });
  };
};

// Get Stores product by categories
export const getActiveSectionItem = (userId, categoryId, cityId, stateId,viewType,marketPlace) => {
  return (dispatch) => {
    dispatch(makeSectionRequest());
    //API.getStoresByCategoryAPI(userId, categoryId, cityId, areaId)
    API.getActiveSectionItem(userId, categoryId, cityId, stateId,viewType,marketPlace)
      .then((result) => {
        if(result!=undefined)
        dispatch(setActiveSectionData(result));
        else
        dispatch(setActiveSectionData([]));
      })
      .catch((err) => {
        console.log(err)
        dispatch(setStoresError(err));
      });
  };
};

// Get all sub categories
export const getSubCategory = (userId,catValue) => {
  return (dispatch) => {
    dispatch(makeStoresRequest());
    //API.getStoresByCategoryAPI(userId, categoryId, cityId, areaId)
    API.subCategoriesListByName(userId,catValue)
      .then((result1) => {
        if(result1!=undefined)
        dispatch(setSubCategory(result1));
        else
        dispatch(setSubCategory([]));
      })
      .catch((err) => {
        console.log(err)
        dispatch(setStoresError(err));
      });
  };
};
