import React, { useEffect, useReducer, useState } from "react";
import LandingPage from "./Components/LandingPage/LandingPage";
import { Route, BrowserRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.css";
import Header from "./Components/UI/Container/Header";
import Footer from "./Components/LandingPage/Footer";
import Notifications from "./Components/Container/Notifications/Notifications";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function App() {
  const [searchText, setSearchText] = useState("");
  const [searchCategory, setSearchCategory] = useState("all");
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  let pathName = "";
  if (window != undefined && window.location != undefined)
    pathName = window.location.pathname;
  const isHeaderFooterVisible = !(
    pathName.includes("redirectToCart") ||
    pathName.includes("redirectToBulkCart")
  );
  if (pathName.includes(".well-known")) {
    return (
      <Redirect
        to={{
          pathname: "/.well-known/assetlinks.json",
        }}
      />
    );
  }
  return (
    <>
      <BrowserRouter>
        {isHeaderFooterVisible && (
          <Header
            setSearchText={setSearchText}
            setSearchCategory={setSearchCategory}
            forceUpdate={forceUpdate}
          />
        )}
        <Route
          path="/"
          component={() => (
            <LandingPage
              searchText={searchText}
              searchCategory={searchCategory}
            />
          )}
        />
        {isHeaderFooterVisible && <Footer />}
      </BrowserRouter>
      <Notifications />
    </>
  );
}

export default App;
