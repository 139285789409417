import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./Utils/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { reduxStore, persistor } from "./Redux/ReduxStore";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@material-ui/core";
import muiTheme from "./Utils/context/ThemeContext";
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <Provider store={reduxStore}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <Router>
              <App />
            </Router>
          </AuthProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
