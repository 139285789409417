import React, { useEffect, useState, useContext } from "react";
import { auth,provider,fbprovider,appleProvider } from "./FirebaseConfig";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [userInfo, setUserInfo] = useState();
  const [loading, setLoading] = useState(true);

  function signUp(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }
  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }
  function loginBygmail() {
    return auth.signInWithPopup(provider).catch(console.log);
  }
  function loginByFb() {

    return auth.signInWithPopup(fbprovider).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("error message>>>>>>>>>>>>>>>>>>>",errorMessage)
      alert(errorMessage);
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
     // const credential = FacebookAuthProvider.credentialFromError(error);
  
      // ...
    });
   /* return auth.signInWithPopup(fbprovider)
    .then((result) => {
      // The signed-in user info.
      console.log("facebook result>>>>>>>>>>>>>>>>>>>",result)
      const user = result.user;
  
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
     // const credential = FacebookAuthProvider.credentialFromResult(result);
      //const accessToken = credential.accessToken;
  
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("error message>>>>>>>>>>>>>>>>>>>",errorMessage)
      alert(errorMessage);
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
     // const credential = FacebookAuthProvider.credentialFromError(error);
  
      // ...
    });*/
  }

  function  loginByApple() {
    return auth.signInWithPopup(appleProvider).catch(console.log);
  }

  function logout() {
    return auth.signOut();
  }
  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUserInfo(user);
      setLoading(false);
    });

    return unsubscribe;
  },[]);

  const value = {
    userInfo,
    signUp,
    login,
    loginBygmail,
    loginByFb,
    loginByApple,
    logout,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
export default AuthContext;
