import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Link,useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import { getUserSnips, removeSnipsData } from "../../Redux/Snips/SnipsAction";
import API from "../../Utils/API";
import { useAuth } from "../../Utils/AuthContext";
import Checkbox from '@material-ui/core/Checkbox';
import StoresHeader from "./Stores/StoresHeader";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import Chip from '@material-ui/core/Chip';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import { COLORS } from "../../Utils/context/ThemeContext";


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function ContactUs() {
  const classes = useStyles();
  const { userInfo } = useAuth();
  const { location } = useSelector((state) => state.location);
  console.log("location>>>>>>>>>>>>>>", location)
  const history = useHistory();
  const [validated, setValidate] = useState(false);
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState("");

  const [name, setName] = useState("");
  const [type, setType] = useState("Application");
  const [comment, setComment] = useState("");
  const [isSave, setIsSave] = useState(false);

  useEffect(() => {

    if (userInfo != undefined && userInfo != null) {
      API.getUserById(userInfo.email).then((result) => {

        console.log("user data>>>>>>>>>>>>>>", result)
        if (result != undefined) {

          setName(result.name);
        }
      })
        .catch((error) => {
          console.log(error);
          console.log(error);
        });

    }
  }, [userInfo?.email]);

  const handleType = (type) => {
    setType(type);
  };

  const handleSubmit = async (e) => {
    let userBody = {
      email: userInfo?.email,
      name: name,
      type:type,
      comment: comment,
      location:location,
    };
    API.addContactUs(userInfo.email, userBody)
    setIsSave(true);
  };

  return (
    <>
      <div className="container-fluid my-5">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <Card className={classes.root}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="h5"
                  className="font-weight-bold d-flex align-items-center justify-content-between"
                >
                  Contact Us
                </Typography>
                {!isSave ? (
                  <div>
                    <Divider className="my-3" />
                    <div className="area">
                      <p className="font-weight-bold">Location</p>
                      <div className="d-flex">
                        <LocationOnOutlinedIcon className="mr-3" />
                        <div>{location}</div>
                      </div>
                    </div>
                    <Divider className="my-3" />
                    <div className="area">
                      <p className="font-weight-bold">Regarding</p>
                      <div className="d-flex">
                        <QuestionAnswerOutlinedIcon className="mr-3" />
                        <Chip
                          className="mr-2"
                          label="Application"
                          clickable
                          onClick={(e) => handleType("Application")}
                          color={type == "Application" ? "primary" : ""}
                          variant="outlined"
                        />
                        <Chip
                          className="mr-2"
                          label="Business Inquiry"
                          clickable
                          onClick={(e) => handleType("Business Inquiry")}
                          color={type == "Business Inquiry" ? "primary" : ""}
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <Divider className="my-3" />
                    <div className="area">
                      <p className="font-weight-bold">Details</p>
                      <div className="d-flex align-items-center">
                        <PermIdentityOutlinedIcon className="mr-3" />
                        <div className="w-50">
                          <input
                            className="form-control bg-light"
                            value={name}
                            disabled
                            placeholder="Name"
                          />
                        </div>
                      </div>
                      <div className="d-flex mt-3 align-items-center">
                        <MailOutlineOutlinedIcon className="mr-3" />
                        <div className="w-50">
                          <input
                            className="form-control bg-light"
                            value={userInfo?.email}
                            disabled
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="d-flex mt-3 align-items-center">
                        <SubjectOutlinedIcon className="mr-3" />
                        <div className="w-50">
                          <textarea
                            className="form-control bg-light"
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Message"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <Button
                        onClick={(e) => handleSubmit(e)}
                        type="button"
                        className="w-30 ml-4"
                        style={{
                          backgroundColor: COLORS.primary.main,
                          marginTop: "20px",
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="secondary"
                        type="button"
                        onClick={(e) => history.goBack()}
                        // style={{ backgroundColor: COLORS.primary.main }}
                        className="ml-3"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="area mt-4">
                    Thank you for getting in touch! We appreciate you contacting
                    us. One of our colleagues will get back in touch with you
                    soon!Have a great day!
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;