import { StoresActionTypes } from "./StoresActionTypes";

const initialState = {
  loading: false,
  sectionLoading: false,
  allStores: [],
  storesCategories: [],
  categoryWiseStores: [],
  subCategory: [],
  productData:[],
  sectionItemData:[],
  allStoreList:[],
  nearByStores: [],
  inStores: [],
  onlineStores: [],
  error: "",
};

const storesReducer = (state = initialState, action) => {
  switch (action.type) {
    case StoresActionTypes.MAKE_STORES_REQUEST:
      return {
        ...state,
        loading: true,
      };
      case StoresActionTypes.MAKE_SECTION_REQUEST:
      return {
        ...state,
        sectionLoading: true,
      };
    case StoresActionTypes.SET_STORES_DATA:
      return {
        ...state,
        loading: false,
        allStores: action.payload.data,
        storesCategories: action.payload.filteredStoresCategories,
        error: "",
      };
    case StoresActionTypes.SET_NEAR_BY_STORES: 
      return {
        ...state,
        loading: false,
        nearByStores: action.payload
      }
    case StoresActionTypes.SET_IN_STORES: 
      return {
        ...state,
        loading: false,
        inStores: action.payload
      }
      case StoresActionTypes.SET_ON_STORES: 
      return {
        ...state,
        loading: false,
        onlineStores: action.payload
      }
      
    case StoresActionTypes.SET_STORES_DATA_CATEGORYWISE:
      return {
        ...state,
        loading: false,
        categoryWiseStores: action.payload,
        error: "",
      };
      case StoresActionTypes.SET_STORES_PRODUCT_DATA:
        return {
          ...state,
          loading: false,
          productData: action.payload,
          error: "",
        };
        case StoresActionTypes.SET_ALL_STORES_LIST_DATA:
        return {
          ...state,
          loading: false,
          allStoreList: action.payload,
          error: "",
        };
        case StoresActionTypes.SET_ACTIVE_SECTION_DATA:
        return {
          ...state,
          sectionLoading: false,
          sectionItemData: action.payload,
          error: "",
        };
      case StoresActionTypes.SET_SUB_CATEGORY:
        return {
          ...state,
          loading: false,
          subCategory: action.payload,
          error: "",
        };
    case StoresActionTypes.SET_STORES_ERROR:
      return {
        loading: false,
        sectionLoading: false,
        allStores: [],
        nearByStores: [],
        storesCategories: [],
        categoryWiseStores: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default storesReducer;
