import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import "../Container.css";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import {
  getActiveSectionItem,
  getAllStores,
  getInStores,
  getOnLineStores,
} from "../../../Redux/Stores/StoresActions";
import StoresHeader from "./StoresHeader";
import ListItem from "@material-ui/core/ListItem";
import { useHistory, useRouteMatch } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "./style.css";
import { StoreCard } from "./StoreCard"
import API from "../../../Utils/API";
import { COLORS } from "../../../Utils/context/ThemeContext";

const offerCardSlider = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  centerMode: false,
};

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  centerMode: false,
};

function Stores() {
  const { userInfo } = useAuth();
  const {
    loading,
    // nearByStores: nearByStoreList,
    // inStores: inStoreList,
  } = useSelector((state) => state.stores);
  const { cityId, cityName, stateId, marketPlace : selectedRefMarketPlace} = useSelector(
    (state) => state.location
  );
  const [inStores, setInStores] = useState([]);
  const [nearByStores, setNearByStores] = useState([]);
  const { params } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const [stores, setStores] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [nearByLoading, setNearByLoading] = useState(false)
  const [inStoreLoading, setInStoreLoading] = useState(false)
  useEffect(() => {
    API.getMainCategories(
      userInfo != null && userInfo !== undefined ? userInfo.email : "gust"
    ).then((result) => {
      if (result !== undefined && result.length > 0) {
        setMainCategoryList(result);
      }
    });
  }, []);
  const [recordPerPage] = useState(32);
  useEffect(() => {
    if (
      cityName &&
      stateId) {
      if (mainCategoryList.length > 0) {
        fetchNearByStores(0);
        fetchInStores(0);
      }
      // dispatch(
      //   getAllStores(
      //     userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
      //     cityId,
      //     stateId,
      //     "store",
      //     selectedRefMarketPlace
      //   )
      // );
      // dispatch(
      //   getActiveSectionItem(
      //     userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
      //     params.tabName,
      //     cityId,
      //     stateId,
      //     "Store",
      //     selectedRefMarketPlace
      //   )
      // );
      // dispatch(
      //   getOnLineStores(
      //     userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
      //     cityId,
      //     cityName,
      //     stateId,
      //     "trending",
      //     selectedRefMarketPlace
      //   )
      // );
    }
  }, [params.tabName,mainCategoryList,selectedRefMarketPlace]);

  const viewAllClick = (newValue) => {
    history.push(`/All/${newValue}/${params.tabName}`);
    // history.push(`/offers/tab/` + newValue);
    console.log("Under Development..............");
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  SwiperCore.use([Navigation, Pagination]);

  const fetchNearByStores = (pageId) => {
      setNearByLoading(true)
      setInStores([])
      setNearByStores([])
    API.getNearByStoresAPI(
      userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
      cityId,
      cityName,
      stateId,
      params.tabName === "Trending"
        ? "trending"
        : mainCategoryList.find((item) => item.name === params.tabName).id,
      selectedRefMarketPlace,
      pageId,
      recordPerPage
    )
      .then((response) => {
        let {
          content,
          pageable: { pageNumber },
          totalPages: numberOfPages,
        } = response;
        setNearByStores((oldItems) => [...oldItems, ...content]);
        setCurrentPage(pageNumber);
        setTotalPages(numberOfPages);
      })
      .finally(() => {
        setNearByLoading(false);
      });;
  }
  const fetchInStores = (pageId) => {
      setInStoreLoading(true);
      setInStores([])
      setNearByStores([])
    API.getInStoresAPI(
      userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
      cityId,
      cityName,
      stateId,
      params.tabName === "Trending" ? "trending" : mainCategoryList.find(item => item.name === params.tabName).id,
      selectedRefMarketPlace,
      pageId,
      recordPerPage
    ).then((response) => {
      let {
        content,
        pageable: { pageNumber },
        totalPages: numberOfPages,
      } = response;
      console.log({ content });
      setInStores(content);
      setCurrentPage(pageNumber);
      setTotalPages(numberOfPages);
    }).finally(() => { 
      setInStoreLoading(false)
    });
  };
  return (
    <>
      <>
        <StoresHeader />
        {/* <pre>{JSON.stringify(allStores, null, 2)}</pre> */}
        {/*<div className="container-fluid">
            <div className="row">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-9 ml-auto offset-sm-mr-1">
                    <div className="row my-4">
                      <div className="col-md-12">
                        <div className="offer-card-main">
                        <Slider {...offerCardSlider}>
                          {allStores != undefined ? allStores.map((item) => (
                            <div>
                              <StoreCard
                                branchName={item.storeName}
                                storeId={item.storeId}
                                offerId={item.id}
                                img={item.fullImage}
                                branchLogo={item.logo}
                                heading={item.heading}
                                avgRating={item.avgRating}
                                type={item.type}
                                key={item.id}
                              />
                            </div>
                          )) : ""}
                          </Slider>
                        </div>
                      </div>

                    </div></div></div>
              </div>


            </div>
          </div>*/}
      </>

      {!loading ? (
        <>
          {/* <pre>{JSON.stringify(allStores, null, 2)}</pre> */}
          <div className="container-fluid storeinfo-wrapper">
            <div className="row">
              {/* <div className="container-fluid mt-4">
                <div className="row">
                  <div className="col-md-9 ml-auto offset-sm-mr-1">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h4 className="font-weight-bold text-uppercase mb-0"></h4>
                          <a
                            className="shopee-header-section__header-link"
                            href=""
                          >
                            <button
                              onClick={() => {
                                viewAllClick("Stores");
                              }}
                              className="shopee-button-no-outline"
                            >
                              View All&nbsp;
                              <svg
                                enable-background="new 0 0 11 11"
                                viewBox="0 0 11 11"
                                x="0"
                                y="0"
                                height="12px"
                                width="12px"
                                className="icon-arrow-right"
                              >
                                <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                              </svg>
                            </button>
                          </a>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="offer-card-main">
                          <Swiper
                            spaceBetween={10}
                            slidesPerView={3}
                            slidesPerGroup={3}
                            navigation
                            pagination={{ clickable: true }}
                            onSlideChange={(swiper) => {
                              console.log("swiper");
                              // handlePause(swiper.previousIndex);
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                          >
                            {allStores != undefined
                              ? allStores.map((item, index) => (
                                  <SwiperSlide>
                                    <div style={{minWidth: "340px"}}>
                                      <StoreCard
                                        branchName={item.storeName}
                                        storeId={item.storeId}
                                        offerId={item.id}
                                        img={item.fullImage}
                                        branchLogo={item.logo}
                                        heading={item.heading}
                                        avgRating={item.avgRating}
                                        type={item.type}
                                        totalLikes={item.totalLikes}
                                        key={item.id}
                                      />
                                    </div>
                                  </SwiperSlide>
                                ))
                              : ""}
                          </Swiper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="container-fluid mt-4">
                <div className="col-md-9 ml-auto offset-sm-mr-1">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <h4 className="font-weight-bold text-uppercase mb-0">
                          Stores from your city
                        </h4>

                        <button
                          onClick={() => {
                            viewAllClick("NearByStores");
                          }}
                          className="shopee-button-no-outline"
                          style={{ color: COLORS.primary.main,fontWeight:"bold" }}
                        >
                          View All&nbsp;
                          <svg
                            enable-background="new 0 0 11 11"
                            viewBox="0 0 11 11"
                            x="0"
                            y="0"
                            height="12px"
                            width="12px"
                            className="icon-arrow-right"
                          >
                            <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                    {nearByLoading && (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          color:COLORS.primary.main
                        }}
                      />
                    )}
                    {nearByStores.length > 0 ? (
                      <div className="col-md-12">
                        <div className="offer-card-main">
                          <Swiper
                            spaceBetween={10}
                            slidesPerView={3}
                            slidesPerGroup={3}
                            navigation
                            loop={nearByStores.length > 3 ? true : false}
                            // pagination={{ clickable: true }}
                            onSlideChange={(swiper) => {
                              console.log("swiper");
                              // handlePause(swiper.previousIndex);
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                          >
                            {nearByStores.map((item, index) => (
                              <SwiperSlide>
                                <StoreCard
                                  branchName={item.storeName}
                                  storeId={item.storeId}
                                  offerId={item.id}
                                  img={
                                    item.primaryOfferImage
                                      ? item.primaryOfferImage
                                      : item.companyLogo
                                  }
                                  branchLogo={item.companyLogo}
                                  logoSmall={item.logoSmall}
                                  logoMedium={item.logoMedium}
                                  heading={item.heading}
                                  avgRating={item.avgRating}
                                  type={item.type}
                                  totalLikes={item.totalLikes}
                                  key={item.id}
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </div>
                    ) : (
                      !nearByLoading && (
                        <div className="pl-3">Coming up soon in future!</div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="container-fluid mt-4">
                <div className="col-md-9 ml-auto offset-sm-mr-1">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <h4 className="font-weight-bold text-uppercase mb-0">
                          {localStorage.getItem("connectSite") == "CA"
                            ? "Canada-wide stores"
                            : "US-wide stores"}
                        </h4>

                        <button
                          onClick={() => {
                            viewAllClick("InStores");
                          }}
                          className="shopee-button-no-outline"
                          style={{ color: COLORS.primary.main,fontWeight:"bold" }}
                        >
                          View All&nbsp;
                          <svg
                            enable-background="new 0 0 11 11"
                            viewBox="0 0 11 11"
                            x="0"
                            y="0"
                            height="12px"
                            width="12px"
                            className="icon-arrow-right"
                          >
                            <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                    {inStoreLoading && (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          color:COLORS.primary.main
                        }}
                      />
                    )}
                    {inStores !== undefined && inStores.length > 0 ? (
                      <div className="col-md-12">
                        <div className="offer-card-main">
                          <Swiper
                            spaceBetween={10}
                            slidesPerView={3}
                            slidesPerGroup={3}
                            navigation
                            loop={inStores.length > 3 ? true : false}
                            // pagination={{ clickable: true }}
                            onSlideChange={(swiper) => {
                              console.log("swiper");
                              // handlePause(swiper.previousIndex);
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                          >
                            {inStores != undefined
                              ? inStores.map((item, index) => (
                                  <SwiperSlide>
                                    <StoreCard
                                      branchName={item.storeName}
                                      storeId={item.storeId}
                                      offerId={item.id}
                                      img={
                                        item.primaryOfferImage
                                          ? item.primaryOfferImage
                                          : item.logoMedium
                                      }
                                      branchLogo={item.companyLogo}
                                      logoSmall={item.logoSmall}
                                      logoMedium={item.logoMedium}
                                      heading={item.heading}
                                      avgRating={item.avgRating}
                                      type={item.type}
                                      totalLikes={item.totalLikes}
                                      key={item.id}
                                    />
                                  </SwiperSlide>
                                ))
                              : ""}
                          </Swiper>
                        </div>
                      </div>
                    ) : (
                      !inStoreLoading && (
                        <div className="pl-3">No Canada-wide stores</div>
                      )
                    )}
                  </div>
                </div>
              </div>
              {/*  <div className="container-fluid mt-4">
                <div className="col-md-9 ml-auto offset-sm-mr-1">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex align-items-center justify-content-between mb-3">
                        <h4 className="font-weight-bold text-uppercase mb-0">
                        Canada-wide online-stores
                        </h4>
                      
                          <button
                            onClick={() => {
                              viewAllClick("OnlineStores");
                            }}
                            className="shopee-button-no-outline"
                          >
                            View All&nbsp;
                            <svg
                              enable-background="new 0 0 11 11"
                              viewBox="0 0 11 11"
                              x="0"
                              y="0"
                              height="12px"
                              width="12px"
                              className="icon-arrow-right"
                            >
                              <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                            </svg>
                          </button>
                       
                      </div>
                    </div>
                    {onlineStores !== undefined && onlineStores.length > 0 ? (<div className="col-md-12">
                      <div className="offer-card-main">
                        <Swiper
                          spaceBetween={10}
                          slidesPerView={3}
                          slidesPerGroup={3}
                          navigation
                          loop={onlineStores.length > 3 ? true : false}
                          //pagination={{ clickable: true }}
                          onSlideChange={(swiper) => {
                            console.log("swiper");
                            // handlePause(swiper.previousIndex);
                          }}
                          onSwiper={(swiper) => console.log(swiper)}
                        >
                          {onlineStores != undefined
                            ? onlineStores.map((item, index) => (
                                <SwiperSlide>
                                    <StoreCard
                                      branchName={item.storeName}
                                      storeId={item.storeId}
                                      offerId={item.id}
                                      img={item.primaryOfferImage ? item.primaryOfferImage : item.companyLogo}
                                      branchLogo={item.companyLogo}
                                      heading={item.heading}
                                      avgRating={item.avgRating}
                                      type={item.type}
                                      totalLikes={item.totalLikes}
                                      key={item.id}
                                    />
                                </SwiperSlide>
                              ))
                            : "Coming up soon in future!"}
                        </Swiper>
                      </div>
                    </div>) : (<div className="pl-3">No Online Stores</div>)}
                  </div>
                </div>
              </div>
              */}
            </div>
          </div>
        </>
      ) : (
        <strong>Loading ....</strong>
      )}
    </>
  );
}

export default Stores;
