import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Link,useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';
import API from "../../../Utils/API";
import Aboutus from "../../../Assets/about-us.png"
import omnichannel from "../../../Assets/web-banner.png"



const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function AboutUs() {
  const classes = useStyles();
  

  

 
  

  return (
    <>
      <section className="aboutus">
        <div className="container-fluid">
        <div className="row align-items-center">
            <div className="col-md-6">
            <h2>About US</h2>
            <p>ShopiAds is an intersection where buyers meet with sellers and builds a trusted bond between them to provide gamut of shopping experience.</p>
            </div>
            <div className="col-md-6">
              <img src={Aboutus} className="img-fluid"></img>
            </div>
        </div>
        </div>
      </section>
      <div className="container-fluid my-5 aboutus-sections">
        <div className="row align-items-center">
        <div className="col-md-6">
              <img src={omnichannel} className="img-fluid mt-3 pt-3 mt-lg-5 pt-lg-5"></img>
            </div>
            <div className="col-md-6">
            <h2>Our Purpose</h2>
            <p>We help in lowering the overall cost of the product by facilitating direct sales which benefits both consumers and sellers.</p>
            <p>ShopiAds onboard incorporated sellers only which secures buyer from fraudsters and provide the quality product at the right price, saving on time and money.</p>
            </div>
            
            
        </div>

        <div className="row align-items-center mt-3">
       
            <div className="col-md-6 order-2 order-lg-1">
            <h2>Features</h2>
            <p>Find a specific Store, Brand or Product of your choice.</p>
            <p>Create your own shopping list store-wise by snipping the Products you wish to buy.</p>
            <p>Browse through hundreds of Stores and Offers city/province/country wide.</p>
            <p>Purchase product by visiting store or buy online from vendor portal.</p>
            <p>Rate your purchase and help others.</p>
            <p>Follow your favourite store and get notified about offers you can't miss.</p>
            </div>
            <div className="col-md-6 order-1 ordger-lg-2">
              <img src={Aboutus} className="img-fluid my-3 py-3 my-lg-0 py-lg-0"></img>
            </div>
            
        </div>
      
      </div>
    </>
  );
}

export default AboutUs;