import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "react-bootstrap";
import Rating from "@material-ui/lab/Rating";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Dummy1 from "../../../Assets/Dummy 1.jpg";
import Dummy2 from "../../../Assets/Dummy 2.jpg";
import Dummy3 from "../../../Assets/Dummy 3.jpg";
import SampleBanner from "../../../Assets/Sample Banner.png";
import Image1 from "../../../Assets/Img1.jpg";
import Table from "../../../Assets/Table.png";
import Children from "../../../Assets/Children.png";
import Casualshirts from "../../../Assets/Casualshirts.png";
import Footwear from "../../../Assets/Footwear.png";
import Sweatshirts from "../../../Assets/Sweatshirts.png";
import Shorts from "../../../Assets/Shorts.png";
import { Grid, ListItemText, makeStyles, Tab, Tabs } from "@material-ui/core";
import Card from "react-bootstrap/Card";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getUserSnips } from "../../../Redux/Snips/SnipsAction";
import { useAuth } from "../../../Utils/AuthContext";
import API from "../../../Utils/API";
import {
  getActiveSectionItem,
  getAllStores,
  getStoresAllProduct,
  getStoresByCategory,
  getStoresProductByCategory,
  getSubCategory,
} from "../../../Redux/Stores/StoresActions";
import RatingForm from "../../DialogBox/RatingForm";
import Offers from "./Offers";
import { OfferCard } from "./OfferCard";
import { COLORS } from "../../../Utils/context/ThemeContext";
import { getDomainCountryCode } from "../../../service/location";

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "0px",
    marginLeft: "12px",
    color: COLORS.primary.main,
    cursor: "pointer",
  },
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& button": {
      "& .MuiTab-wrapper": {
        flexDirection: "row !important",
        justifyContent: "left !important",
      },
    },
  },
  selectedItem: {
    "& span": {
      color: "var(--primarycolor)",
    },
  },
  unSelectedItem: {
    color: "black",
  },
}));

function OffersAll() {
  const classes = useStyles();
  const { userInfo } = useAuth();
  const { path, params } = useRouteMatch();
  const currPath = path.split(":")[0];
  const dispatch = useDispatch();
  const history = useHistory();
  let allCategories = [];
  const {
    userId,
    cityId,
    stateId,
    marketPlace: selectedRefMarketPlace,
  } = useSelector((state) => state.location);
  const {
    loading,
    allStores,
  } = useSelector((state) => state.stores);
  const { allStoreList } = useSelector((state) => state.stores);
  const { allLikes } = useSelector((state) => state.snips);
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [recordPerPage, setRecordPerPage] = useState(32)
  const [value, setValue] = useState(0);
  const allOffers = [...allStores];
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [allCategoriesArray, setAllCategoriesArray] = useState([]);
  const [categoryToggle, setCategoryMoreToggle] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loginForm, setLoginForm] = useState(false);
  const [likesLoading, setLikesLoading] = useState(false);
  const [likes, setLikes] = useState(0);
  const [ratingForm, setRatingForm] = useState(false);
  const [liked, setLiked] = useState(false);
  const [storeId, setStoreId] = useState();
  const [offerId, setOfferId] = useState();
  const [categoryId, setCategoryId] = useState("");

  allOffers &&
    allOffers.map((items) => {
      allLikes.map((item) => {
        if (item.offerId === items.id) {
          items.liked = true;
        } else {
          items.liked = false;
        }
      });
    });
  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };

  const setCategoryToggle = () => {
    setCategoryMoreToggle(!categoryToggle);
  };

  useEffect(() => {
    // setValue(allCategoriesArray.indexOf(params.tabName));
    
    dispatch(getUserSnips(userInfo?.email, "like"));
    API.getMainCategories(
      userInfo != null && userInfo != undefined ? userInfo.email : "gust"
    )
      .then((result) => {
        console.log("result.data>>>>>>>>>>>>>>>>>>", result);
        if (result != undefined && result.length > 0) {
          //console.log('data=='+result.data);
          setMainCategoryList(result);
          allCategories = ["Trending"].concat(
            result.map((category) => {
              if(category.name === params.tabName) setCategoryId(category.id)
               return category.name})
          );
          if (params.tabName === "Trending") {
            dispatch(
              getAllStores(
                userInfo != null && userInfo != undefined ? userInfo.email : "gust",
                cityId,
                stateId,
                "offer"
              )
            );
          } else {
            let catId =  result.filter((e) => e.name === params.tabName)[0].id;          
            dispatch(
              getStoresByCategory(
                userInfo?.email || userId,
                catId,
                cityId,
                stateId,
                "offer"
              )
            );
          }
          setAllCategoriesArray(allCategories);
          setValue(allCategories.indexOf(params.tabName));
          console.log("allCategoriesArray>>>>>>>>>>>>>>>>>>", allCategories);
        }
      })
      .catch((error) => {
        console.log(error);
        // console.log(error);
      });

    // dispatch(
    //   getStoresAllProduct(
    //     userInfo != null && userInfo != undefined ? userInfo.email : "gust",
    //     cityId,
    //     stateId,
    //     "offer"
    //   )
    // );
  }, [params]);

  const cardClick = (newValue) => {
    history.push(`/offers/tab/` + newValue);
    // console.log("Under Development..............")
  };

  const handleOpenForm = () => setRatingForm(true);

  const handleCloseForm = () => setRatingForm(false);

  const handleChange = (event, newValue) => {
    const tabName = event.target.innerText;
    setValue(newValue);
    if (tabName !== "NEW") {
      dispatch(
        getStoresByCategory(
          userInfo?.email || userId,
          tabName === "TRENDING" ? "trending" : mainCategoryList[newValue - 1].id,
          cityId,
          stateId,
          "offer",
          selectedRefMarketPlace
        )
      );
    } else {
      dispatch(
        getAllStores(userInfo?.email || userId, cityId, stateId, "offer",selectedRefMarketPlace)
      );
      const searchQuery = API.generateQueryString({
        user: API.encodedUserId(userInfo?.email || userId),
        cityId,
        stateId,
        viewType: "Offer",
        marketPlace: selectedRefMarketPlace,
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc: getDomainCountryCode() === "CA" ? true : false
      });
      dispatch(
        getStoresAllProduct(searchQuery)
      );
      dispatch(
        getActiveSectionItem(
          userInfo?.email || userId,
          "Trending",
          cityId,
          stateId,
          "Offer",
          selectedRefMarketPlace
        )
      );
    }
  };

  const handleMenuChange = (newValue, index) => {
    //const tabName = event.target.innerText;
    history.push(`${currPath}${newValue}`);
    setValue(newValue);
    setSelectedIndex(index);
    var CatId = "";
    mainCategoryList.map((item, index) => {
      if (item.name == newValue) CatId = item.id;
    });

    dispatch(
      getStoresByCategory(
        userInfo?.email || userId,
        CatId,
        cityId,
        stateId,
        "offer"
      )
    );
    dispatch(
      getStoresProductByCategory({
        user:
          API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
        categoryId: CatId,
        cityId,
        stateId,
        viewType: "offer",
        marketPlace: selectedRefMarketPlace,
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc:false
      })
    );
  };

  const renderStoreName = (newValue) => {
    let storeName = "";
    if (allStoreList != undefined && allStoreList.length > 0) {
      const obj = allStoreList.filter((item) => item.id == newValue);
      if (obj != undefined && obj.length > 0) storeName = obj[0].storeName;
    }
    return <div>{storeName}</div>;
  };

  const makeLikes = (item) => {
    // history.push(`/storeDetails/` + newValue);
    if (!userInfo?.email) setLoginForm(true);
    else {
      setLikesLoading(true);
      if (
        allLikes !== undefined &&
        allLikes.filter((e) => e.type === "Offer") &&
        allLikes.filter((e) => e.offerId === item.id).length === 0 &&
        !loading &&
        !likesLoading
      ) {
        API.makeLikes(userInfo?.email, item.id, "offer")
          .then((result1) => {
            console.log("makeLikes>>>>>>>>>>>>>>>", result1);
            if (result1 != undefined) {
              // setOffer(result1)
              let totalLikes = item.totalLikes + 1;
              setLikes(totalLikes);
              dispatch(getUserSnips(userInfo?.email, "like"));
              setLikesLoading(false);
              // setLoading(false);
            } else {
              // setOffer("")
              setLikesLoading(false);
              // setLoading(false);
            }
          })
          .catch((error) => {
            // setLoading(false);
            console.log(error);
            setLikesLoading(false);
            // console.log(error);
          });
      } else {
        const offerId =
          allLikes !== undefined &&
          allLikes.filter((e) => e.offerId === item.id)[0].id;
        API.makeUnLikes(userInfo?.email, item.id, "offer", offerId)
          .then((result) => {
            console.log("makeUnLikes>>>>>>>>>>>>>>>", result);
            if (result !== undefined) {
              // setOffer(result1)
              let totalLikes = item.totalLikes - 1;
              setLikes(totalLikes);
              dispatch(getUserSnips(userInfo?.email, "like"));
              setLikesLoading(false);
              // setLoading(false);
            } else {
              // setOffer("")
              setLikesLoading(false);
              // setLoading(false);
            }
          })
          .catch((error) => {
            // setLoading(false);
            console.log(error);
            setLikesLoading(false);
          });
        // alert("already liked this offer thanks.")
        // setLikesLoading(false);
      }
    }
    // console.log("Under Development..............")
  };

  function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;
    
    if (img.complete) {
      return true;
    } else {
      img.onload = () => {
        return true;
      };
      
      img.onerror = () => {
        return false;
      };
    }
  }

  
  return (
    <>
      <Grid container className="mt-3">
        <Grid item xs={2}>
          <div className="menu-tab">
            <i
             style={{...style.backButton, position:'relative'}}
              className="fa fa-arrow-circle-left"
              aria-hidden="true"
              title="Back to Login"
              onClick={() =>
                history.push(`/offers/${window.location.href.split("/")[5]}`)
              }
            ></i>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="off"
              className={classes.tabs}
              orientation="vertical"
            >
              {allCategoriesArray.map((item, index) =>
               
                  <Tab
                    {...a11yProps(index)}
                    label={item}
                    key={item}
                    style={{ minWidth: "120px",textAlign:'left'}}
                    onClick={() => history.push(`${currPath}${params.offerName}/${item}`)}
                    wrapped
                  />
               
              )}
            </Tabs>
            
          </div>
        </Grid>
        <Grid item xs={10}>
        <h4 className="ml-5" ><strong>{(params.offerName === "FeaturedDeals" && "Featured Deals") || (params.storeName === "InStores" && localStorage.getItem("connectSite")=="CA"?"Canada-wide stores":"US-wide stores") || (params.storeName === "OnlineStores" && "ONLINE STORES") }11111</strong></h4>
          <Grid container className="ml-5">
            {allOffers !== undefined && allOffers.length > 0
              ? allOffers.map((item, index) => (
                //checkIfImageExists(item.fullImage)?
                  <div style={{ minWidth: "340px", maxWidth: "350px" }}>
                    <OfferCard
                      branchName={item.storeName}
                      storeId={item.storeId}
                      offerId={item.id}
                      img={item.fullImage}
                      branchLogo={item.logo}
                      logoSmall={item.logoSmall}
                      heading={item.heading}
                      avgRating={item.avgRating}
                      totalLikes={item.totalLikes}
                      key={item.id}
                    />
                  </div>//:""
                ))
              : <h4>Coming up soon in future in {params.tabName}</h4>}
          </Grid>
        </Grid>
      </Grid>
      {ratingForm && (
        <RatingForm
          handleOpen={handleOpenForm}
          handleClose={handleCloseForm}
          open={ratingForm}
          storeId={storeId}
          productId={""}
          offerId={offerId}
          type={"Offer"}
        />
      )}
    </>
  );
}

export default OffersAll;
