import { SnipsActionTypes } from "./SnipsActionTypes";

const initialState = {
  loading: false,
  allSnips: [],
  allLikes: [],
  error: "",
};

const snipsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SnipsActionTypes.MAKE_SNIPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
      case SnipsActionTypes.MAKE_LIKES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SnipsActionTypes.SET_SNIPS_DATA:
      return {
        loading: false,
        allSnips: action.payload,
        allLikes:[],
        error: "",
      };
      case SnipsActionTypes.SET_LIKES_DATA:
      return {
        ...state,
        loading: false,
        allSnips:[],
        allLikes: action.payload,
        error: "",
      };
    case SnipsActionTypes.PUSH_SNIPS_DATA:
      return {
        loading: false,
        allSnips: [...state.allSnips].concat(action.payload),
        error: "",
      };
    case SnipsActionTypes.REMOVE_SNIPS_DATA:
      return {
        loading: false,
        allSnips: action.payload,
        error: "",
      };
    case SnipsActionTypes.SET_SNIPS_ERROR:
      return {
        loading: false,
        allSnips: [],
        allLikes:[],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default snipsReducer;
