import React from "react";
import "./LandingPage.css";
import Container from "./Container";
import Footer from "./Footer";
import { Hidden } from "@material-ui/core";

function LandingPage(props) {
  return (
    <>
      <div className="body-container">
        {/* <Header /> */}
        <Container {...props} />
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default LandingPage;
