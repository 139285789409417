import React, { useEffect, useState } from "react";
import "../Container.css";
import { useAuth } from "../../../Utils/AuthContext";
import { Form, Button, InputGroup, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getAllStores, getSubCategory } from "../../../Redux/Stores/StoresActions";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import API from "../../../Utils/API";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useForm, useStep } from "react-hooks-helper";
import { COLORS } from "../../../Utils/context/ThemeContext";
import environment from "../../../Utils/env";


const defaultData = {
  mobileNo: "",
  firstName: "",
  password: "",
  email: "",
  roleId: "",
  agent: "N",
  companyId: "",
  gender: "",
};

function Profile() {
  const { userInfo } = useAuth();
  const { loading, allStores, categoryWiseStores } = useSelector(
    (state) => state.stores
  );
  const { location, userId, cityId, cityName, stateId } = useSelector(
    (state) => state.location
  );
  const [form, setForm] = useForm(defaultData);
  const { password, email, firstName, mobileNo, roleId, agent, companyId, gender } = form;
  const { path, params } = useRouteMatch();
  const [subCategories, setSubCategories] = useState([]);
  const [filtervalue, setFiltervalue] = useState([]);
  const history = useHistory();
  const [validated, setValidate] = useState(false);
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState("");
  const [profileData, setProfileData] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {

    if (userInfo != undefined && userInfo != null) {
      API.getUserById(userInfo.email).then((result) => {

        console.log("user data>>>>>>>>>>>>>>", result)
        if (result != undefined) {

          form.firstName = result.name
          form.mobileNo = result.mob
          form.email = result.email
          form.gender = result.gender;
          setProfileData(result);
        }
      })
        .catch((error) => {
          console.log(error);
          console.log(error);
        });

    }
  }, [params.tabName]);


  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  const handleSubmit = async (e) => {
    try {
      setValidate(true);
      setErrors({});
      setResponse("");
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (form.mobileNo == '' || form.mobileNo == undefined) {
        setErrors({ "mobile": "Please enter mobile number." });
        e.preventDefault();
        e.stopPropagation();
      }
      else if (form.mobileNo != '' && form.mobileNo != undefined && form.mobileNo.length < 10) {
        setErrors({ "mobile": "Please enter valid mobile number."});
        e.preventDefault();
        e.stopPropagation();
      }
      if (form.firstName == '' || form.firstName == undefined) {
        e.preventDefault();
        e.stopPropagation();
        // e.stopNavigation();
        // return;
      }
      else if (form.email == '' || form.email == undefined) {
        setErrors({ "email": "Please enter email address." });
        e.preventDefault();
        e.stopPropagation();
        // e.stopNavigation();
        // return;
      }
      else if (!pattern.test(form.email)) {

        setErrors({ "email": "Please enter valid email address." });
        e.preventDefault();
        e.stopPropagation();

        // e.stopNavigation();
        // return;
      }
      else if (form.email != '' && form.mobileNo != '' && form.mobileNo.length == 10 && pattern.test(form.email) && form.firstName != '') {

        let userBody = {
          email: form.email,
          name: form.firstName,
          mob: form.mobileNo,
          gender: form.gender,
        };
        API.updateWebUser(userInfo.email, userBody)
        setResponse("Profile Save Successfully");
        history.push("/")
      }
    }
    catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <>
        {/* <pre>{JSON.stringify(allStores, null, 2)}</pre> */}
        <div className="container-fluid mt-5 pt-5">
          <div className="row mt-3">
            <div className="col-md-4 mx-auto">
              <div className="card">
                <div className="font-weight-bold text-uppercase h4 text-center border-bottom card-header">
                  Profile
                </div>
                <p className="text-center text-success mt-2 mb-0">{response}</p>

                <div className="card-body px-0 pb-0">
                  <Form
                    noValidate
                    validated={validated}
                    action="#"
                    style={style.form}
                  >
                    <InputGroup className="mb-2">
                      {/* <Form.Group controlId="formBasicEmail" className="mb-4">
                  <Form.Label>Enter OTP</Form.Label>
                  <Form.Control
                    type="number"
                    name="otp"
                    value={form.otp}
                    onChange={setForm}
                    required
                  />
                  <Form.Control.Feedback type="invalid" >Please enter valid otp</Form.Control.Feedback>
        </Form.Group>*/}
                      <Form.Group
                        controlId="validationfirstName"
                        className="col-12"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          name="firstName"
                          type="text"
                          value={firstName}
                          onChange={setForm}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter name
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        controlId="validationmobileNo"
                        className="col-12"
                      >
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          name="mobileNo"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          value={mobileNo}
                          type="number"
                          maxLength={"10"}
                          onChange={setForm}
                          required
                        />
                        <div className="text-danger">{errors.mobile}</div>
                      </Form.Group>
                    </InputGroup>
                    <InputGroup className="mb-2">
                      <Form.Group
                        controlId="validationemail"
                        className="col-12"
                      >
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                          readOnly={
                            userId == null || userId == undefined ? false : true
                          }
                          name="email"
                          type="email"
                          value={email}
                          onChange={setForm}
                          required
                        />
                        <div className="text-danger">{errors.email}</div>
                      </Form.Group>
                      {/* <Form.Group>
                          <div key="custom-inline-radio" className="mb-3 col-12">
                            <Form.Check
                              // custom
                              inline
                              label="Male"
                              type="radio"
                              id="custom-inline-radio-1"
                              name="gender"
                              value="Male"
                              checked={gender=="Male"?true:false}
                              onChange={setForm}
                              // checked={user.gender === "Male" && false}
                              required
                            />
                            <Form.Check
                              // custom
                              inline
                              label="Female"
                              type="radio"
                              id="custom-inline-radio-2"
                              name="gender"
                              value="Female"
                              checked={gender=="Female"?true:false}
                              onChange={setForm}
                              // checked={user.gender === "Female" && false}
                              required
                            />
                          </div>
                        </Form.Group> */}
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <Form.Group className="col-6 mx-auto">
                        <div className="d-flex">
                          <Button
                            onClick={(e) => handleSubmit(e)}
                            type="button"
                            className="w-100"
                            style={{
                              backgroundColor: COLORS.primary.main,
                            }}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="secondary"
                            type="button"
                            onClick={(e) => { 
                              history.push("/")
                            }}
                            className="w-100 ml-3"
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form.Group>
                    </InputGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default Profile;
const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "blue",
    cursor: "pointer",
  },
};