import { LocationActionTypes } from "./LocationActionTypes";
import API from "../../Utils/API";

export const setMatketPlaceToLocalStorage = (marketPlace) => {
  return { type: LocationActionTypes.SET_MARKET_PLACE, payload: marketPlace };
};
export const setDefaultCountryToLocalStorage = (country) => {
  return { type: LocationActionTypes.SET_DAFAULT_COUNTRY, payload: country };
};


const makeLocationRequest = () => {
  return { type: LocationActionTypes.MAKE_LOCATION_REQUEST };
};

const setCities = (data) => {
  return {
    type: LocationActionTypes.SET_CITIES,
    payload: data,
  };
};

export const setCityId = (cityId) => {
  return {
    type: LocationActionTypes.SET_CITYID,
    payload: cityId,
  };
};
export const setCityName = (cityName) => {
  return {
    type: LocationActionTypes.SET_CITYNAME,
    payload: cityName,
  };
};

export const setStateId = (stateId) => {
  return {
    type: LocationActionTypes.SET_STATEID,
    payload: stateId,
  };
};

const setAreas = (data) => {
  return {
    type: LocationActionTypes.SET_AREAS,
    payload: data,
  };
};

export const setAreaId = (areaId) => {
  return {
    type: LocationActionTypes.SET_AREAID,
    payload: areaId,
  };
};

export const setLocation = (state, city) => {
  return {
    type: LocationActionTypes.SET_LOCATION,
    payload: `${state}, ${city}`,
  };
};

export const setError = (error) => {
  return {
    type: LocationActionTypes.ERROR,
    payload: error,
  };
};

// Get All Cities
export const getCities = (userId, stateId) => {
  return (dispatch) => {
    dispatch(makeLocationRequest());
    API.getCitiesAPI(userId, stateId)
      .then((result) => {
        dispatch(
          setCities(
            result.map((item) => ({ cityId: item.id, cityName: item.name }))
          )
        );
      })
      .catch((err) => {
        // dispatch(setError(err));
      });
  };
};

// Get All Areas
export const getAreas = (userId, cityId) => {
  return (dispatch) => {
    dispatch(makeLocationRequest());
    API.getAreasAPI(userId, cityId)
      .then((result) => {
        dispatch(
          setAreas(
            result.map((item) => ({ areaId: item.id, areaName: item.name }))
          )
        );
      })
      .catch((err) => {
        // dispatch(setError(err));
      });
  };
};
