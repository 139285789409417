import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  getActiveSectionItem,
  getAllStores,
  getInStores,
  getOnLineStores,
  getStoresByCategory,
  getSubCategory,
} from "../../../Redux/Stores/StoresActions";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from '@material-ui/core/CircularProgress';
import API from "../../../Utils/API";
// import Button from 'react-bootstrap/Button'
import { getUserSnips } from "../../../Redux/Snips/SnipsAction";
import { Button } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function StoresHeader(props) {
  const { userInfo } = useAuth();
  const [value, setValue] = useState(0);
  // const [toggle, setToggle] = useState(false);
  const [toggle, setMoreToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabToggle, setTabToggle] = useState("Accessories");
  const { storesCategories } = useSelector((state) => state.stores);
  const [allCategoriesArray, setAllCategoriesArray] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryToggle, setCategoryMoreToggle] = useState(false);

  const setToggle = () => {
    setMoreToggle(!toggle);
  };

  const setCategoryToggle = () => {
    setCategoryMoreToggle(!categoryToggle);
  };

  const clickOnCategory = (catId) => {
    setMoreToggle(!toggle);
    history.push(`/product/` + catId, {previous:"stores"});
  };

  document.addEventListener("click", function (event) {
    var ignoreClickOnMeElement = document.getElementById("departmentId");
    //console.log("ignoreClickOnMeElement>>>>>>>>>>>>>",ignoreClickOnMeElement);
    //console.log("event>>>>>>>>>>>>>",event);
    if (ignoreClickOnMeElement != null) {
      var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
      if (!isClickInsideElement) {
        setMoreToggle(false);
        //Do something click is outside specified element
      }
    }
  });

  document.addEventListener("click", function (event) {
    var ignoreClickOnMeElement = document.getElementById("moreMenu");
    //console.log("ignoreClickOnMeElement>>>>>>>>>>>>>",ignoreClickOnMeElement);
    //console.log("event>>>>>>>>>>>>>",event);
    if (ignoreClickOnMeElement != null) {
      var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
      if (!isClickInsideElement) {
        setCategoryMoreToggle(false);
        //Do something click is outside specified element
      }
    }
  });

  const setMenu = (value, catId) => {
    setTabToggle(value);
    setLoading(true);
    API.subCategoriesListByName(
      userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
      value
    )
      .then((result) => {
        if (result !== undefined) {
          setSubCategories(result);
          setLoading(false);
        } else {
          setSubCategories([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
  };

  let allCategories = [];
  /*const allCategoriesArray = ["All", "New"].concat(
  storesCategories.map((category) => category.categoryName)
);*/
  const {
    location,
    userId,
    cityId,
    cityName,
    areaId,
    stateId,
    marketPlace: selectedRefMarketPlace,
  } = useSelector((state) => state.location);
  const { subCategory, allStoreList } = useSelector((state) => state.stores);

  const history = useHistory();
  const { path, params } = useRouteMatch();
  let currPath = path.split(":")[0];
  console.log("current path in header>>>>>>>>>>>>>>",currPath);
  if(currPath=="/follow")
  currPath="/offers/"

  const [mainCategoryList, setMainCategoryList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    // setValue(allCategoriesArray.indexOf(params.tabName));
    dispatch(getUserSnips(userInfo?.email, "like"));
    API.getMainCategories(
      userInfo != null && userInfo != undefined ? userInfo.email : "gust"
    )
      .then((result) => {
        console.log("result.data>>>>>>>>>>>>>>>>>>", result);
        if (result != undefined && result.length > 0) {
          setMainCategoryList(result);
          setTabToggle(result[0].name)
          allCategories = ["Trending", "All Stores"].concat(
            result.map((category) => category.name)
          );
          allCategories.map((items, index) => {
            if(params.tabName !== "Trending" && items === params.tabName && index >= 6 ) {
              allCategories.splice(6, 0, params.tabName);
              allCategories.splice(index+1, 1);
            }
          })
          setAllCategoriesArray(allCategories);
          setValue(allCategories.indexOf(params.tabName));
          console.log("allCategoriesArray>>>>>>>>>>>>>>>>>>", allCategories);
        }
      })
      .catch((error) => {
        console.log(error);
        // console.log(error);
      });

    /* API.subCategoriesListByName(userInfo.email,"all").then((result) => {  
        console.log("result.data>>>>>>>>>>>>>>>>>>",result);
          if(result!=undefined)
          {
              //console.log('data=='+result.data);
              setSubCategories(result)
             // setLoading(false);
  
            
            
          }
          else
          {
          setSubCategories([])
         // setLoading(false);
          }
  
              })
              .catch((error) => {
                console.log(error);
                console.log(error);
              });*/
    /*setLoading(true);
    API.subCategoriesListByName(userInfo != null && userInfo != undefined ? userInfo.email : "gust","Automotive").then((result) => {  
      console.log("result.data>>>>>>>>>>>>>>>>>>",result);
        if(result!=undefined)
        {
            //console.log('data=='+result.data);
            setSubCategories(result)
            setLoading(false);

          
          
        }
        else
        {
        setSubCategories([])
        setLoading(false);
        }

            })
            .catch((error) => {
              console.log(error);
              console.log(error);
            });*/
  }, [params, location]);

  const handleChange = (event, newValue) => {
    const tabName = event.target.innerText;
    setValue(newValue);
    if (tabName !== "TRENDING" && tabName !== "NEW" && tabName !== "ALL STORES") {
      dispatch(
        getStoresByCategory(
          userInfo?.email || userId,
          mainCategoryList[newValue - 2].id,
          cityId,
          stateId,
          "store",selectedRefMarketPlace
        )
      );
      dispatch(
        getActiveSectionItem(
          userInfo?.email || userId,
          mainCategoryList[newValue - 1].id,
          cityId,
          stateId,
          "Store",selectedRefMarketPlace
        )
      );
      dispatch(
        getOnLineStores(
          userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
          cityId,
          cityName,
          stateId,
          mainCategoryList[newValue - 2].id,selectedRefMarketPlace
        )
      );
    } else if (tabName === "ALL STORES") {
      history.push(`/All/Stores`);
    } else {
      dispatch(
        getAllStores(userInfo?.email || userId, cityId, stateId, "store",selectedRefMarketPlace)
      );
      dispatch(
        getActiveSectionItem(
          userInfo?.email || userId,
          "Trending",
          cityId,
          stateId,
          "Store",selectedRefMarketPlace
        )
      );
    }
  };
  const handleMenuChange = (newValue, index) => {
    //const tabName = event.target.innerText;
    history.push(`${currPath}${newValue}`);
    setValue(newValue);
    var CatId = "";
    mainCategoryList.map((item, index) => {
      if (item.name == newValue) CatId = item.id;
    });

    dispatch(
      getStoresByCategory(
        userInfo?.email || userId,
        CatId,
        cityId,
        stateId,
        "offer",selectedRefMarketPlace
      )
    );
    dispatch(
      getOnLineStores(
        userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
        cityId,
        cityName,
        stateId,
        mainCategoryList[index - 2].id,selectedRefMarketPlace
      )
    );
  };
  const additionalStyle = props.noHeader
    ? {
      paddingLeft: "4%",
        width: "fit-content",
        left: "10px",
      }
    : {};
  return (
    <div className="containerHeader" style={additionalStyle}>
      <Button
        id="departmentId"
        onClick={() => {
          setToggle();
        }}
        className="department-button"
        variant="contained"
        color="primary"
        // className={classes.button}
      >
        Department
        {toggle ? (
          <FontAwesomeIcon
            icon={faAngleUp}
            style={{ paddingLeft: "10px", height: "20px" }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ paddingLeft: "10px", height: "20px" }}
          />
        )}
      </Button>
      <div
        style={{
          position: "absolute",
          top: 54,
          display: "flex",
          flexDirection: "column",
          alignSelf: "flex-start",
        }}
      >
        <div
          className={
            toggle ? "dowpdown-wrapper d-block" : "d-none dowpdown-wrapper"
          }
        >
          <div class="categories-inner">
            <ul className="dept-list-container">
              {mainCategoryList.map((item, index) => (
                <li>
                  <a
                    href="#"
                    onMouseOver={() => {
                      setMenu(item.name, item.id);
                    }}
                    id={item.id}
                    className={tabToggle == item.name ? "active" : ""}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>

            <div className="dept-category-container container">
              <a className="flyout-all-dept-link">All {tabToggle}</a>
              {!loading ? (
                <div className="row">
                  {subCategory && subCategory[tabToggle]?.map(
                    (subItem) =>
                      subCategory[subItem.id]?.length > 0 && (
                        <div className="col-md-4 pr-5">
                          <li className="category-column-section">
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                clickOnCategory(subItem.id);
                              }}
                              className="category-col-header-link"
                            >
                              {subItem.name}
                            </a>

                            <ul className="category-section-items-list">
                              {subCategory[subItem.id] !== undefined
                                ? subCategory[subItem.id].map(
                                    (subCat, index1) => (
                                      <li>
                                        <a
                                          onClick={() => {
                                            clickOnCategory(subCat.id);
                                          }}
                                          href="javascript:void(0)"
                                        >
                                          {subCat.name}
                                        </a>
                                      </li>
                                    )
                                  )
                                : ""}
                            </ul>
                          </li>
                        </div>
                      )
                  )}
                </div>
              ) : (
                <div
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <CircularProgress
                    color="primary"
                    style={{ color: "#1d6042" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!props.noHeader && (
        <div className="d-flex align-items-center menu-tab">
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="off"
            className="headerTabs"
          >
            {allCategoriesArray.map((item, index) =>
              index <= 6 ? (
                <Tab
                  {...a11yProps(index)}
                  label={item}
                  key={item}
                  style={{ minWidth: "120px" }}
                  onClick={() =>
                    item === "All Stores"
                      ? history.push(`/All/Stores`)
                      : history.push(`${currPath}${item}`)
                  }
                />
              ) : (
                ""
              )
            )}
          </Tabs>
          <div className="position-relative">
            <div
              className="more"
              id="moreMenu"
              onClick={() => {
                setCategoryToggle();
              }}
            >
              More{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>

            <div
              className={
                categoryToggle ? "more-dropdown d-block shadow" : "d-none"
              }
            >
              <div className="row">
                {allCategoriesArray.map((item, index) =>
                  index > 6 ? (
                    <div
                      className="col-md-4 tab-text"
                      onClick={() => handleMenuChange(item, index)}
                      href="javascript:void(0)"
                    >
                      <ListItemText primary={item} />
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StoresHeader;
