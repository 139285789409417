import React from "react";
import PrivateRoute from "../../Utils/PrivateRoute";
import { Route, Redirect, Switch } from "react-router-dom";
import Offers from "../Container/Offers/Offers";
import OffersInfo from "../Container/Offers/OffersInfo";
import Stores from "../Container/Stores/Stores";
import Snips from "../Container/Snips";
import StoresDetails from "../Container/Stores/StoresDetails";
import Product from "../Container/product/Product";
import ProductDetails from "../Container/product/ProductDetails";
import CollectionDetail from "../Container/Stores/CollectionDetail";
import Profile from "../Container/Profile/Profile";
import Search from "../Container/Search/Search";
import OffersAll from "../Container/Offers/OffersAll";
import AllProducts from "../Container/product/AllProducts";
import AllStores from "../Container/Stores/AllStores";
import PrivacyPolicy from "../Container/PrivacyPolicy";
import SectionProductCard from "../Container/Offers/SectionProducts";
import AllStoresList from "../Container/Stores/AllStoresList";
import StoreFollwing from "../Container/Stores/StoreFollwing";
import SectionOfferCard from "../Container/Offers/SectionOfferCard";
import FAQ from "../Container/FAQ";
import ContactUs from "../Container/ContactUs";
import AboutUs from "../Container/Search/AboutUs";
import DownloadApp from "../Container/DownloadApp";
import ProductShare from "../Container/ProductShare";
import OfferShare from "../Container/OfferShare";
import AboutStore from "../Container/Stores/AboutStore";
import TermsOfUse from "../Container/TermsofUse";
import AdvertiserAgreement from "../Container/AdvertiserAgreement";
import UserNotification from "../Container/Notifications/UserNotification";
import {isMobile} from 'react-device-detect';
import MobileHomePage from "../Container/MobileHomePage";
import RedirectToCart from "../Container/product/RedirectToCart";
import UserDeleteConfirmation from "../Container/Profile/UserDeleteConfirmation";
import RedirectToBulkCart from "../Container/product/RedirectToBulkCart";
import ShopifyPrivacyPolicy from "../Container/ShopifyPrivacyPolicy";
import MyVideo from "../Container/MyVideo";
import Rating from "../Container/product/Rating";
import UserDeletion from "../Container/Auth/UserDeletion";

function Container(props) {
  return (
    <div className="mainContainer">
      {isMobile?
         <Route exact strict path="/" render={() => <Redirect to={`mobileHome`} />} />
    :""}
      <Route exact path="/user-deletion" component={UserDeletion} />
      <Route exact path="/offers/:tabName" component={isMobile?MobileHomePage:Offers} />
      <Route exact path="/stores/:tabName" component={isMobile?MobileHomePage:Stores} />
      <Route exact path="/offers/:tabName/:offerId" component={OffersInfo} />
      <Route exact path="/rating/:productId" component={Rating} />
      <PrivateRoute exact path="/snips" component={Snips} />
      <PrivateRoute exact path="/follow" component={StoreFollwing} />
      <PrivateRoute exact path="/notification" component={UserNotification} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <Route exact path="/search/:searchText/:catId" component={Search} />
      <Route exact path="/storeDetails/:storeId" component={isMobile?MobileHomePage:StoresDetails} />
      <Route exact path="/product/:tabName" component={Product} />
      <Route exact path="/productDetail/:storeId/:productId" component={ProductDetails} />
      <Route exact path="/collectionDetail/:offerId/:storeId" component={CollectionDetail} />
      <Route exact path="/Returns/:storeId" component={AboutStore} />
      <Route exact path="/AllOffers/:offerName/:tabName" component={OffersAll} />
      <Route exact path="/AllProducts/:offerName/:tabName" component={AllProducts} />
      <Route exact path="/All/:storeName/:tabName" component={AllStores} />
      <Route exact path="/All/Stores" component={AllStoresList} />
      <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
      <Route exact path="/shopPrivacyPolicy" component={ShopifyPrivacyPolicy} />
      <Route exact path="/myVideo" component={MyVideo} />
      <Route exact path="/advertiserAgreement" component={AdvertiserAgreement} />
      <Route exact path="/termsOfuse" component={TermsOfUse} />
      <Route exact path="/faq" component={FAQ} />
      <Route exact path="/contact" component={ContactUs} />
      <Route exact path="/about" component={AboutUs} />
      <Route exact path="/download" component={DownloadApp} />
      <Route exact path="/productShare/:productId" component={ProductShare} />
      <Route exact path="/mobileHome" component={MobileHomePage} />
      <Route exact path="/offerShare/:offerId" component={OfferShare} />
      <Route exact path="/:sectionName/allProducts/:tabName" component={SectionProductCard} />
      <Route exact path="/:sectionName/allOffers/:tabName" component={SectionOfferCard} />
      <Route exact path="/redirectToCart/:productId/:email/:quantity" component={RedirectToCart} />
      <Route exact path="/redirectToBulkCart/:productId/:email/:storeId/:quantity" component={RedirectToBulkCart} />
            <Route exact path="/userDeleteConfirmation/:email" component={UserDeleteConfirmation} />
      {!isMobile?<Route exact strict path="/" render={() => <Redirect to={`offers/Trending`} />} />
      :""}
    </div>
  );
}

export default Container;
