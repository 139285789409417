import React, { Events, useState } from "react";
import "../Container.css";
import API from "../../../Utils/API";
import { pushSnipsData, getUserSnips } from "../../../Redux/Snips/SnipsAction";
import "react-toastify/dist/ReactToastify.css";
import "moment-timezone";
import { connect } from "react-redux";
import AuthContext from "../../../Utils/AuthContext";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "antd/dist/antd.css";

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "#1d6042",
    cursor: "pointer",
  },
  tooltipIcon: {
    color: "#1d6042",
  },
};

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  centerMode: false,
};

class RedirectToBulkCart extends React.Component {
  static contextType = AuthContext;

  constructor() {
    super();
    this.state = {
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      loading: false,
      productData: [],
      productRating: [],
      storeData: []
    };
  }

  componentDidMount() {
    const user = this.context;
    this.setState({ userInfo: user.userInfo });     
            API.getStoreById(this.props.match.params.storeId).then((result) => {
                    console.log("Store Data ::::",result);
                    if (result != undefined) {
                      this.setState({ storeData: result },()=>{
                        this.handleBuy();
                      });
                    }
                    else {
                      this.setState({ loading: false });
                    }
                  }).catch((error) => {
                    console.log(error);
                    console.log(error);
                  });
       
     
  }

  handleBuy = async () => {
        
        API.productBuyNow(this.props.match.params.email,this.props.match.params.productId);
      //  if(this.state.isShopifyProduct=="Y") {
        const shopifyToken= await API.getShopifyShopToken(this.state.storeData.websiteUrl);
        let storeFrontToken="";
        if(shopifyToken!=undefined&&shopifyToken!=null&&shopifyToken!="")
        {
          storeFrontToken=shopifyToken.storefrontToken
        }
          var catUrl="https://"+this.state.storeData.websiteUrl+"/cart/"+this.props.match.params.productId+"?access_token="+storeFrontToken+"&checkout[email]="+this.props.match.params.email+"&attributes[utm_source]=shopiads";
         window.location.replace(catUrl);
        // window.location.replace(this.state.productData.vendorUrl+"?utm_source=shopiads");

       /* } else {
           window.location.replace(this.state.productData.vendorUrl+"?utm_source=shopiads");
        }*/
  };

  
  
  render() {
    SwiperCore.use([Navigation, Pagination]);
    return (
      <>
        <strong>Please wait redirecting to vendor cart ....</strong>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  allSnips: state.snips,
  allLikes: state.snips,
  loading: state.snips,
});

const mapDispatchToProps = (dispatch) => {
  return {
    pushSnips: (snipBody) => pushSnipsData(snipBody),
    getUserSnips: (email, like) => getUserSnips(email, like),
  };
};
export default connect(mapStateToProps, mapDispatchToProps())(RedirectToBulkCart);
