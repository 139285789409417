import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import { getUserSnips, removeSnipsData } from "../../Redux/Snips/SnipsAction";
import API from "../../Utils/API";
import { useAuth } from "../../Utils/AuthContext";
import Checkbox from "@material-ui/core/Checkbox";
import StoresHeader from "./Stores/StoresHeader";
import environment from "../../Utils/env";

function AdvertiserAgreement() {
  return (
    <>
      <div class="wpb_wrapper mt-4">
        <div className="d-flex justify-content-center">
          <h2 className="mt-4">Advertiser Agreement</h2>
        </div>
        <p>
          These terms contain legal obligations and form an agreement between
          you (collectively, the “Advertiser”, “you”, “your”,” Store”, or”
          Business”) and SHOPIADS Inc, as applicable (collectively, the
          “SHOPIADS,” “us,” “we,” or “our”). Please read these terms carefully
          before using any of our services.
        </p>

        <ul style={{ marginLeft: "40px" }}>
          <li>
            ShopiAds onboard stores/business who are incorporated. Advertiser is
            solely responsible to prove the identity or resolve the dispute
            under applicable law.
          </li>
          <li>
            In order to display or promote product, collection, offer or service
            listing, Advertiser allow ShopiAds or its affiliates to collect,
            store content from the Advertiser data source using website or any
            other means.
          </li>
          <li>
            All the advertisement content must comply with{" "}
            <a href={`${environment.CANADA_BASE_URL}/termsOfuse`} target="_new">
              {" "}
              Terms of use
            </a>
            ,{" "}
             <a href={`${environment.CANADA_BASE_URL}/privacyPolicy`}  target="_new">
              Privacy policy
            </a>{" "}
            and other applicable policies and applicable law and regulations.
            You may not Advertise or sell counterfeit or pirated products or
            services and you are solely responsible for any damages according to
            the applicable law.
          </li>
          <li>
            Advertiser should provide content in the format required by ShopiAds
            but we may need to re-format the content for the optimum
            presentation to adapt to technical requirement of platform, however
            contents will not be altered in a manner that would mislead the
            original content. ShopiAds retains the full modification rights over
            the content, appearance, functionality and non-exclusive,
            royalty-free rights to use, distribute, run, copy, publicly display,
            content provided by you or accessed by us in connection with the
            ShopiAds platform.
          </li>
          <li>
            Advertiser is responsible for the contents of advertisement
            including logos, images, description, price and acknowledge that
            such content will be true, correct and verified.
          </li>
          <li>
            Following products and services are prohibited to be advertised on
            ShopiAds
          </li>
          <ul style={{ marginLeft: "40px" }}>
            <li>Tobacco and related products</li>
            <li>Illegal products or services banned by the law</li>
            <li>Weapons, ammunition or explosives</li>
            <li>Sexual product, content or services </li>
            <li>Third-party infringement </li>
            <li>Inflammatory content </li>
            <li>Unsafe substances to health or drugs</li>
          </ul>
          <li>
            Copyright, trademark, privacy, publicity or other personal or
            proprietary rights and other legal disclosures.
          </li>
          <li>
            Advertiser should display any privacy or other terms related to
            content that will be applicable to your interaction with Users. Any
            such terms, information or policies will not conflict with our terms
            and policies. Advertiser acknowledges that any of this conflict, may
            result in the suspension of Advertiser’s user account. Advertiser
            acknowledges that we reserve the unrestricted right to: (a) not
            display any of the content it receives which contains a technical
            malfunction or for any other reason as determined by us; and (b)
            cancel, suspend or discontinue display of any Listings which
            violates our{" "}
            <a href={`${environment.CANADA_BASE_URL}/termsOfuse`} target="_new">
              {" "}
              Terms of use
            </a>{" "}
            or{" "}
            <a href={`${environment.CANADA_BASE_URL}/privacyPolicy`} target="_new">
              Privacy policy
            </a>
            .
          </li>
          <li>
            Advertiser is responsible for any defect and complying with any
            recall or safety alert with respect to any product related to your
            advertisement. You agree to immediately remove any advertisement
            upon issuance of any recall or safety{" "}
          </li>
          <li>
            Alert or allegation of infringement of intellectual property rights
            with respect to products relating to your advertisement.{" "}
          </li>
          <li>
            We are not responsible for fulfilling any sales or customer service
            or managing returns in connection with your use of a ShopiAds
            platform{" "}
          </li>
          <li>
            Users post content, including ratings and reviews, related to your
            advertisement. You acknowledge that software algorithm will be used
            to present more useful ratings and reviews to Users. You may not
            submit or allow others to submit illegitimate ratings or reviews in
            regards to your advertisement.{" "}
          </li>
          <li>
            You may not engage directly with Users for marketing or any other
            purpose unless the User has opted for your marketing or any other
            service{" "}
          </li>
          <li>
            As per Data Collection and User policy the consumer account and data
            will be deleted after 6 months once the user request to unregister
            from ShopiAds.Hence our reports will show history data back up to 6
            months only.{" "}
          </li>
          <li>
            These terms do not create any partnership, joint venture or agency
            relationship between you and ShopiAds{" "}
          </li>
          <li>
            Advertiser shall pay the applicable fees as defined during the
            creation of the Campaign at the standard rates, unless any discount
            or promotional rates are provided to Advertiser. We will not be
            liable for any damages incurred by Advertiser from setting up wrong
            campaign in connection with any listing.{" "}
          </li>
          <li>
            ShopiAds will issue invoice for each campaign and provide the
            transaction statement for the executed campaign. Advertiser will be
            responsible for applicable taxes as per the region of service. On
            request Advertiser will provide ShopiAds all the paid tax receipts
            to establish that such taxes have been paid which might be needed by
            authorities. Advertiser authorizes ShopiAds to investigate
            Advertiser’s credit and financial standing by any reasonable means
            necessary, and Advertiser allows us to share Advertiser’s credit
            card information with companies who work on ShopiAds behalf for the
            purpose of credit checks.{" "}
          </li>
          <li>
            We strive to make service secure and available all the time, however
            no method over the internet is 100% reliable and we do not warrant
            that it will operate securely or without interruption. In no event
            we are responsible for any malfunction of the service or any loss of
            revenue, exemplary damages, whether based in contract, tort,
            negligence or any other legal claim.{" "}
          </li>
          <li>
            In the event of a conflict between these Terms and any other terms,
            Advertiser Agreement shall govern solely with respect to your use of
            the ShopiAds platform. We may change or update these Terms at any
            time without notice as we deem necessary to the full extent
            permitted by law, Any matter hereby irrevocably attorn to the
            jurisdiction of the courts of the Province of Ontario. These Terms
            shall be construed and interpreted in accordance with the laws of
            the Province of Ontario and the federal laws of Canada as
            applicable.{" "}
          </li>
        </ul>
        <p>
          <strong>Effective: Mar 22, 2022</strong>
        </p>
      </div>
    </>
  );
}

export default AdvertiserAgreement;
