// export default CustomerSelectOption;
import { MenuItem, styled } from "@material-ui/core";
import React from "react";

const CustomerSelectOption = styled("option")(({ theme }) => ({}));
export default CustomerSelectOption;
// import { MenuItem, styled } from "@material-ui/core";
// import React from "react";

// const CustomerSelectOption = styled(MenuItem)(({ theme }) => ({
//   "&:hover": {
//     backgroundColor: "#1d6042", // Change to your desired color
//     color: "white",
//     fontWeight:"bold"
//   },
//   "&.Mui-selected": {
//     backgroundColor: "#1d6042", // Selected color
//     "&:hover": {
//       backgroundColor: "#1d6042", // Keeps the selected color on hover
//     },
//   },
// }));
