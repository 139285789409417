import { OffersActionTypes } from "./OffersActionTypes";

const initialState = {
  loading: false,
  allOffers: [],
  categories: [],
  categoryWiseOffers: [],
  error: "",
};

const offersReducer = (state = initialState, action) => {
  switch (action.type) {
    case OffersActionTypes.MAKE_OFFERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OffersActionTypes.SET_OFFERS_DATA:
      return {
        ...state,
        loading: false,
        allOffers: action.payload,
        categories: action.payload.categories.map((item) => ({
          categoryId: item.id,
          categoryName: item.name,
        })),
        error: "",
      };

    case OffersActionTypes.SET_OFFERS_DATA_CATEGORYWISE:
      return {
        ...state,
        loading: false,
        categoryWiseOffers: action.payload,
        error: "",
      };

    case OffersActionTypes.SET_OFFERS_ERROR:
      return {
        loading: false,
        allOffers: [],
        categories: [],
        categoryWiseOffers: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default offersReducer;
