import React, { Events, useState } from "react";
import "../Container.css";
import API from "../../../Utils/API";
import "react-toastify/dist/ReactToastify.css";
import "moment-timezone";
import { connect } from "react-redux";
import AuthContext from "../../../Utils/AuthContext";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "antd/dist/antd.css";

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "#1d6042",
    cursor: "pointer",
  },
  tooltipIcon: {
    color: "#1d6042",
  },
};



class UserDeleteConfirmation extends React.Component {
  static contextType = AuthContext;

  constructor() {
    super();
    this.state = {
      isUserAdmin:false,
    };
 
  }

  componentDidMount() {
    
    console.log("email Params ::::",this.props.match.params.email);
    API.getUserByEncodingId(this.props.match.params.email).then((result) => {

        if(result?.authType!="A" && result?.isMaster!="Y")
        {
          API.getUserByEncodingIdUS(this.props.match.params.email).then((result) => {

            if(result?.authType!="A" && result?.isMaster!="Y")
            {
              API.userDelete(this.props.match.params.email)
              .then((result) => {
                console.log("user markUserDelete Details ::::",result);
              
              }).catch((error) => {
                
                console.log(error);
              });
        
              API.userDeleteUS(this.props.match.params.email)
              .then((result) => {
                console.log("user markUserDelete Details ::::",result);
              
              }).catch((error) => {
                
                console.log(error);
              });
            }
            else
            {
              this.setState({ isUserAdmin: true });
            }
          
    
        })
        .catch((error) => {
          console.log(error);
          console.log(error);
        });

        }
        else
        {
          this.setState({ isUserAdmin: true });
        }
      

    })
    .catch((error) => {
      console.log(error);
      console.log(error);
    });

  }
  
  
  render() {
    SwiperCore.use([Navigation, Pagination]);
    return (
      <>
      <br/>
      <br/>
      <h5  className="mt-4">{this.state.isUserAdmin?<strong>Admin user needs verification before deleting, please contact support team on support@shopiads.com </strong>:<strong>Account has been deleted successfully </strong>}</h5>
       {/* <h5  className="mt-4"><strong>User will be deleted after 6 months</strong></h5>*/}
      </>
    );
  }
}

export default UserDeleteConfirmation;


