import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storesReducer from "./Stores/StoresReducer";
import offersReducer from "./Offers/OffersReducer";
import locationReducer from "./Location/LocationReducer";
import snipsReducer from "./Snips/SnipsReducer";

const persistConfig = {
  key: "root", // key for the localStorage key
  storage, // storage engine to use
  whitelist:["location","snips"],
  timeout: 0, // optional - time to wait before state write (e.g. for debounce/throttle)
};
const RootReducer = combineReducers({

  location: locationReducer,
  offers: offersReducer,
  stores: storesReducer,
  snips: snipsReducer,
});

const persistedReducer = persistReducer(persistConfig, RootReducer);

export default persistedReducer;
