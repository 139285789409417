import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Msg = ({ title, text }) => {
  return (
    <div className="msg-container">
      <p className="msg-title">{title}</p>
      <p className="msg-description">{text}</p>
    </div>
  );
};
const NotificationPopUp = (myProps, toastProps) =>
  toast(<Msg {...myProps} />, { ...toastProps });

NotificationPopUp.success = (myProps, toastProps) =>
  toast.success(<Msg {...myProps} />, { ...toastProps });

NotificationPopUp.info = (myProps, toastProps) =>
  toast.info(<Msg {...myProps} />, { ...toastProps });

export default NotificationPopUp;