import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ScrollTo from "react-scroll-into-view";
import { makeStyles } from "@material-ui/core/styles";
import Card from "./Card";
import API from "../../../Utils/API";
import { useAuth } from "../../../Utils/AuthContext";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { Link } from "react-scroll";
import { Select } from "@material-ui/core";
import CustomerSelectOption from "../../UI/Input/CustomerSelectOption";
import CustomSelect from "../../UI/Container/CustomSelect";

const style = {
  backButton: {
    fontSize: "25px",
    marginTop: "10px",
    color: "#1d6042",
    cursor: "pointer",
  },
};

const useStyles = makeStyles((theme) => ({
  mainToolbar_MiddleNav: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    position: "absolute",
  },
  search: {
    backgroundColor: "white",
    height: "44px",
    width: "260px",
    borderRadius: "5px",
    position: "relative",
    display: "flex",
    border: "1px solid #dee2e6",
    marginLeft: "20px",
  },
  searchCategory: {
    width: "110px",
    height: "42px",
    borderRadius: "5px",
    // backgroundColor: "#cdec85",
    color: "#1d6042",
    textAlign:'center',
    border: "none",
    padding: "0px 5px",
    marginLeft: "20px",
    "& option": {
      backgroundColor: "#FEFEFE",
      color: "#1d6042",
    },
  },
  searchIcon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "5px 5px 5px 10px",
    "& svg": {
      fill: "#9A9A9A",
    },
  },
  inputInput: {
    padding: "5px 10px",
    width: "100%",
  },
}));

function AllStoresList() {
  const history = useHistory();
  const classes = useStyles();
  const { userInfo } = useAuth();
  const { allStoreList } = useSelector((state) => state.stores);
  const [storesData, setStoresData] = useState({});
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [searchText, setSearchText] = useState("");
  const [selectedLetter, setSelectedLetter] = useState("");
  const [offset, setOffset] = useState(-300);
  const { marketPlace: selectedRefMarketPlace } = useSelector(
    (state) => state.location
  );
  useEffect(() => {
    API.getMainCategories(
      userInfo != null && userInfo !== undefined ? userInfo.email : "gust"
    )
      .then((result) => {
        if (result != undefined && result.length > 0) {
          setMainCategoryList(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let finalData = {};
    alphabets.map((item, key) => {
      let data = [];
      allStoreList !== undefined &&
        allStoreList.length > 0 &&
        allStoreList.map((items, index) => {
          if (item.toUpperCase() === items.storeName.substring(0, 1).toUpperCase()) {
            data.push(items);
          }
        });
      finalData[item] = data;
    });
    setStoresData(finalData);
  }, [allStoreList]);

  console.log({ storesData });
  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const getCatChange = async (e, newValue) => {
    const data = {
      text: searchText ? searchText : "",
      catId: e.target.value ? e.target.value : "all",
      marketPlace:selectedRefMarketPlace,
    };
    if (e.target.value !== undefined && e.target.value !== "") {
      setSelectedCat(e.target.value);
      API.searchStore(data)
        .then((result) => {
          if (result !== undefined && result.length > 0) {
            let finalData = {};
            alphabets.map((item, key) => {
              let data = [];
              result !== undefined &&
                result.length > 0 &&
                result.map((items, index) => {
                  if (item.toUpperCase() === items.storeName.substring(0, 1).toUpperCase()) {
                    data.push(items);
                  }
                });
              finalData[item] = data;
            });
            setStoresData(finalData);
          }
          else
          {
            setStoresData({});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onSearchClick = (value) => {
    const data = {
      text: searchText ? searchText : "",
      catId: value ? value : "all",
      marketPlace:selectedRefMarketPlace,
    };
    API.searchStore(data)
      .then((result) => {
        if (result !== undefined && result.length > 0) {
          let finalData = {};
          alphabets.map((item, key) => {
            let data = [];
            result !== undefined &&
              result.length > 0 &&
              result.map((items, index) => {
                if (item.toUpperCase() === items.storeName.substring(0, 1).toUpperCase()) {
                  data.push(items);
                }
              });
            finalData[item] = data;
          });
          setStoresData(finalData);
          setOffset(100);
          var letter = String.fromCharCode(
            searchText.substring(0, 1).charCodeAt(0) - 3
          );
          var elmnt = document.getElementById(letter.toUpperCase());
          if (elmnt) {
            elmnt.scrollIntoView();
          }
        }
        else
        {
          setStoresData({});
         
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchChange = (e) => {
    console.log(e.target.value, "vvvvvvvvvvvvvv");
    setSearchText(e.target.value);
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearchClick();
      // handleSearchClick("/search");
    }
  };

  const viewSection = (path) => {
    var elmnt = document.getElementById(path);
      if (elmnt) {
        elmnt.scrollIntoView();
      }
  }

  const myFunction = (path) => { 
    if(searchText.length > 0) {
      setOffset(-300);
      setSearchText("");
      const data = {
        text: "",
        catId: "all",
        marketPlace:selectedRefMarketPlace,
      };
      API.searchStore(data)
        .then((result) => {
          if (result !== undefined && result.length > 0) {
            let finalData = {};
            alphabets.map((item, key) => {
              let data = [];
              result !== undefined &&
                result.length > 0 &&
                result.map((items, index) => {
                  if (item.toUpperCase() === items.storeName.substring(0, 1).toUpperCase()) {
                    data.push(items);
                  }
                });
              finalData[item] = data;
            });
            setStoresData(finalData);
            var elmnt = document.getElementById(path.toUpperCase());
            elmnt.style.paddingTop= "200px";
            if (elmnt) {
              elmnt.scrollIntoView();
            }
          }
          else
          {
            setStoresData({});
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      var elmnt = document.getElementById(path);
      elmnt.style.paddingTop= "40px"
      if (elmnt) {
        elmnt.scrollIntoView();
      }
    }
  };

  return (
    <>
      {/* <div> */}
      <div className="back-button">
        <i
          style={{ ...style.backButton, position: "relative" }}
          className="fa fa-arrow-circle-left"
          aria-hidden="true"
          title="Back to Login"
          onClick={() => history.goBack()}
        ></i>
        <div className="d-flex align-items-center mb-3">
          <h4>Search By Category</h4>
          <CustomSelect
            className={classes.searchCategory}
            value={selectedCat}
            onChange={getCatChange}
          >
            <CustomerSelectOption value="all" selected={"all" === selectedCat}>
              All Stores
            </CustomerSelectOption>
            {mainCategoryList.map((item, index) => (
              <CustomerSelectOption key={index} value={item.id}>
                {item.name}
              </CustomerSelectOption>
            ))}
          </CustomSelect>
          <div className="ml-3 d-flex align-items-center">
            <h4>Search By Store Name</h4>
            <div className={classes.search}>
              <InputBase
                autoComplete="off"
                placeholder="Enter Store Name"
                onClick={(e) => searchChange(e)}
                onInput={(e) => {
                  setSearchText(e.target.value);
                }}
                onKeyDown={_handleKeyDown}
                name="searchText"
                value={searchText}
                classes={{
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
              <div className={classes.searchIcon}>
                <SearchIcon onClick={() => onSearchClick()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="alphabet-bar">
        {alphabets.map((name, index) => (
          <div key={index}>
            <Link
              activeClass="active"
              to={name}
              spy={true}
              smooth={true}
              offset={offset}
              duration={500}
              onClick={(e) => myFunction(name)}
              className={classes.aboutus_SideNav}
              variant="body1"
            >
              <h5 className="official-store-list-alphabet-letter">{name}</h5>
            </Link>
          </div>
        ))}
      </div>
      {/* </div> */}
      <div className="all-stores-container">
        <div className="container-fluid mt-4">
          <div className="store-container" style={{}}>
            {Object.keys(storesData).length > 0 ? (
              Object.keys(storesData).map((itemKey, index) => (
                <div
                  className="official-store-list-section-wrapper"
                  id={itemKey}
                >
                  <div className="official-store-list-section-item">
                    <div className="official-store-list-section-name">
                      {itemKey}
                    </div>
                    <div className="official-store-list-section-content">
                      {storesData[itemKey].map((items, index) => (
                        <Card id={itemKey} items={items} />
                      ))}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="official-store-list-section-item">
                <div className="official-store-list-section-content mt-4 ml-4">
                  <strong style={{ marginLeft: "200px" }}>
                    Store Not Found
                  </strong>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AllStoresList;
