import React, { useState } from 'react'
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from '../../../Utils/AuthContext';
import API from '../../../Utils/API';
import { COLORS } from '../../../Utils/context/ThemeContext';

export default function UserDeletion() {
    const [email, setEmail] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [btnDisable, setBtnDisable] = useState("");
    const handleChange = (e) => {
        setErrMsg("");
        setSuccessMsg("");
        setEmail(e.target.value);
        setBtnDisable(false);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setBtnDisable(true);
            API.sendUserDeleteMail(email).then(response => {
                setSuccessMsg("Please check your email Inbox/Spam to Unregister");
                setEmail("");
            });
        } catch (err) {
            setErrMsg(err);
        }
    };
    return (
      <div
        className="container-fluid"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            marginBottom: "100px",
            width: "60VW",
            minWidth: "320px",
          }}
        >
          <h2>Account Deletion and Data Privacy</h2>
          <p>
            To delete your account and its associated data, please follow these
            steps:
          </p>
          <div
            style={{
              paddingLeft: "20px",
              marginBottom: "40px",
              textAlign: "justify",
            }}
          >
            <ol>
              <li style={{ marginBottom: "5px" }}>
                Access the "Unregister User" option located on the login screen
                of the app. Alternatively, you can also use the following form
                to request user data deletion.
              </li>
              <li style={{ marginBottom: "5px" }}>
                Upon selecting "Unregister User," you will be prompted to input
                your email address. An email will then be sent to your provided
                email address to confirm ownership and your desire to delete the
                user account and associated data.
              </li>
              <li style={{ marginBottom: "5px" }}>
                Upon confirmation, your account and all relevant data will be
                promptly deleted, with the exception of any purchase orders, if
                applicable. Purchase orders are retained for a period of three
                years in compliance with regulatory requirements for{" "}
                <a href="privacyPolicy#data-retention">
                  accounting and tax purposes
                </a>
                .
              </li>
            </ol>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Form
              className="ForgotPasswordForm"
              style={{ minHeight: 0 }}
              onSubmit={handleSubmit}
            >
              <div style={{ flex: 1 }}>
                <Form.Group>
                  <Form.Label
                    className="text-left"
                    style={{ marginBottom: "20px", textAlign: "justify" }}
                  >
                    Kindly input your registered email address, and we will
                    proceed with unregistering the user.
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </div>
              <Button
                disabled={btnDisable}
                type="submit"
                className="w-100"
                style={{
                    backgroundColor: COLORS.primary.main,
                    marginTop: "20px"
                }}
              >
                Submit
              </Button>
              <div
                style={{
                  marginTop: "25px",
                }}
              >
                {errMsg && <Alert variant="danger">{errMsg}</Alert>}
                {successMsg && <Alert variant="success">{successMsg}</Alert>}
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
}
