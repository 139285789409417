import API from "../../Utils/API";
import { SnipsActionTypes } from "./SnipsActionTypes";

const makeSnipsRequest = () => {
  return { type: SnipsActionTypes.MAKE_SNIPS_REQUEST };
};

const makeLikesRequest = () => {
  return { type: SnipsActionTypes.MAKE_LIKES_REQUEST };
};

export const setSnipsData = (data) => {
  return {
    type: SnipsActionTypes.SET_SNIPS_DATA,
    payload: data,
  };
};

const setLikesData = (data) => {
  console.log("setlike data>>>>>>>>>>>>>>>>>",data);
  return {
    type: SnipsActionTypes.SET_LIKES_DATA,
    payload: data,
  };
};

export const pushSnipsData = (data) => {
  return {
    type: SnipsActionTypes.PUSH_SNIPS_DATA,
    payload: data,
  };
};

export const removeSnipsData = (data) => {
  return {
    type: SnipsActionTypes.REMOVE_SNIPS_DATA,
    payload: data,
  };
};

const setSnipsError = (error) => {
  return {
    type: SnipsActionTypes.SET_SNIPS_ERROR,
    payload: error,
  };
};

// Get all sub categories
export const getUserSnips = (userId,type) => {
  return (dispatch) => {
    if(type=="like")
    dispatch(makeLikesRequest());
    else
    dispatch(makeSnipsRequest());
    //API.getStoresByCategoryAPI(userId, categoryId, cityId, areaId)
    API.getUserSnips(userId,type)
      .then((result1) => {
        if(result1!=undefined)
        {
          if(type=="like")
          {
          dispatch(setLikesData(result1));
         // dispatch(setSnipsData([]));
          }
          else
          {
          dispatch(setSnipsData(result1));
         // dispatch(setLikesData([]));
          }
        }
        
      })
      .catch((err) => {
        console.log(err)
        dispatch(setSnipsError(err));
      });
  };
};
