export const StoresActionTypes = {
  MAKE_STORES_REQUEST: "MAKE_STORES_REQUEST",
  MAKE_SECTION_REQUEST: "MAKE_SECTION_REQUEST",
  SET_STORES_DATA: "SET_STORES_DATA",
  SET_STORES_PRODUCT_DATA: "SET_STORES_PRODUCT_DATA",
  SET_ALL_STORES_LIST_DATA: "SET_ALL_STORES_LIST_DATA",
  SET_ACTIVE_SECTION_DATA: "SET_ACTIVE_SECTION_DATA",
  SET_STORES_DATA_CATEGORYWISE: "SET_STORES_DATA_CATEGORYWISE",
  SET_STORES_ERROR: "SET_STORES_ERROR",
  SET_SUB_CATEGORY: "SET_SUB_CATEGORY",
  SET_NEAR_BY_STORES: "SET_NEAR_BY_STORES",
  SET_IN_STORES: "SET_IN_STORES",
  SET_ON_STORES: "SET_ON_STORES",
};
