import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "../../Utils/AuthContext";
import { COLORS } from "../../Utils/context/ThemeContext";

export default function ShopifyStoreDiscount(props) {
  const { handleClose, handleOpen,storeData, open } = props;
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [btnDisable, setBtnDisable] = useState("");

  const handleChange = (e) => {
    setErrMsg("");
    setSuccessMsg("");
    setEmail(e.target.value);
  };
  const { resetPassword } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setBtnDisable(true);
      await resetPassword(email);
      setSuccessMsg("Check your inbox/spam folder for further instruction");
      setEmail("");
    } catch (err) {
      setErrMsg(err.code.split("/")[1]);
    }
    setBtnDisable(false);
  };
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => handleClose("forgotPassword")}
      aria-labelledby="console.log-dialog-slide-title"
      aria-describedby="console.log-dialog-slide-description"
    >
      <div className="discountForm">
        <div className="text-center mb-4">
          <strong>Apply Discount at Checkout</strong>
        </div>

        {storeData != undefined &&
        storeData.shopifyDiscount != undefined &&
        storeData.shopifyDiscount != null &&
        storeData.shopifyDiscount != "" ? (
          storeData.shopifyDiscount.map((item, index) => (
            <div class="container mt-2">
              <div class="row">
                <div class="col-sm2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-4"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                </div>
                <div class="col-sm">{item}</div>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-4">
            <strong>No additional discounts available at this moment</strong>
          </div>
        )}

        <div className="text-center">
          <Button
            type="submit"
            className="w-30 text-center mt-4"
            onClick={() => {
              handleClose();
            }}
            style={{
              backgroundColor: COLORS.primary.main,
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
