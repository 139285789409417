import React, { useState, useEffect } from "react";
import "./Form.css";
import Dialog from "@material-ui/core/Dialog";
import { useAuth } from "../../Utils/AuthContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { Rating } from "@material-ui/lab";
import API from "../../Utils/API";
import { uuid } from "../../Utils/uuid";
import { COLORS } from "../../Utils/context/ThemeContext";

export default function RatingForm(props) {
  const { userInfo } = useAuth();
  const { handleClose, handleOpen, open, storeId, productId, offerId, type } =
    props;
  const [inputType, setInputType] = useState("password");
  const [btnDisable, setBtnDisable] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errMsg1, setErrMsg1] = useState("");
  const [errMsg2, setErrMsg2] = useState("");
  const [errMsg3, setErrMsg3] = useState("");
  const [errMsg4, setErrMsg4] = useState("");
  const [errorBillMsg, setErrorBillMsg] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [shopifyOrder, setShopifyOrder] = useState(false);
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (storeId != undefined && storeId != null && storeId != "") {
      API.getStoreById(storeId)
        .then((result) => {
          if (result != undefined) {
            setStoreData(result);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error);
        });

    }
    API.isUserShopifyOrder(userInfo?.email,productId)
    .then((result) => {
      if (result != undefined) {
        setShopifyOrder(result);
      }
    })
    .catch((error) => {
      console.log(error);
      console.log(error);
    });

  }, [props]);

  /*const handleChange = (e) => {
    setErrMsg("");
    setBtnDisable(false);
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };*/

  const toggleInputType = (type) => setInputType(type);

  const [selectedBillImage, setSelectedBillImage] = React.useState("");
  const [ratingImage1, setRatingImage1] = useState("");
  const [ratingImageUrl1, setRatingImageUrl1] = React.useState("");
  const [ratingImage2, setRatingImage2] = useState("");
  const [ratingImageUrl2, setRatingImageUrl2] = React.useState("");
  const [ratingImage3, setRatingImage3] = useState("");
  const [ratingImageUrl3, setRatingImageUrl3] = React.useState("");
  const [ratingImage4, setRatingImage4] = useState("");
  const [ratingImageUrl4, setRatingImageUrl4] = React.useState("");

  /*const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setBtnDisable(true);
      await login(user.email, user.password);
      handleClose("login");
      setUser({
        email: "",
        password: "",
      });
    } catch (err) {
      setErrMsg(err.code.split("/")[1]);
    }
    setBtnDisable(false);
  };*/

  const handleSubmit = async (e) => {
    e.preventDefault();
    API.getUserById(userInfo.email).then((result) => {
      if (result !== undefined) {
        let name = result.name;
        if (rating == "" || rating == undefined) {
          e.preventDefault();
          e.stopPropagation();
          setErrMsg("Please select rating star");
          // e.stopNavigation();
          // return;
        } else if (!shopifyOrder && (selectedBillImage == "" || selectedBillImage == undefined)) {
          e.preventDefault();
          e.stopPropagation();
          setErrMsg("Please upload bill image");
          // e.stopNavigation();
          // return;
        } else if (rating != "" && (shopifyOrder || selectedBillImage != "")) {
          var images = [];
          var ratingImages = [];
          var billId = uuid();
          if (selectedBillImage != undefined && selectedBillImage != "")
            var imageExtension = selectedBillImage.name.substring(
              selectedBillImage.name.indexOf("."),
              selectedBillImage.name.length
            );
    
          images.push(
            API.bucketURL +
              "billsImage/" +
              storeData.code +
              "/" +
              billId +
              imageExtension
          );
    
          let billBody = {
            id: billId,
            image: images,
            storeId: storeId,
            storeName: storeData.code,
            companyId: storeData.companyId,
            status: shopifyOrder?"A":"N",
            isShopifyOrderReview:shopifyOrder?"Y":"N",
            userId: userInfo.email,
          };
    
          uploadBillImage(billId);
          API.addBill(userInfo.email, billBody);
    
          if (
            ratingImage1 != undefined &&
            ratingImage1 != "" &&
            ratingImage1 != null
          ) {
            var imageExtension = ratingImage1.name.substring(
              ratingImage1.name.indexOf("."),
              ratingImage1.name.length
            );
            var imageId = uuid();
            ratingImages.push(
              API.bucketURL +
                "RatingVideoImage/" +
                storeData.code +
                "/" +
                imageId +
                imageExtension
            );
            uploadRatingMedia(imageId, ratingImage1);
          }
    
          if (
            ratingImage2 != undefined &&
            ratingImage2 != "" &&
            ratingImage2 != null
          ) {
            var imageExtension = ratingImage2.name.substring(
              ratingImage2.name.indexOf("."),
              ratingImage2.name.length
            );
            var imageId = uuid();
            ratingImages.push(
              API.bucketURL +
                "RatingVideoImage/" +
                storeData.code +
                "/" +
                imageId +
                imageExtension
            );
            uploadRatingMedia(imageId, ratingImage2);
          }
          if (
            ratingImage3 != undefined &&
            ratingImage3 != "" &&
            ratingImage3 != null
          ) {
            var imageExtension = ratingImage3.name.substring(
              ratingImage3.name.indexOf("."),
              ratingImage3.name.length
            );
            var imageId = uuid();
            ratingImages.push(
              API.bucketURL +
                "RatingVideoImage/" +
                storeData.code +
                "/" +
                imageId +
                imageExtension
            );
            uploadRatingMedia(imageId, ratingImage3);
          }
          if (
            ratingImage4 != undefined &&
            ratingImage4 != "" &&
            ratingImage4 != null
          ) {
            var imageExtension = ratingImage4.name.substring(
              ratingImage4.name.indexOf("."),
              ratingImage4.name.length
            );
            var imageId = uuid();
            ratingImages.push(
              API.bucketURL +
                "RatingVideoImage/" +
                storeData.code +
                "/" +
                imageId +
                imageExtension
            );
            uploadRatingMedia(imageId, ratingImage4);
          }
          let reviewBody = {
            id: uuid(),
            billId: billId,
            rating: rating,
            comment: comment,
            offerId: offerId,
            productId: productId,
            storeId: storeId,
            storeName: storeData.code,
            companyId: storeData.companyId,
            status: shopifyOrder?"A":"N",
            ratingMedia: ratingImages,
            ratingType: type,
            isShopifyOrderReview:shopifyOrder?"Y":"N",
            userId: userInfo.email,
            userName: name,
          };
    
          API.addRating(userInfo.email, reviewBody);
          setIsSubmit(true);
        }
      }
    })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
  };

  const uploadLogo = (e) => {
    //form.companyLogo=e.target.files[0].name;
    //form.fileObject=Array.from(e.target.files)
    isVideo(e) ? setErrorBillMsg("Please upload only image") : setErrorBillMsg("");
    setSelectedBillImage(e.target.files[0]);
    console.log("setSelectedBillImage>>>>>>>>>>>>", selectedBillImage);
    setFileUrl(e.target.files[0].name);
    //setForm(form.companyLogo);
  };

  const isVideo = (e) => {
    const videos = ["mp4", "3gp", "ogg", "mov", "wmv", "flv", "avi", "mkv"];
    var extension = e.target.files[0].name.split(".")[1];
    if (videos.includes(extension)) {
      return true
    } else {
      return false
    }
  }

  const uploadRatingImage1 = (e) => {
    isVideo(e) ? setErrMsg1("Please upload only image") : setErrMsg1("");
    setRatingImage1(e.target.files[0]);
    setRatingImageUrl1(e.target.files[0].name);
  };
  const uploadRatingImage2 = (e) => {
    isVideo(e) ? setErrMsg2("Please upload only image") : setErrMsg2("");
    setRatingImage2(e.target.files[0]);
    setRatingImageUrl2(e.target.files[0].name);
  };
  const uploadRatingImage3 = (e) => {
    isVideo(e) ? setErrMsg3("Please upload only image") : setErrMsg3("");
    setRatingImage3(e.target.files[0]);
    setRatingImageUrl3(e.target.files[0].name);
  };
  const uploadRatingImage4 = (e) => {
    isVideo(e) ? setErrMsg4("Please upload only image") : setErrMsg4("");
    setRatingImage4(e.target.files[0]);
    setRatingImageUrl4(e.target.files[0].name);
  };

  const uploadRatingMedia = async (id, selectedMedia) => {
    const formData = new FormData();
    formData.append("file", selectedMedia);
    console.log("selectedMedia>>>>>>>>>>>>", selectedMedia);
    if (selectedMedia !== null && selectedMedia != "") {
      //store.dispatch('setOverlay', { showing: true })
      var imageName = selectedMedia.name.substring(
        0,
        selectedMedia.name.indexOf(".")
      );
      API.uploadRatingImage(formData, "companyName", storeData.code, id)
        .then((response) => {
          if (response.isAxiosError) {
            console.log("image response>>>>>>>>>", response);
            // store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { color: 'error', text: 'Something went wrong please try again' })
          } else {
            //store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { text: 'Company Logo Uploaded successfully' })
          }
        })
        .catch((response) => {
          // store.dispatch('setOverlay', { showing: false })
          // error callback
        });
    }
    // API.setUploadLogo(formData)
  };

  const uploadBillImage = async (billId) => {
    const formData = new FormData();
    formData.append("file", selectedBillImage);
    console.log("setSelectedBillImage111111>>>>>>>>>>>>", selectedBillImage);
    if (selectedBillImage !== null && selectedBillImage != "") {
      //store.dispatch('setOverlay', { showing: true })
      var imageName = selectedBillImage.name.substring(
        0,
        selectedBillImage.name.indexOf(".")
      );
      API.uploadBillImage(formData, "companyName", storeData.code, billId)
        .then((response) => {
          if (response.isAxiosError) {
            console.log("image response>>>>>>>>>", response);
            // store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { color: 'error', text: 'Something went wrong please try again' })
          } else {
            //store.dispatch('setOverlay', { showing: false })
            //store.dispatch('setSnackbar', { text: 'Company Logo Uploaded successfully' })
          }
        })
        .catch((response) => {
          // store.dispatch('setOverlay', { showing: false })
          // error callback
        });
    }
    // API.setUploadLogo(formData)
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => handleClose("login")}
      aria-labelledby="console.log-dialog-slide-title"
      aria-describedby="console.log-dialog-slide-description"
    >
      {!isSubmit ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Form onSubmit={handleSubmit} className="LoginForm">
            {errMsg && <Alert variant="danger">{errMsg}</Alert>}

            <Rating
              emptySymbol="fa fa-star-o fa-2x"
              fullSymbol="fa fa-star fa-2x"
              fractions={2}
              className="ratingForm"
              onChange={(rate) => setRating(rate.target.value)}
            />

            <Form.Group controlId="formBasicEmail">
              <Form.Label></Form.Label>
              <Form.Control
                name="comment"
                as="textarea"
                rows={3}
                value={comment}
                //onChange={handleChange}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Enter Comment"
              />
            </Form.Group>

            {!shopifyOrder ? (
              <React.Fragment>
                <Form.Label>Bill Image</Form.Label>
                <Form.File
                  id="custom-file"
                  label={fileUrl}
                  accept="image/*"
                  custom
                  name="companyLogo"
                  //onChange={setForm}
                  onChange={uploadLogo}
                />
                {errorBillMsg && <Alert variant="danger">{errorBillMsg}</Alert>}
              </React.Fragment>
            ) : (
              ""
            )}
            <Form.Label className="mt-2">Upload Image (Optional)</Form.Label>
            <Form.File
              id="custom-file"
              label={ratingImageUrl1}
              accept="image/*"
              custom
              className="mt-2"
              name="companyLogo"
              //onChange={setForm}
              onChange={uploadRatingImage1}
            />
            {errMsg1 && <Alert variant="danger">{errMsg1}</Alert>}
            <Form.File
              id="custom-file"
              label={ratingImageUrl2}
              accept="image/*"
              custom
              className="mt-2"
              name="companyLogo"
              //onChange={setForm}
              onChange={uploadRatingImage2}
            />
            {errMsg2 && <Alert variant="danger">{errMsg2}</Alert>}
            <Form.File
              id="custom-file"
              label={ratingImageUrl3}
              accept="image/*"
              custom
              className="mt-2"
              name="companyLogo"
              //onChange={setForm}
              onChange={uploadRatingImage3}
            />
            {errMsg3 && <Alert variant="danger">{errMsg3}</Alert>}
            <Form.File
              id="custom-file"
              label={ratingImageUrl4}
              accept="image/*"
              custom
              className="mt-2"
              name="companyLogo"
              //onChange={setForm}
              onChange={uploadRatingImage4}
            />
            {errMsg4 && <Alert variant="danger">{errMsg4}</Alert>}
            <Button
              disabled={btnDisable}
              type="submit"
              className="w-100 mt-4"
              style={{
                backgroundColor: COLORS.primary.main,
              }}
            >
              Submit
            </Button>
          </Form>
        </motion.div>
      ) : (
        <div className="mt-4 ml-2 mr-4">
          {shopifyOrder ? (
            <strong className="ml-4 mt-4">
              Your rating has been submitted.{" "}
            </strong>
          ) : (
            <strong className="ml-4 mt-4">
              Your rating has been submitted,after review by admin it will
              reflect.{" "}
            </strong>
          )}
          <div className="d-flex align-items-center justify-content-center">
            <Button
              disabled={btnDisable}
              type="button"
              onClick={(e) => handleClose("login")}
                className="w-30 ml-4 mt-4 mb-4"
                style={{
                  backgroundColor: COLORS.primary.main,
                  color:'white'
                }}
            >
              Okay
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
}
