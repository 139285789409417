import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  getActiveSectionItem,
  getAllStores,
  getStoresAllProduct,
  getStoresByCategory,
  getStoresProductByCategory,
  getSubCategory,
} from "../../../Redux/Stores/StoresActions";
import CircularProgress from '@material-ui/core/CircularProgress';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import API from "../../../Utils/API";
import { Button } from "@material-ui/core";
import { getUserSnips } from "../../../Redux/Snips/SnipsAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import environment from "../../../Utils/env";
import { COLORS } from "../../../Utils/context/ThemeContext";
import { getDomainCountryCode } from "../../../service/location";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function OffersHeader(props) {
  const { userInfo } = useAuth();
  const [value, setValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [recordPerPage, setRecordPerPage] = useState(32)
  const [toggle, setMoreToggle] = useState(false);
  const [categoryToggle, setCategoryMoreToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabToggle, setTabToggle] = useState("Accessories");
  const { storesCategories } = useSelector((state) => state.stores);
  const {
    marketPlace: selectedRefMarketPlace,
  } = useSelector((state) => state.location);
  const [allCategoriesArray, setAllCategoriesArray] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const setToggle = () => {
    setMoreToggle(!toggle);
  };

  const setCategoryToggle = () => {
    setCategoryMoreToggle(!categoryToggle);
  };

  const clickOnCategory = (catId) => {
    setMoreToggle(!toggle);
    history.push(`/product/` + catId, {previous:"offers"});
  };

  document.addEventListener("click", function (event) {
    var ignoreClickOnMeElement = document.getElementById("departmentId");
    //console.log("ignoreClickOnMeElement>>>>>>>>>>>>>",ignoreClickOnMeElement);
    //console.log("event>>>>>>>>>>>>>",event);
    if (ignoreClickOnMeElement != null) {
      var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
      if (!isClickInsideElement) {
        setMoreToggle(false);
        //Do something click is outside specified element
      }
    }
  });

  document.addEventListener("click", function (event) {
    var ignoreClickOnMeElement = document.getElementById("moreMenu");
    //console.log("ignoreClickOnMeElement>>>>>>>>>>>>>",ignoreClickOnMeElement);
    //console.log("event>>>>>>>>>>>>>",event);
    if (ignoreClickOnMeElement != null) {
      var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
      if (!isClickInsideElement) {
        setCategoryMoreToggle(false);
        //Do something click is outside specified element
      }
    }
  });

 const setMenu = (value, catId) => {
    setTabToggle(value);
    setLoading(true);
    API.subCategoriesListByName(
      userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
      value
    )
      .then((result) => {
        if (result !== undefined) {
          setSubCategories(result);
          setLoading(false);
        } else {
          setSubCategories([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
  };

  let allCategories = [];
  /*const allCategoriesArray = ["All", "New"].concat(
  storesCategories.map((category) => category.categoryName)
);*/
  const { location, userId, cityId, cityName, areaId, stateId } = useSelector(
    (state) => state.location
  );
  const { subCategory } = useSelector((state) => state.stores);

  const history = useHistory();
  const { path, params } = useRouteMatch();
  const currPath = path.split(":")[0];
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [categoryIdList, setCategoryIdList] = useState([])
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserSnips(userInfo?.email, "like"));
    API.getMainCategories(
      userInfo != null && userInfo != undefined ? userInfo.email : "gust"
    )
      .then((result) => {
        console.log("result.data>>>>>>>>>>>>>>>>>>", result);
        if (result != undefined && result.length > 0) {
          //console.log('data=='+result.data);
          setMainCategoryList(result);
          setCategoryIdList(["Trending"].concat(
            result.map((category) => category.id)
          ));
          const selectedCategory = result.find(item => params.tabName === item.id || params.tabName === item.name)
          allCategories = ["Trending"].concat(
            result.map((category) => category.name)
          );
          allCategories.map((items, index) => {
            if(params.tabName !== "Trending" && items === params.tabName && index >= 6 ) {
              allCategories.splice(6, 0, params.tabName);
              allCategories.splice(index+1, 1);
            }
          })
          setAllCategoriesArray(allCategories);
          setValue(allCategories.indexOf(selectedCategory.name || "Trending"));
          console.log("allCategoriesArray>>>>>>>>>>>>>>>>>>", allCategories);
        }
      })
      .catch((error) => {
        console.log(error);
        // console.log(error);
      });

    /* API.subCategoriesListByName(userInfo.email,"all").then((result) => {  
        console.log("result.data>>>>>>>>>>>>>>>>>>",result);
          if(result!=undefined)
          {
              //console.log('data=='+result.data);
              setSubCategories(result)
             // setLoading(false);
  
            
            
          }
          else
          {
          setSubCategories([])
         // setLoading(false);
          }
  
              })
              .catch((error) => {
                console.log(error);
                console.log(error);
              });*/
    /*setLoading(true);
    API.subCategoriesListByName(userInfo != null && userInfo != undefined ? userInfo.email : "gust","Automotive").then((result) => {  
      console.log("result.data>>>>>>>>>>>>>>>>>>",result);
        if(result!=undefined)
        {
            //console.log('data=='+result.data);
            setSubCategories(result)
            setLoading(false);

          
          
        }
        else
        {
        setSubCategories([])
        setLoading(false);
        }

            })
            .catch((error) => {
              console.log(error);
              console.log(error);
            });*/
  }, [params]);

  const handleChange = (event, newValue) => {
    const tabName = event.target.innerText;
    setValue(newValue);
    if (tabName !== "TRENDING" && tabName !== "NEW") {
      dispatch(
        getStoresByCategory(
          userInfo?.email || userId,
          mainCategoryList[newValue - 1].id,
          cityId,
          stateId,
          "offer",
          selectedRefMarketPlace
        )
      );
      dispatch(
        getStoresProductByCategory({
          user: API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
          categoryId: mainCategoryList[newValue - 1].id,
          cityId,
          stateId,
          viewType: "offer",
          marketPlace: selectedRefMarketPlace,
          pageNo: 0,
          recordPerPage,
          orderBy: "createdOn",
          asc:false
        })
      );
      dispatch(
        getActiveSectionItem(
          userInfo?.email || userId,
          mainCategoryList[newValue - 1].id,
          cityId,
          stateId,
          "Offer",
          selectedRefMarketPlace
        )
      );
    } else {
      dispatch(
        getAllStores(userInfo?.email || userId, cityId, stateId, "offer",selectedRefMarketPlace)
      );
      console.log({
        getAllStores:{
        user: API.encodedUserId(userInfo?.email || userId),
        cityId,
        stateId,
        viewType: "offer",
        marketPlace: selectedRefMarketPlace
          ? selectedRefMarketPlace
          : window.location.hostname.includes(environment.CADANA_DOMAIN)
          ? "Canada"
          : "United States",
        pageNo: 0,
        recordPerPage,
        // TODO: ORDERBY, ASC
      }
      })
      const searchQuery = API.generateQueryString({
        user: API.encodedUserId(userInfo?.email || userId),
        cityId,
        stateId,
        viewType: "offer",
        marketPlace: selectedRefMarketPlace
          ? selectedRefMarketPlace
          : window.location.hostname.includes(environment.CADANA_DOMAIN)
          ? "Canada"
          : "United States",
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc: getDomainCountryCode() === "CA" ? true : false
      });
      dispatch(
        getStoresAllProduct(searchQuery)
      );
      dispatch(
        getActiveSectionItem(
          userInfo?.email || userId,
          "Trending",
          cityId,
          stateId,
          "Offer",
          selectedRefMarketPlace
        )
      );
    }
  };

  const handleMenuChange = (newValue,index) => {
    //const tabName = event.target.innerText;
    history.push(`${currPath}${categoryIdList[index]}`);
    setValue(newValue);
    var CatId = "";
    mainCategoryList.map((item, index) => {
      if (item.name == newValue) CatId = item.id;
    });

    dispatch(
      getStoresByCategory(
        userInfo?.email || userId,
        CatId,
        cityId,
        stateId,
        "offer"
      )
    );
    dispatch(
      getStoresProductByCategory({
        user:API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
        categoryId: CatId,
        cityId,
        stateId,
        viewType: "offer",
        marketPlace: selectedRefMarketPlace,
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc:false
      })
    );
    dispatch(
      getActiveSectionItem(
        userInfo?.email || userId,
        CatId,
        cityId,
        stateId,
        "Offer",
        selectedRefMarketPlace
      )
    );
  };
 const additionalStyle = props.noHeader
    ? {
      paddingLeft: "4%",
        width: "fit-content",
        left: "10px",
      }
    : {};
  return (
    <div className="containerHeader" style={additionalStyle}>
      <Button
        id="departmentId"
        onClick={() => {
          setToggle();
        }}
        className="department-button"
        variant="contained"
        color="primary"
        // className={classes.button}
      >
        Department
        {toggle ? (
          <FontAwesomeIcon
            icon={faAngleUp}
            style={{ paddingLeft: "10px", height: "20px" }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faAngleDown}
            style={{ paddingLeft: "10px", height: "20px" }}
          />
        )}
      </Button>
      <div className="stickyOfferHeader" style={{ top: 54 }}>
        <div
          className={
            toggle ? "dowpdown-wrapper d-block" : "d-none dowpdown-wrapper"
          }
        >
          <div class="categories-inner">
            <ul className="dept-list-container" style={{ zIndex: 50 }}>
              {mainCategoryList.map((item, index) => (
                <li>
                  <a
                    href="#"
                    onMouseOver={() => {
                      setMenu(item.name, item.id);
                    }}
                    id={item.id}
                    className={tabToggle == item.name ? "active" : ""}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
            <div
              className="dept-category-container container"
              style={{ marginLeft: 0, zIndex: 45 }}
            >
              <a className="flyout-all-dept-link">All {tabToggle}</a>
              {!loading ? (
                <div className="row">
                  {subCategory &&
                    subCategory[tabToggle]?.map(
                      (subItem) =>
                        subCategory[subItem.id]?.length > 0 && (
                          <div className="col-md-4 pr-5">
                            <li className="category-column-section">
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  clickOnCategory(subItem.id);
                                }}
                                className="category-col-header-link"
                              >
                                {subItem.name}
                              </a>

                              <ul className="category-section-items-list">
                                {subCategory[subItem.id] !== undefined
                                  ? subCategory[subItem.id].map(
                                      (subCat, index1) => (
                                        <li>
                                          <a
                                            onClick={() => {
                                              clickOnCategory(subCat.id);
                                            }}
                                            href="javascript:void(0)"
                                          >
                                            {subCat.name}
                                          </a>
                                        </li>
                                      )
                                    )
                                  : ""}
                              </ul>
                            </li>
                          </div>
                        )
                    )}
                </div>
              ) : (
                <div
                  style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <CircularProgress
                    color="primary"
                    style={{ color: COLORS.primary.main }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center menu-tab">
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
          className="headerTabs"
        >
          {allCategoriesArray.map((item, index) =>
            index <= 6 ? (
              <Tab
                {...a11yProps(index)}
                label={item}
                key={item}
                onClick={() => history.push(`${currPath}${categoryIdList[index]}`)}
                style={{ minWidth: "120px", zIndex: 50 }}
              />
            ) : (
              ""
            )
          )}
        </Tabs>
        <div className="position-relative">
          <div
            className="more "
            id="moreMenu"
            onClick={() => {
              setCategoryToggle();
            }}
          >
            More{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>

          <div
            className={
              categoryToggle ? "more-dropdown d-block shadow" : "d-none"
            }
          >
            <div className="row">
              {allCategoriesArray &&
                allCategoriesArray.map((item, index) =>
                  index > 6 ? (
                    <div
                      className="col-md-4 tab-text"
                      onClick={() => handleMenuChange(item,index)}
                      href="javascript:void(0)"
                    >
                      <ListItemText primary={item} />
                    </div>
                  ) : (
                    ""
                  )
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OffersHeader;
