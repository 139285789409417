import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Form, Button, Alert } from "react-bootstrap";
import { useAuth } from "../../Utils/AuthContext";
import API from "../../Utils/API";
import { COLORS } from "../../Utils/context/ThemeContext";
import { ArrowBack } from "@material-ui/icons";

export default function UnRegisterUser(props) {
  const { handleClose, handleOpen, open } = props;
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [btnDisable, setBtnDisable] = useState("");

  const handleChange = (e) => {
    setErrMsg("");
    setSuccessMsg("");
    setEmail(e.target.value);
    setBtnDisable(false);
  };
  const { unRegisterUser } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setBtnDisable(true);
      API.sendUserDeleteMail(email).then(response =>{
           console.log("Unregister User :::",response);
          /* if(response == "successfully"){
            setSuccessMsg("User Deleted Successfully");
            setEmail("");
        } else if (response == "User can not delete") {
            setSuccessMsg("User can not be delete");
            setEmail("");
        } else {
            setSuccessMsg("User not exist");
            setEmail("");
        }*/
        setSuccessMsg("Please check your email Inbox/Spam to Unregister");
            setEmail("");
      });
    } catch (err) {
        console.log("Error in Api calling",err);
      setErrMsg(err);
    }
   // setBtnDisable(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => handleClose("unRegisterUser")}
      aria-labelledby="console.log-dialog-slide-title"
      aria-describedby="console.log-dialog-slide-description"
    >
      <Form className="ForgotPasswordForm" onSubmit={handleSubmit}>
        <ArrowBack
          titleAccess="Back to Login"
          style={{
            color: COLORS.primary.main,
            marginBottom:'10px'
          }}
          onClick={() => {
            handleOpen("login");
            handleClose("unRegisterUser");
          }}
        />
        {errMsg && <Alert variant="danger">{errMsg}</Alert>}
        {successMsg && <Alert variant="success">{successMsg}</Alert>}
        <Form.Group>
          <Form.Label className="text-center">
            Please enter your registered email address and we will unregister
            the user.
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button
          disabled={btnDisable}
          // variant={btnDisable?"secondary":"primary"}
          type="submit"
          className="w-100"
          style={{
            backgroundColor: COLORS.primary.main,
          }}
        >
          Submit
        </Button>
        <div className="otherFormOptions">
          <p
            className="loginFormLink"
            onClick={() => {
              handleOpen("register");
              handleClose("unRegisterUser");
            }}
          >
            New User ? Sign Up here !
          </p>
        </div>
      </Form>
    </Dialog>
  );
}
