import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import { getUserSnips, removeSnipsData } from "../../Redux/Snips/SnipsAction";
import API from "../../Utils/API";
import { useAuth } from "../../Utils/AuthContext";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function FAQ() {
  const classes = useStyles();

  return (
    <>
      <div className="container-fluid my-5">
        <div className="row">
          <div className="col-md-12">
            <h3>FAQs</h3>
            <div className={classes.root}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    How ShopiAds works
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    ShopiAds is an Advertising platform where stores promote
                    their offers and catalog. Once buyer decides to purchase
                    they are redirected to store web-portal for checkout or can
                    purchase in-store
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Which types of Stores and sellers are onboarded
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Be confident to Shop from ShopiAds as we onboard only
                    incorporated sellers to avoid any fraudsters. If you find
                    any unregistered sellers please report to us.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Who is responsible for charging and returning Payment
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    As the product is purchased from store web-portal and
                    in-store they are solely responsible for charging and
                    returning payment. Please check their payment policy for
                    charges and return
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Who is responsible for Shipping
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Store provides Shipping and tracking details on your email
                    address or number. Please check their policy for shipping
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Who is responsible for Returns
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Store provides returns. Please check their policy for return
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    User Deletion
                  </Typography>
                </AccordionSummary>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    In order to delete user account please follow given step...
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    1) Click on Unregister user on login screen on web or app
                  </Typography>
                </AccordionDetails>
                <AccordionDetails>
                  <Typography>
                    2) Input your email id which will send email to confirm if
                    you want to delete user account
                  </Typography>
                </AccordionDetails>
                <AccordionDetails>
                  <Typography>
                    3) On confirmation your account and all its associated data
                    will be deleted immediately except orders which will be
                    retained for a year in case store request the transaction
                    for verification or reporting purpose
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Credentials privacy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    User credentials are managed by google firebase and we do
                    not keep them in our system. In case you lose the password
                    you can reset it.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Ratings and Reviews
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Ratings and reviews are provided by verified buyers who had
                    purchased through ShopiAds!
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Content Discrepancy
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    In case of discrepancy between ShopiAds and Store web portal
                    or in-store, Store takes the precedence
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    What is STORE and OFFER Mode
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      On ShopiAds you can find two different modes that is STORE
                      and OFFER .{" "}
                    </p>
                    <p>
                      In Store mode you will be able to do store-wise shopping
                      and find stores from respective categories along with
                      their catalog.
                    </p>
                    <p>Store mode is divide into following two sections.</p>
                    <p>
                      Nearby section shows stores from the city you set in the
                      location.
                    </p>
                    <p>
                      Country-wide section will show stores from all over
                      country excluding the city you set in Location.
                    </p>
                    <p>
                      In Offer mode you will be able to find offers and products
                      from different stores which is organized into relevant
                      category, department and occasion section.
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Notification
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    When you like to get new offers about a specific store you
                    can follow it Which will send notification whenever a new
                    offer or product is added
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Location</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    ShopiAds is an omni channel platform allowing to shop
                    in-store and online If you wish to shop in-store simply set
                    the location of desired city and find all stores displayed
                    in the Nearby section under store mode. Otherwise whole
                    application considers canada-wide stores for online shopping
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    Contact us
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    In case of any fraudulent activity. Please connect with us
                    with details of your purchase using contact us page
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQ;
