export const LocationActionTypes = {
  MAKE_LOCATION_REQUEST: "MAKE_LOCATION_REQUEST",
  SET_LOCATION: "SET_LOCATION",
  SET_CITIES: "SET_CITIES",
  SET_CITYID: "SET_CITYID",
  SET_CITYNAME: "SET_CITYNAME",
  SET_STATEID: "SET_STATEID",
  SET_AREAS: "SET_AREAS",
  SET_AREAID: "SET_AREAID",
  ERROR: "ERROR",
  SET_MARKET_PLACE: "SET_MARKET_PLACE",
  SET_DAFAULT_COUNTRY: "SET_DAFAULT_COUNTRY",
};
