import { Select, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAreas,
  getCities,
  setCityId,
  setCityName,
  setLocation,
  setStateId,
  setDefaultCountryToLocalStorage,
  setMatketPlaceToLocalStorage,
} from "../../Redux/Location/LocationActions";
import API from "../../Utils/API";
import {
  getDomainCountryCode,
  MARKET_PLACES,
} from "../../service/location";
import environment from "../../Utils/env";
import CustomerSelectOption from "../UI/Input/CustomerSelectOption";
import CustomSelect from "../UI/Container/CustomSelect";
export const locationRequiredURLs = [
  "privacyPolicy",
  "shopPrivacyPolicy",
  "myVideo",
  "termsOfuse",
  "about",
  "faq",
  "advertiserAgreement",
  "mobileHome",
  "user-deletion",
];

export default function LocationForm(props) {
  const {
    cities,
    location,
    userId,
    stateId,
    defaultCountry,
    marketPlace,
  } = useSelector((state) => state.location);

  const { handleClose, open } = props;
  const [selectedCityOption, setSelectedCityOption] = useState([]);
  const [stateData, setstateData] = useState([]);
  const [selectedStateOption, setSelectedStateOption] = useState([]);

  const [selectedDefaultCountry, setSelectedDefaultCountry] = useState(
    defaultCountry || getDomainCountryCode()
  );
  const dispatch = useDispatch();
  const citiesOptions = cities.map((item) => item.cityName);
  const stateOptions = stateData.map((item) => item.name);
  useEffect(() => {
    API.getAllState("guest")
      .then((result) => {
        if (result != undefined && result.length > 0) {
          setstateData(result);
        }
      })
      .catch((error) => {
      });
    dispatch(getCities(userId, stateId));
  }, [dispatch, userId, stateId]);

  const selectedCity = async (e) => {
    setSelectedCityOption(e);
    const cityObj = await cities.filter((item) => item.cityName === e[0]);
    dispatch(setCityId(cityObj[0]?.cityId));
    dispatch(setCityName(e[0]));
    dispatch(getAreas(userId, cityObj[0]?.cityId));
  };

  const selectedState = async (e) => {
    setSelectedStateOption(e);
    const stateObj = await stateData.filter((item) => item.name === e[0]);
    setSelectedCityOption([])
    dispatch(setStateId(stateObj[0]?.id));
    dispatch(getCities(userId, stateObj[0]?.id));
  };

  const saveLocation = () => {
    dispatch(setLocation(selectedStateOption[0], selectedCityOption[0]));
    if (!defaultCountry) {      
      dispatch(setDefaultCountryToLocalStorage(selectedDefaultCountry));
    }
    if (!marketPlace){ 
      dispatch(setMatketPlaceToLocalStorage(MARKET_PLACES.find(item => item.name === selectedDefaultCountry[0])?.id));
    };
     handleClose("location");
    setTimeout(function () {
      window.location.reload(false);
    }, 1000);
  };
  return (
    <Dialog
      open={
        open &&
        !locationRequiredURLs.find(
          (blockPage) =>
            window.location.pathname?.startsWith(`/${blockPage}`) &&
            window.innerWidth > 768
        )
      }
      keepMounted
      //onClose={() => handleClose("location")}
      aria-labelledby="console.log-dialog-slide-title"
      aria-describedby="console.log-dialog-slide-description"
      style={{ paddingTop: 0, paddingRight: 0 }}
    >
      {location && (
        <div>
          <IconButton style={{ position: "absolute", right: 0 }}>
            <Close onClick={() => handleClose("location")} />
          </IconButton>
        </div>
      )}
      <DialogTitle>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            position: "relative",
          }}
        >
          <b style={{ textAlign: "center", flex: 1 }}>Setting Location</b>
        </div>
      </DialogTitle>
      <Form className="LocationForm" style={{ padding: "0px" }}>
        {(defaultCountry === "" ||
          !defaultCountry ||
          defaultCountry === getDomainCountryCode()) && (
          <>
            <strong style={{ textAlign: "center", paddingBottom: "12px" }}>
              Select your default country
            </strong>
            <div
              style={{
                // alignSelf: "stretch",
                padding: "0px 24px 12px 34px",
              }}
            >
              <CustomSelect
                className="form-control"
                onChange={(e) => {
                  setSelectedDefaultCountry(e.target.value);
                  dispatch(setDefaultCountryToLocalStorage(e.target.value));
                  dispatch(setMatketPlaceToLocalStorage(e.target.value));
                  if (e.target.value !== getDomainCountryCode()) {
                    const navigateURL = `${
                      e.target.value === "CA"
                        ? environment.CANADA_BASE_URL
                        : environment.USA_BASE_URL
                    }?${API.generateQueryString({
                      defaultCountry: e.target.value,
                      marketPlace: MARKET_PLACES.find(
                        (item) => item.id === e.target.value
                      ).name,
                    })}`;
                    window.location.replace(navigateURL);
                  }
                }}
                value={selectedDefaultCountry}
              >
                {MARKET_PLACES.map((item) => (
                  <CustomerSelectOption
                    value={item.id}
                    selected={selectedDefaultCountry === item.id}
                  >
                    {item.name}
                  </CustomerSelectOption>
                ))}
              </CustomSelect>
            </div>
          </>
        )}
        {location && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ textAlign: "center" }}>
              In addition to country you get city specific view of stores :
            </Typography>
            <strong>{location}</strong>
          </div>
        )}
        {!location && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ textAlign: "center" }}>
              In addition to country you get city specific view of stores :
            </Typography>
            <strong>Select State/Province, Selet City</strong>
          </div>
        )}
        {/* <Button
          style={{ margin: "20px 0", textTransform: "none" }}
          variant="contained"
          startIcon={<SendRoundedIcon />}
        >
          Detect My Location
        </Button>
        <p>Or</p> */}
        <div className="mt-3" style={{ display: "flex" }}>
          <input
            type="text"
            style={{
              width: 0,
              height: 0,
              visibility: "hidden",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Typeahead
              id="valid-styles-example"
              autoComplete={"none"}
              // onChange={setSelectedCityOption}
              onChange={(e) => selectedState(e)}
              options={stateOptions}
              inputProps={{ autoComplete: "none", style: { margin: "0 10px" } }}
              selected={selectedStateOption}
              placeholder="Search State/Province"
            />
            <Typeahead
              id="valid-styles-example1"
              //autoComplete="new-password"
              // onChange={setSelectedCityOption}
              autoComplete={"none"}
              inputProps={{ autoComplete: "none" }}
              onChange={(e) => selectedCity(e)}
              options={citiesOptions}
              selected={selectedCityOption}
              placeholder="Search City"
            />
          </div>
          <input type="hidden" value="something" />
          {/* <Typeahead
            id="valid-styles-example"
            // onChange={setSelectedAreaOption}
            onChange={(e) => selectedArea(e)}
            options={areasOptions}
            selected={selectedAreaOption}
            placeholder="Search Area"
         />*/}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Button
            style={{
              margin: "20px 0",
              textTransform: "none",
              color: "white",
            }}
            variant="contained"
            disabled={
              selectedStateOption[0] == undefined ||
              selectedCityOption[0] == undefined
                ? true
                : false
            }
            color="primary"
            onClick={saveLocation}
          >
            CONTINUE
          </Button>
        </div>
      </Form>
    </Dialog>
  );
}
