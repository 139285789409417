import React, { useState } from "react";
import "./Form.css";
import Dialog from "@material-ui/core/Dialog";
import { useAuth } from "../../Utils/AuthContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Alert } from "react-bootstrap";
import { motion } from "framer-motion";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import API from "../../Utils/API";
import RegisterForm from "../DialogBox/RegisterForm";
import ForgotPasswordForm from "../DialogBox/ForgotPasswordForm";
import UnRegisterUser from "../DialogBox/UnRegisterUser";
import LocationForm from "../DialogBox/LocationForm";
import { COLORS } from "../../Utils/context/ThemeContext";


export default function LoginForm(props) {
  const { login, loginBygmail,loginByFb,loginByApple } = useAuth();
  const { handleClose, handleOpen, open } = props;
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [inputType, setInputType] = useState("password");
  const [btnDisable, setBtnDisable] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [openForm, setOpenForm] = useState({
    login: false,
    register: false,
    forgotPassword: false,
    location: false,
    unRegisterUser: false
  });

  const handleChange = (e) => {
    setErrMsg("");
    setBtnDisable(false);
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleInputType = (type) => setInputType(type);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setBtnDisable(true);
      const res=await login(user.email, user.password);
      console.log("login responase>>>>>>>>>>>>>>>>>>>>>",res);
      handleClose("login");
      setUser({
        email: "",
        password: "",
      });
      let userBody = {
        email: user.email,
        emailOk: "Y",
        emailSubcription: "Y",
        name: res.user.displayName,
        mob: "",
        mobOk: "Y",
        authType: "AU",
        password: "",
        country: "",
        companyId: "",
        roleId: ""//Store Admin role id

      };
      API.getUserById(user.email).then((result) => {

        console.log("user data>>>>>>>>>>>>>>", result)
        if (result == undefined || result == null || result == "") {
          API.createUser(res.user.email,userBody)
          API.createUserUS(res.user.email,userBody)
        }
        else
         API.updateUserSignIn(user.email);

         window.location.reload();
        
      })
      .catch((error) => {
         API.createUser(res.user.email,userBody)
         API.createUserUS(res.user.email,userBody)
         window.location.reload();
        console.log(error);
        console.log(error);
      });
      // await API.updateUserSignIn(user.email);
      // window.location.reload();
    } catch (err) {
      setErrMsg(err.code.split("/")[1]);
    }
    setBtnDisable(false);
    
  };

  // Sign in with google
  const signinByGoogle = async (e) => {
    e.preventDefault();
    try {
      setBtnDisable(true);
      const res = await loginBygmail();
      console.log("google response>>>>>>>>>>", res);
      handleClose("login");
      setUser({
        email: "",
        password: "",
      });
      let userBody = {
        email: res.user.email,
        emailOk: "Y",
        emailSubcription: "Y",
        name: res.user.displayName,
        mob: "",
        mobOk: "Y",
        authType: "S",
        password: "",
        country: "",
        companyId: "",
        roleId: ""//Store Admin role id

      };
      API.getUserById(res.user.email).then((result) => {

        console.log("user data>>>>>>>>>>>>>>", result)
        if (result == undefined || result == null || result == "") {
          API.createUser(res.user.email,userBody)
          API.createUserUS(res.user.email,userBody)
        }
        else
         API.updateUserSignIn(user.email);

         window.location.reload();
        
      })
      .catch((error) => {
        API.createUser(res.user.email,userBody)
        API.createUserUS(res.user.email,userBody)
        console.log(error);
        console.log(error);
        window.location.reload();
      });

    } catch (err) {
      // setErrMsg(err.code.split("/")[1]);
    }
    setBtnDisable(false);
  };

  // Sign in with facebook
  const signinByFacebook = async (e) => {
    e.preventDefault();
    try {
      setBtnDisable(true);
      const res = await loginByFb();
      console.log("Facebook response>>>>>>>>>>", res);
      handleClose("login");
      setUser({
        email: "",
        password: "",
      });
      if(res!=undefined)
      {
      let userBody = {
        email: res.user.email,
        emailOk: "Y",
        emailSubcription: "Y",
        name: res.user.displayName,
        mob: "",
        mobOk: "Y",
        authType: "S",
        password: "",
        country: "",
        companyId: "",
        roleId: ""//Store Admin role id

      };
      API.getUserById(res.user.email).then((result) => {

        console.log("user data>>>>>>>>>>>>>>", result)
        if (result == undefined || result == null || result == "") {
          API.createUser(res.user.email,userBody)
          API.createUserUS(res.user.email,userBody)
        }
        else
         API.updateUserSignIn(user.email);

         window.location.reload();
        
      })
      .catch((error) => {
        API.createUser(res.user.email,userBody)
        API.createUserUS(res.user.email,userBody)
        console.log(error);
        console.log(error);
        window.location.reload();
      });
     
    }
    } catch (error) {
      console.log("fb err>>>>>>>>>>>>>>>>>>",error);
     // const errorMessage = error.message;
     // alert(errorMessage);
     // if(err!=undefined)
     // setErrMsg(errorMessage);
    }
    setBtnDisable(false);
  };


  // Sign in with google
  const signinByApple = async (e) => {
    e.preventDefault();
    try {
      setBtnDisable(true);
      const res = await loginByApple();
      console.log("apple response>>>>>>>>>>", res);
      handleClose("login");
      setUser({
        email: "",
        password: "",
      });
      if(res!=undefined)
      {
      let userBody = {
        email: res.user.email,
        emailOk: "Y",
        emailSubcription: "Y",
        name: res.user.displayName!=null&&res.user.displayName!=undefined?res.user.displayName:res.user.email,
        mob: "",
        mobOk: "Y",
        authType: "S",
        password: "",
        country: "",
        companyId: "",
        roleId: ""//Store Admin role id

      };
      API.getUserById(res.user.email).then((result) => {

        console.log("user data>>>>>>>>>>>>>>", result)
        if (result == undefined || result == null || result == "") {
          API.createUser(res.user.email,userBody)
          API.createUserUS(res.user.email,userBody)
        }
        else
         API.updateUserSignIn(user.email);

         window.location.reload();
        
      })
      .catch((error) => {
        API.createUser(res.user.email,userBody)
        API.createUserUS(res.user.email,userBody)
        console.log(error);
        console.log(error);
        window.location.reload();
      });
     
    }
    } catch (error) {
      console.log("fb err>>>>>>>>>>>>>>>>>>",error);
     // const errorMessage = error.message;
     // alert(errorMessage);
     // if(err!=undefined)
     // setErrMsg(errorMessage);
    }
    setBtnDisable(false);
  };

  const handleOpenForm = (form) => {
    setOpenForm((prevState) => ({ ...prevState, [form]: true }));
    if(form=="login")
    handleOpen("login");
  };

  const handleCloseForm = (form) => {
    setOpenForm((prevState) => ({ ...prevState, [form]: false }));
    if(form=="register")
    handleClose("login");
    if(form=="register1")
    setOpenForm((prevState) => ({ ...prevState, ["register"]: false }));
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={() => handleClose("login")}
        aria-labelledby="console.log-dialog-slide-title"
        aria-describedby="console.log-dialog-slide-description"
      >
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Form onSubmit={handleSubmit} className="LoginForm">
            {errMsg && <Alert variant="danger">{errMsg}</Alert>}
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                name="email"
                type="email"
                value={user.email}
                onChange={handleChange}
                placeholder="Enter email"
                required
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <div className="loginPasswordInput">
                <Form.Control
                  className="passwordField"
                  name="password"
                  type={inputType}
                  value={user.password}
                  onChange={handleChange}
                  placeholder="Password"
                  required
                />
                {inputType === "password" ? (
                  <i
                    className="fa fa-eye-slash"
                    aria-hidden="true"
                    onClick={() => toggleInputType("text")}
                  ></i>
                ) : (
                  <i
                    className="fa fa-eye"
                    aria-hidden="true"
                    onClick={() => toggleInputType("password")}
                  ></i>
                )}
              </div>
              <p
                className="small float-right forgotPasswordLink"
                onClick={() => {
                  handleOpenForm("forgotPassword");
                  //handleClose("login");
                }}
              >
                Forgot Password ?
              </p>
            </Form.Group>

            <Button
              disabled={btnDisable}
              type="submit"
              className="w-100"
              style={{
                backgroundColor: COLORS.primary.main,
                color:'white'
              }}
            >
              Login
            </Button>
            <div className="otherFormOptions">
            <span> --- Or Login with --- </span>

            </div>
            <div className="otherFormOptions">
              <div className="socialLoginBtns">
                {/* <FacebookLogin
                appId="571655573622734"
                textButton="Facebook"
                size="small"
                fields="name,email,picture"
                // callback={responseFacebook}
                icon="fa-facebook"
              /> */}
                {/* <GoogleLogin
                className="googleBtn"
                clientId="15194957769-p5gs59bq6cppljkrgd02hse3p9v9f1qo.apps.googleusercontent.com"
                buttonText="Google"
                // onSuccess={responseGoogle}
                // onFailure={responseGoogle}
                onClick={() => signinByGoogle}
                cookiePolicy={"single_host_origin"}
              /> */}

                {/* <Button
                variant="outlined"
                color="primary"
                appId="571655573622734"
                className="login-facebook d-flex"
                startIcon={<i className="fa fa-scissors" aria-hidden="true"></i>}
                // style={{ width: "250px" }}
                onClick={signinByFacebook}
                fields="name,email,picture"
              >
                <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z" /></svg>
                Facebook
              </Button> */}

                <Button
                  variant="outlined"
                  color="primary"
                  className="login-google d-flex mx-2"
                  startIcon={
                    <i className="fa fa-scissors" aria-hidden="true"></i>
                  }
                  style={{ borderWidth: "1px", borderColor: "#1d6042" }}
                  onClick={signinByGoogle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="20px"
                    height="20px"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    />
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    />
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    />
                  </svg>
                  Google
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  appId="571655573622734"
                  className="login-google d-flex"
                  startIcon={
                    <i className="fa fa-scissors" aria-hidden="true"></i>
                  }
                  style={{ borderWidth: "1px", borderColor: "#1d6042" }}
                  // style={{ width: "250px" }}
                  onClick={signinByApple}
                  fields="name,email,picture"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 496.255 608.728"
                  >
                    <path
                      d="M273.81 52.973C313.806.257 369.41 0 369.41 0s8.271 49.562-31.463 97.306c-42.426 50.98-90.649 42.638-90.649 42.638s-9.055-40.094 26.512-86.971zM252.385 174.662c20.576 0 58.764-28.284 108.471-28.284 85.562 0 119.222 60.883 119.222 60.883s-65.833 33.659-65.833 115.331c0 92.133 82.01 123.885 82.01 123.885s-57.328 161.357-134.762 161.357c-35.565 0-63.215-23.967-100.688-23.967-38.188 0-76.084 24.861-100.766 24.861C89.33 608.73 0 455.666 0 332.628c0-121.052 75.612-184.554 146.533-184.554 46.105 0 81.883 26.588 105.852 26.588z"
                      fill="#999"
                    />
                  </svg>
                  Apple
                </Button>
              </div>
              <p
                className="registerFormLink"
                onClick={() => {
                  handleOpenForm("register");
                  // handleClose("login");
                }}
              >
                New User ? Sign Up here !
              </p>
              <p
                className="medium float-right forgotPasswordLink"
                onClick={() => {
                  handleOpenForm("unRegisterUser");
                  // handleClose("login");
                }}
              >
                UnRegister User
              </p>
            </div>
          </Form>
        </motion.div>
      </Dialog>
      <RegisterForm
        handleOpen={handleOpenForm}
        handleClose={handleCloseForm}
        open={openForm.register}
      />
      <ForgotPasswordForm
        handleOpen={handleOpenForm}
        handleClose={handleCloseForm}
        open={openForm.forgotPassword}
      />
      <UnRegisterUser
        handleOpen={handleOpenForm}
        handleClose={handleCloseForm}
        open={openForm.unRegisterUser}
      />
    </>
  );
}
