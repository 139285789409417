import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "react-bootstrap";
import Rating from "@material-ui/lab/Rating";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Dummy1 from "../../../Assets/Dummy 1.jpg";
import Dummy2 from "../../../Assets/Dummy 2.jpg";
import Dummy3 from "../../../Assets/Dummy 3.jpg";
import SampleBanner from "../../../Assets/Sample Banner.png";
import Image1 from "../../../Assets/Img1.jpg";
import Table from "../../../Assets/Table.png";
import Children from "../../../Assets/Children.png";
import Casualshirts from "../../../Assets/Casualshirts.png";
import Footwear from "../../../Assets/Footwear.png";
import Sweatshirts from "../../../Assets/Sweatshirts.png";
import Shorts from "../../../Assets/Shorts.png";
import { Grid, ListItemText, makeStyles, Tab, Tabs } from "@material-ui/core";
import Card from "react-bootstrap/Card";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getUserSnips } from "../../../Redux/Snips/SnipsAction";
import { useAuth } from "../../../Utils/AuthContext";
import API from "../../../Utils/API";
import {
  getActiveSectionItem,
  getAllStores,
  getInStores,
  getOnLineStores,
  getStoresAllProduct,
  getStoresByCategory,
  getStoresProductByCategory,
  getSubCategory,
} from "../../../Redux/Stores/StoresActions";
import { StoreCard } from "./StoreCard";
import Spinner from "react-bootstrap/Spinner";
import ReactPaginate from "react-paginate";
import { leftPaginationArrow, rightPaginationArrow } from "../Offers/SectionProducts";
import { COLORS } from "../../../Utils/context/ThemeContext";
import { getDomainCountryCode } from "../../../service/location";

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    marginLeft: "12px",
    color: "#1d6042",
    cursor: "pointer",
  },
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& button": {
      "& .MuiTab-wrapper": {
        flexDirection: "row !important",
        justifyContent: "left !important",
      },
    },
  },
}));

function AllStores() {
  const classes = useStyles();
  const { userInfo } = useAuth();
  const { path, params } = useRouteMatch();
  const currPath = path.split(":")[1];
  const dispatch = useDispatch();
  const history = useHistory();
  let allCategories = [];
  const {
    userId,
    cityId,
    cityName,
    stateId,
    marketPlace: selectedRefMarketPlace,
  } = useSelector((state) => state.location);
  const {
    allStores,
    nearByStores,
    inStores,
    onlineStores,
  } = useSelector((state) => state.stores);
  const [value, setValue] = useState(0);
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [allCategoriesArray, setAllCategoriesArray] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(0)
  const [stores, setStores] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [recordPerPage] = useState(32)
  useEffect(() => { 
    API.getMainCategories(
      userInfo != null && userInfo != undefined ? userInfo.email : "gust"
    ).then((result) => {
      if (result != undefined && result.length > 0) {
        setMainCategoryList(result);
        allCategories = ["Trending"].concat(
          result.map((category) => category.name)
        );
        setAllCategoriesArray(allCategories);
        setValue(allCategories.indexOf(params.tabName));
      }
    });
  }, [])
  useEffect(() => {
    dispatch(getUserSnips(userInfo?.email, "like"));
    fetchInitialPage()
  }, [params]);
  useEffect(() => {
    fetchInitialPage();
  }, [value, mainCategoryList]);
  const fetchInitialPage = () => { 
    setStores([])
    if (params.storeName === "NearByStores") {
      fetchNearByStores(0);
    } else if (params.storeName === "InStores") {
      fetchInStores(0);
    } else if (params.storeName === "OnlineStores") {
      dispatch(
        getOnLineStores(
          userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
          cityId,
          cityName,
          stateId,
          value === 0 ? "trending" : mainCategoryList[value - 1].id,
          selectedRefMarketPlace
        )
      );
    }
  }
  const loadItemByPageId = (paheNumber) => { 
    if (params.storeName === "NearByStores") {
      fetchNearByStores(paheNumber);
    } else if (params.storeName === "InStores") {
      fetchInStores(paheNumber);
    } else if (params.storeName === "OnlineStores") {
      dispatch(
        getOnLineStores(
          userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
          cityId,
          cityName,
          stateId,
          value === 0 ? "trending" : mainCategoryList[value - 1].id,
          selectedRefMarketPlace
        )
      );
    }
  }
  const fetchNearByStores = (pageId) => {
    setIsLoading(true)
    API.getNearByStoresAPI(
      userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
      cityId,
      cityName,
      stateId,
      mainCategoryList.find(item => item.name === params.tabName)?.id ? mainCategoryList.find(item => item.name === params.tabName)?.id : "trending",
      selectedRefMarketPlace,
      pageId,
      recordPerPage
    )
      .then((response) => {
        let {
          content,
          pageable: { pageNumber },
          totalPages: numberOfPages,
        } = response;
        if (pageId === 0) {
          setStores(content);
        } else {
          setStores((oldItems) => [...oldItems, ...content]);
        }
        setCurrentPage(pageNumber);
        setTotalPages(numberOfPages);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetchInStores = (pageId) => {
    setIsLoading(true);
    API.getInStoresAPI(
      userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
      cityId,
      cityName,
      stateId,
      mainCategoryList.find((item) => item.name === params.tabName)?.id
        ? mainCategoryList.find((item) => item.name === params.tabName)?.id
        : "trending",
      selectedRefMarketPlace,
      pageId,
      recordPerPage
    )
      .then((response) => {
        let {
          content,
          pageable: { pageNumber },
          totalPages: numberOfPages,
        } = response;
        if (pageId === 0) {
          setStores(content);
        } else {
          setStores((oldItems) => [...oldItems, ...content]);
        }
        setCurrentPage(pageNumber);
        setTotalPages(numberOfPages);
      })
      .finally(() => {
        setIsLoading(false);
      });;
  };
  const cardClick = (newValue) => {
    history.push(`/storeDetails/` + newValue);
    // console.log("Under Development..............")
  };

  const handleChange = (event, newValue) => {
    const tabName = event.target.innerText;
    setValue(newValue);

    if (tabName !== "NEW") {
        if (window.location.href.split("/")[4] === "OnlineStores") {
        dispatch(
          getOnLineStores(
            userInfo != null && userInfo !== undefined ? userInfo.email : "gust",
            cityId,
            cityName,
            stateId,
            tabName === "TRENDING" ? "trending" : mainCategoryList[newValue - 1].id,selectedRefMarketPlace
          )
        );
      }
    } else {
      const searchQuery = API.generateQueryString({
        user:API.encodedUserId(userInfo?.email || userId),
        cityId,
        stateId,
        viewType: "offer",
        marketPlace: selectedRefMarketPlace,
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc: getDomainCountryCode() === "CA" ? true : false
      });
      dispatch(
        getAllStores(userInfo?.email || userId, cityId, stateId, "offer",selectedRefMarketPlace)
      );
      dispatch(
        getStoresAllProduct(searchQuery)
      );
      dispatch(
        getActiveSectionItem(
          userInfo?.email || userId,
          "Trending",
          cityId,
          stateId,
          "Offer",
          selectedRefMarketPlace
        )
      );
    }
  };

  const handleMenuChange = (newValue, index) => {
    //const tabName = event.target.innerText;
    history.push(`${currPath}${newValue}`);
    setValue(newValue);
    setSelectedIndex(index);
    var CatId = "";
    mainCategoryList.map((item, index) => {
      if (item.name == newValue) CatId = item.id;
    });

    dispatch(
      getStoresByCategory(
        userInfo?.email || userId,
        CatId,
        cityId,
        stateId,
        "offer"
      )
    );
    dispatch(
      getStoresProductByCategory({
        user: API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
        categoryId: CatId,
        cityId,
        stateId,
        viewType: "offer",
        marketPlace: selectedRefMarketPlace,
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc:false
      })
    );
  };

  return (
    <>
      {isLoading && (
        <Spinner
          animation="border"
          role="status"
          style={{ position: "absolute", left: "50%", top: "50%", color:COLORS.primary.main }}
        />
      )}
      <Grid container className="mt-3">
        <Grid item xs={2}>
          <div className="menu-tab">
            <i
              style={{ ...style.backButton, position: "relative" }}
              className="fa fa-arrow-circle-left"
              aria-hidden="true"
              title="Back to Login"
              onClick={() =>
                history.push(`/stores/${window.location.href.split("/")[5]}`)
              }
            ></i>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="off"
              className={classes.tabs}
              orientation="vertical"
            >
              {allCategoriesArray.map((item, index) => (
                <Tab
                  {...a11yProps(index)}
                  label={item}
                  key={item}
                  onClick={() =>
                    history.push(`/All/${params.storeName}/${item}`)
                  }
                  style={{ minWidth: "120px", textAlign: "left" }}
                  wrapped
                />
              ))}
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={10}>
          <h4 className="ml-5">
            <strong>
              {(params.storeName === "NearByStores" &&
                "STORES FROM YOUR CITY") ||
                (params.storeName === "InStores" &&
                localStorage.getItem("connectSite") == "CA"
                  ? "CANADA-WIDE STORES"
                  : "US-WIDE STORES") ||
                (params.storeName === "OnlineStores" && "ONLINE STORES")}
            </strong>
          </h4>
          <Grid container className="ml-5 mt-3">
            {stores.map((item, index) => (
              <div style={{ minWidth: "340px" }}>
                <StoreCard
                  branchName={item.storeName}
                  storeId={item.storeId}
                  offerId={item.id}
                  img={
                    item.primaryOfferImage
                      ? item.primaryOfferImage
                      : item.companyLogo
                  }
                  branchLogo={item.companyLogo}
                  logoSmall={item.logoSmall}
                  logoMedium={item.logoMedium}
                  heading={item.heading}
                  avgRating={item.avgRating}
                  type={item.type}
                  totalLikes={item.totalLikes}
                  key={item.id}
                />
              </div>
            ))}
            {!isLoading && stores.length === 0 && (
              <h4>Coming up soon in future in {params.tabName}</h4>
            )}
          </Grid>
        </Grid>
      </Grid>
      {totalPages > 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ReactPaginate
            forcePage={currentPage}
            pageCount={totalPages}
            pageRangeDisplayed={3} // Number of pages to display in the pagination
            marginPagesDisplayed={1} // Number of pages to display on the edges
            previousLabel={leftPaginationArrow}
            nextLabel={rightPaginationArrow}
            breakLabel={"..."}
            containerClassName={"pagination"}
            activeClassName={"active"}
            disabledClassName={"disabled"}
            onPageChange={(e) => {
              const selectedPage = e.selected;
              setCurrentPage(selectedPage);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}
    </>
  );
}

export default AllStores;
