import React from "react";
export function EntypoShoppingBag(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        d="M18.121 3.271c-.295-.256-1.906-1.731-2.207-1.991c-.299-.259-.756-.28-1.102-.28H5.188c-.345 0-.802.021-1.102.28c-.301.26-1.912 1.736-2.207 1.991c-.297.256-.543.643-.464 1.192c.079.551 1.89 13.661 1.937 13.973A.67.67 0 0 0 4 19h12a.67.67 0 0 0 .648-.565c.047-.311 1.858-13.422 1.938-13.973c.078-.548-.168-.935-.465-1.191M10 11.973c-3.248 0-3.943-4.596-4.087-5.543H7.75c.276 1.381.904 3.744 2.25 3.744s1.975-2.363 2.25-3.744h1.838c-.145.947-.84 5.543-4.088 5.543M3.17 4.006L5 2h10l1.83 2.006z"
      ></path>
    </svg>
  );
}

export function EntypoCartPlus(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.19em"
      height="1em"
      viewBox="0 0 1664 1408"
      {...props}
    >
      <path
        fill="currentColor"
        d="M1216 448q0-26-19-45t-45-19h-128V256q0-26-19-45t-45-19t-45 19t-19 45v128H768q-26 0-45 19t-19 45t19 45t45 19h128v128q0 26 19 45t45 19t45-19t19-45V512h128q26 0 45-19t19-45m-576 832q0 53-37.5 90.5T512 1408t-90.5-37.5T384 1280t37.5-90.5T512 1152t90.5 37.5T640 1280m896 0q0 53-37.5 90.5T1408 1408t-90.5-37.5t-37.5-90.5t37.5-90.5t90.5-37.5t90.5 37.5t37.5 90.5m128-1088v512q0 24-16 42.5t-41 21.5L563 890q1 7 4.5 21.5t6 26.5t2.5 22q0 16-24 64h920q26 0 45 19t19 45t-19 45t-45 19H448q-26 0-45-19t-19-45q0-14 11-39.5t29.5-59.5t20.5-38L268 128H64q-26 0-45-19T0 64t19-45T64 0h256q16 0 28.5 6.5t20 15.5t13 24.5T389 73t5.5 29.5T399 128h1201q26 0 45 19t19 45"
      ></path>
    </svg>
  );
}