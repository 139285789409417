import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Form, Button, Alert} from "react-bootstrap";
import { useAuth } from "../../Utils/AuthContext";
import API from "../../Utils/API";
import { COLORS } from "../../Utils/context/ThemeContext";

export default function ForgotPasswordForm(props) {
  const { handleClose, handleOpen, open } = props;
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    phoneNo: "",
    gender: "",
  });
  const [inputType, setInputType] = useState("password");
  const [btnDisable, setBtnDisable] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleChange = (e) => {
    setErrMsg("");
    setBtnDisable(false);
    const { name, value } = e.target;

    if (name === "password" && value.length > 10) return;
    if (name === "phoneNo" && value.length > 10) return;

    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const toggleInputType = (type) => setInputType(type);

  const { signUp } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setBtnDisable(true);
      const res = await signUp(user.email, user.password);
      res.user.updateProfile({
        displayName: user.name,
        phoneNumber: user.phoneNo,
        gender: user.gender,
      });
      let userBody = {
        email:user.email,
        emailOk:"Y",
        emailSubcription:"Y",
        name:user.name,
        mob:user.phoneNo,
        mobOk:"Y",
        authType:"AU",
        password:"",
        country:"",
        gender:user.gender,
        companyId:"",
        roleId:""//Store Admin role id

       };
      API.createUser(user.email,userBody)
      API.createUserUS(user.email,userBody)
      setSuccessMsg("User Registered Successfully");
      handleClose("register");
      handleClose("login");
      setUser({
        name: "",
        email: "",
        password: "",
        phoneNo: "",
        gender: "",
      });
      setSuccessMsg("");
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    } catch (err) {
      console.log(err);
      setErrMsg(err.code.split("/")[1]);
    }
    setBtnDisable(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => handleClose("register")}
      aria-labelledby="console.log-dialog-slide-title"
      aria-describedby="console.log-dialog-slide-description"
    >
      <Form className="RegisterForm" onSubmit={handleSubmit}>
        {errMsg && <Alert variant="danger">{errMsg}</Alert>}
        {successMsg && <Alert variant="success">{successMsg}</Alert>}
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="name"
            value={user.name}
            onChange={handleChange}
            type="text"
            placeholder="Enter Your Name"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="email"
            value={user.email}
            onChange={handleChange}
            type="email"
            placeholder="Enter email"
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <div className="loginPasswordInput">
            <Form.Control
              name="password"
              className="passwordField"
              value={user.password}
              onChange={handleChange}
              type={inputType}
              placeholder="Password"
              required
            />
            {inputType === "password" ? (
              <i
                className="fa fa-eye-slash"
                aria-hidden="true"
                onClick={() => toggleInputType("text")}
              ></i>
            ) : (
              <i
                className="fa fa-eye"
                aria-hidden="true"
                onClick={() => toggleInputType("password")}
              ></i>
            )}
          </div>
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone Number (Optional)</Form.Label>
          <Form.Control
            name="phoneNo"
            value={user.phoneNo}
            onChange={handleChange}
            type="text"
            placeholder="Phone No."
            //required
          />
        </Form.Group>
        {/* <Form.Group>
          <div key="custom-inline-radio" className="mb-3">
            <Form.Check
              // custom
              inline
              label="Male"
              type="radio"
              id="custom-inline-radio-1"
              name="gender"
              value="Male"
              onChange={handleChange}
              // checked={user.gender === "Male" && false}
              required
            />
            <Form.Check
              // custom
              inline
              label="Female"
              type="radio"
              id="custom-inline-radio-2"
              name="gender"
              value="Female"
              onChange={handleChange}
              // checked={user.gender === "Female" && false}
              required
            />
          </div>
        </Form.Group> */}
        <Button
          disabled={btnDisable}
          type="submit"
          className="w-100"
          style={{
            backgroundColor:COLORS.primary.main
          }}
        >
          Register
        </Button>
        <div className="otherFormOptions">
          <p
            className="loginFormLink"
            onClick={() => {
              handleOpen("login");
              handleClose("register1");
            }}
          >
            Existing User ? Login here !
          </p>
        </div>
      </Form>
    </Dialog>
  );
}
