import React from "react";
export default function HyperLinkText(props) {
    return (
        <a
            style={{ paddingLeft: "10px", ...props.style }}
            href={props.href}
            onClick={props.onClick}
            {...props}
        >
            {props.label}
        </a>
    );
}
