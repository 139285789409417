import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import { getUserSnips, removeSnipsData } from "../../Redux/Snips/SnipsAction";
import API from "../../Utils/API";
import { useAuth } from "../../Utils/AuthContext";
import Checkbox from "@material-ui/core/Checkbox";
import StoresHeader from "./Stores/StoresHeader";

function ShopifyPrivacyPolicy() {
  return (
    <>
      <div class="wpb_wrapper mt-4">
        
        <>
 
  <style
    dangerouslySetInnerHTML={{
      __html:
        '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:Helvetica;\n\tpanose-1:2 11 6 4 2 2 2 2 2 4;}\n@font-face\n\t{font-family:Wingdings;\n\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n@font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n /* Style Definitions */\n p.MsoNormal ml-4, li.MsoNormal ml-4, div.MsoNormal ml-4\n\t{margin-top:0cm;\n\tmargin-right:0cm;\n\tmargin-bottom:8.0pt;\n\tmargin-left:0cm;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:"Calibri",sans-serif;}\n.MsoChpDefault\n\t{font-family:"Calibri",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:8.0pt;\n\tline-height:107%;}\n@page WordSection1\n\t{size:612.0pt 792.0pt;\n\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0cm;}\nul\n\t{margin-bottom:0cm;}\n-->\n'
    }}
  />
  <div className="WordSection1">
    <p
      className="MsoNormal"
      align="center"
      style={{
        marginBottom: "12.0pt",
        textAlign: "center",
        lineHeight: "45.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "25.0pt",
            fontFamily: '"Helvetica",sans-serif',
            color: "black"
          }}
        >
          ShopiAds Inc Privacy Policy
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        Last updated: Feb 10, 2024
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        ShopiAds "the Sales channel” provides a Marketplace to promote products and sell directly through store web-portal and in-store. "the Service" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.
      </span>
    </p>
    
    
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "18.0pt",
            fontFamily: '"Helvetica",sans-serif',
            color: "black"
          }}
        >
          Personal Information the App Collects
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        When you install the App, we are automatically able to access certain types of information from your Shopify account: 
      </span>
    </p>
   
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        We are using following Api’s
      </span>
    </p>
    <ul style={{ marginTop: "0cm" }} type="disc">
      <li
        className="MsoNormal ml-4"
        style={{
          color: "black",
          marginBottom: "12.0pt",
          lineHeight: "normal",
          background: "white"
        }}
      >
       
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Helvetica",sans-serif' }}
        >
          Shop Admin Rest Api to get  shop details like address,store name ,email and number and use as default pre fields value for smooth onboarding

        </span>
      </li>
      <li
        className="MsoNormal ml-4"
        style={{
          color: "black",
          marginBottom: "12.0pt",
          lineHeight: "normal",
          background: "white"
        }}
      >
        
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Helvetica",sans-serif' }}
        >
         Product Admin Rest Api to get product information like Description, Variant, Image link and Price
        </span>
      </li>
      <li
        className="MsoNormal ml-4"
        style={{
          color: "black",
          marginBottom: "12.0pt",
          lineHeight: "normal",
          background: "white"
        }}
      >
        
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Helvetica",sans-serif' }}
        >
          Collection Admin Rest Api to get collection information like Description, Image link and Associated products
        </span>
      </li>

      <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        Additionally, we collect the following types of personal information from you once you have installed the App: 

      </span>
    </p>

      <li
        className="MsoNormal ml-4"
        style={{
          color: "black",
          marginBottom: "12.0pt",
          lineHeight: "normal",
          background: "white"
        }}
      >
        
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Helvetica",sans-serif' }}
        >
          Store Registration Number and Name, Store Address, Email and Contact number, Store policies and Store operational hours
        </span>
      </li>
      <li
        className="MsoNormal ml-4"
        style={{
          color: "black",
          marginBottom: "12.0pt",
          lineHeight: "normal",
          background: "white"
        }}
      >
       
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Helvetica",sans-serif' }}
        >
          We collect email address and order information of the customers routed through ShopiAds Marketplace so we can relate the purchases for billing reports.
        </span>
      </li>
      <li
        className="MsoNormal ml-4"
        style={{
          color: "black",
          marginBottom: "12.0pt",
          lineHeight: "normal",
          background: "white"
        }}
      >
       
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Helvetica",sans-serif' }}
        >
          We collect personal information of customers directly from the relevant individual, through ShopiAds marketplace accounts and track their purchases using utm_source parameter which are updated in Store order after the purchase which are read through webhook for further processing of billing
        </span>
      </li>
      
      
    </ul>
    
    
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "27.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "18.0pt",
            fontFamily: '"Helvetica",sans-serif',
            color: "black"
          }}
        >
          How Do We Use Your Personal Information?
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services.
      </span>
    </p>
   
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "27.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "18.0pt",
            fontFamily: '"Helvetica",sans-serif',
            color: "black"
          }}
        >
          How Do We Use Customer data?
        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        While using Our Service, we collect customer First Name, Last Name, Phone and Email address of orders referred by ShopiAds. And provide detailed report of all the conversion done through ShopiAds so merchants can verify with their system.
      </span>
    </p>

    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        Data transfer mechanism
      </span>
    </p>
    <ul style={{ marginTop: "0cm" }} type="disc">
      <li
        className="MsoNormal ml-4"
        style={{
          color: "black",
          marginBottom: "12.0pt",
          lineHeight: "normal",
          background: "white"
        }}
      >
       
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Helvetica",sans-serif' }}
        >
          All data is processed using secure layer at all stages and authentication is done using firebase which is encrypted, So only user can access or change their credentials.

        </span>
      </li>
      </ul>
      

      <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        Scope of data processed 
      </span>
    </p>
    <ul style={{ marginTop: "0cm" }} type="disc">
      <li
        className="MsoNormal ml-4"
        style={{
          color: "black",
          marginBottom: "12.0pt",
          lineHeight: "normal",
          background: "white"
        }}
      >
       
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Helvetica",sans-serif' }}
        >
          First Name, Last Name, Phone and Email address.

        </span>
      </li>
      </ul>


      <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        Retention 
      </span>
    </p>
    <ul style={{ marginTop: "0cm" }} type="disc">
      <li
        className="MsoNormal ml-4"
        style={{
          color: "black",
          marginBottom: "12.0pt",
          lineHeight: "normal",
          background: "white"
        }}
      >
       
        <span
          style={{ fontSize: "12.0pt", fontFamily: '"Helvetica",sans-serif' }}
        >
          Provision is provided on platform to Unregistered user so Customer can delete their data whenever required.

        </span>
      </li>
      </ul>


    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "27.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "18.0pt",
            fontFamily: '"Helvetica",sans-serif',
            color: "black"
          }}
        >
          Sharing Your Personal Information

        </span>
      </b>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.

      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
       You have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        Additionally, please note that your information will be used in Canada and the United States

      </span>
    </p>
    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        Data Retention When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information or if customer deletes the account on the ShopiAds marketplace

      </span>
    </p>

    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.

      </span>
    </p>

    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "45.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "18.0pt",
            fontFamily: '"Helvetica",sans-serif',
            color: "black"
          }}
        >
          Contact Us
        </span>
      </b>
    </p>

    <p
      className="MsoNormal"
      style={{
        marginBottom: "12.0pt",
        lineHeight: "normal",
        background: "white"
      }}
    >
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          color: "black"
        }}
      >
        Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at compliance@shopiads.com

      </span>
    </p>


    
  </div>
</>

      </div>
    </>
  );
}

export default ShopifyPrivacyPolicy;
