import React, { useEffect, useState } from "react";
import "../Container.css";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllStores,
  getStoresAllProduct,
  getStoresByCategory,
  getStoresProductByCategory,
} from "../../../Redux/Stores/StoresActions";
import OffersHeader from "./OffersHeader";
import ListItem from "@material-ui/core/ListItem";
import API from "../../../Utils/API";
import { useHistory, useRouteMatch } from "react-router-dom";
import ProductCard from "../product/ProductCard";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "./style.css";
import { OfferCard } from "./OfferCard";
import { COLORS } from "../../../Utils/context/ThemeContext";
import { getDomainCountryCode } from "../../../service/location";

function Offers() {
  const { userInfo } = useAuth();
  const {
    loading,
    sectionLoading,
    productData,
  } = useSelector((state) => state.stores);
  const {
    userId,
    cityId,
    cityName,
    stateId,
    marketPlace: selectedRefMarketPlace,
  } = useSelector((state) => state.location);
  const [sectionInfo, setSectionInfo] = useState({
    loading: true,
    sections: [],
  });
  const { params } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const [items, setItems] = useState(productData?.items || [])
  const [recordPerPage, setRecordPerPage] = useState(32)
  
  useEffect(() => {
    if (productData?.items) {
      setItems(productData.items)      
    }
  }, [productData])
  useEffect(() => {
    const userQueryId = userInfo != null && userInfo !== undefined ? userInfo.email : "gust";
    API.getActiveSectionItemByPage(
      userId,
      params.tabName,
      cityId,
      stateId,
      "Offer",
      selectedRefMarketPlace,
      1,
      32
    ).then(data => {
      console.log({data})
      setSectionInfo({
        loading: false,
        sections: data,
      });
    }).catch(() => { 
      setSectionInfo({
        loading: false,
        sections: []
      });
    })
    if (
      cityName &&
      stateId &&
      (params.tabName === "Trending" || params.tabName === "New")
    ) {
      dispatch(
        getAllStores(
          userId,
          cityId,
          stateId,
          "offer",
          selectedRefMarketPlace
        )
      );
      const searchQuery = API.generateQueryString({
        user: API.encodedUserId(userQueryId),
        cityId,
        stateId,  
        viewType: "offer",
        marketPlace: selectedRefMarketPlace,
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc: getDomainCountryCode() === "CA" ? true : false
      });
      dispatch(
        getStoresAllProduct(searchQuery)
      );
    } else {
      API.getMainCategories(
        userId
      )
        .then((result) => {
          console.log("result.data>>>>>>>>>>>>>>>>>>", result);
          if (result != undefined && result.length > 0) {
            const id = result.filter((e) => e.name === params.tabName)[0].id;
            dispatch(
              getStoresByCategory(
                userInfo?.email || userId,
                id,
                cityId,
                stateId,
                "offer",
                selectedRefMarketPlace
              )
            );
            dispatch(
              getStoresProductByCategory({
                user:API.encodedUserId(
                  userInfo != null && userInfo !== undefined
                    ? userInfo.email
                    : "gust"),
                categoryId: id,
                cityId,
                stateId,
                viewType: "offer",
                marketPlace: selectedRefMarketPlace,
                pageNo: 0,
                recordPerPage,
                orderBy: "createdOn",
                asc:false
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // console.log(params.tabName);
    /* if (params.tabName != "All" && params.tabName != "New") {
       API.subCategoriesListByName(userInfo.email,params.tabName).then((result) => {  
         console.log("result.data>>>>>>>>>>>>>>>>>>",result);
           if(result!=undefined && result.length>0)
           {
               //console.log('data=='+result.data);
               setSubCategories(result)
             
             
           }
           else
           setSubCategories([])
 
               })
               .catch((error) => {
                 console.log(error);
                 console.log(error);
               });
     }
     else
     {
       console.log("else condation>>>>>>>>>>>>>>>>>>",params.tabName);
       API.getAllSubCategories(userInfo!=null && userInfo!=undefined?userInfo.email:"gust").then((result) => {  
         console.log("result.data>>>>>>>>>>>>>>>>>>",result);
           if(result!=undefined && result.length>0)
           {
               //console.log('data=='+result.data);
               setSubCategories(result)
             
             
           }
               })
               .catch((error) => {
                 console.log(error);
                 console.log(error);
               });
     }*/
  }, [params.tabName,selectedRefMarketPlace]);

  const viewAllProductsClick = (newValue) => {
    history.push(newValue);
    // history.replace(newValue);
  };

  SwiperCore.use([Navigation, Pagination]);

  function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;
    
    if (img.complete) {
      return true;
    } else {
      img.onload = () => {
        return true;
      };
      
      img.onerror = () => {
        return false;
      };
    }
  }

  return (
    <>
      {!loading ? (
        <>
          <OffersHeader />
          {/* <pre>{JSON.stringify(allStores, null, 2)}</pre> */}
          <div className="container-fluid storeinfo-wrapper">
            <div className="row">
              <div className="container-fluid mt-4">
                <div className="row">
                  <div className="col-md-9 ml-auto offset-sm-mr-1">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h4 className="font-weight-bold text-uppercase mb-0">
                            FEATURED DEALS
                          </h4>
                          {/* <button
                            onClick={() => {
                              viewAllProductsClick(
                                `/AllOffers/FeaturedDeals/${params.tabName}`
                              );
                            }}
                            className="shopee-button-no-outline"
                          >
                            View All &nbsp;
                            <svg
                              enable-background="new 0 0 11 11"
                              viewBox="0 0 11 11"
                              x="0"
                              y="0"
                              height="12px"
                              width="12px"
                              className="icon-arrow-right"
                            >
                            <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                            </svg>
                          </button> */}
                          {}
                          <button
                            onClick={() => {
                              viewAllProductsClick(
                                `/AllProducts/FeaturedDeals/${params.tabName}`
                              );
                            }}
                            className="shopee-button-no-outline"
                            style={{
                              color: COLORS.primary.main,
                              fontWeight: "bold",
                            }}
                          >
                            View All &nbsp;
                            <svg
                              enable-background="new 0 0 11 11"
                              viewBox="0 0 11 11"
                              x="0"
                              y="0"
                              height="12px"
                              width="12px"
                              className="icon-arrow-right"
                            >
                              <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="offer-card-main">
                          {items.length <= 0 && (
                            <div>Coming up soon in future!</div>
                          )}
                          <Swiper
                            spaceBetween={10}
                            slidesPerView={4}
                            slidesPerGroup={4}
                            // removeClippedSubviews={false}
                            navigation
                            loop={items.length > 4 ? false : false}
                            //pagination={{ clickable: true }}
                            onSlideChange={(swiper) => {
                              console.log("swiper");
                              // handlePause(swiper.previousIndex);
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                          >
                            {items.map((item, index) => (
                              <SwiperSlide>
                                <ProductCard
                                  branchName={item.storeName}
                                  storeId={item.storeId}
                                  productId={item.id}
                                  img={item.images}
                                  branchLogo={item.logo}
                                  heading={item.heading}
                                  avgRating={item.avgRating}
                                  discount={item.discountPerc}
                                  actualPrice={item.actualPrice}
                                  offerPrice={item.offerPrice}
                                  totalLikes={item.totalLikes}
                                  key={item.id}
                                />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      </div>

                      {/* <div className="col-md-12">
                        <div className="offer-card-main">
                          <Swiper
                            spaceBetween={10}
                            slidesPerView={3}
                            slidesPerGroup={3}
                            navigation
                            loop={allStores.length > 3 ? true : false}
                           
                            onSlideChange={(swiper) => {
                              console.log("swiper",swiper);
                              
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                          >
                            {allStores !== undefined
                              ? allStores.map((item, index) => (
                                
                                 
                                  <SwiperSlide>
                                      <OfferCard
                                        branchName={item.storeName}
                                        storeId={item.storeId}
                                        offerId={item.id}
                                        img={item.fullImage}
                                        branchLogo={item.logo}
                                        logoSmall={item.logoSmall}
                                        heading={item.heading}
                                        avgRating={item.avgRating}
                                        totalLikes={item.totalLikes}
                                        key={item.id}
                                        imageWidth={'fullWidth'}
                                      />
                                  </SwiperSlide>//:""
                                ))
                              : ""}
                          </Swiper>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="container-fluid mt-4"> */}
              {/* <div className="container-fluid mt-4">
                <div className="row">
                  <div className="col-md-9 ml-auto offset-sm-mr-1">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h4 className="font-weight-bold text-uppercase mb-0"></h4>

                          <button
                            onClick={() => {
                              viewAllProductsClick(
                                `/AllProducts/FeaturedDeals/${params.tabName}`
                              );
                            }}
                            className="shopee-button-no-outline"
                          >
                            View All &nbsp;
                            <svg
                              enable-background="new 0 0 11 11"
                              viewBox="0 0 11 11"
                              x="0"
                              y="0"
                              height="12px"
                              width="12px"
                              className="icon-arrow-right"
                            >
                              <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                            </svg>
                          </button>

                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="offer-card-main">
                          <Swiper
                            spaceBetween={10}
                            slidesPerView={4}
                            slidesPerGroup={4}
                            navigation
                            loop={productData && productData.length > 4 ? true : false}
                            // pagination={{ clickable: true }}
                            onSlideChange={(swiper) => {
                              console.log("swiper");
                              // handlePause(swiper.previousIndex);
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                          >
                            {productData !== undefined
                              ? productData.map((item, index) => (
                                  <SwiperSlide>
                                      <ProductCard
                                        branchName={item.storeName}
                                        storeId={item.storeId}
                                        productId={item.id}
                                        img={item.images}
                                        branchLogo={item.logo}
                                        heading={item.heading}
                                        avgRating={item.avgRating}
                                        discount={item.discountPerc}
                                        actualPrice={item.actualPrice}
                                        offerPrice={item.offerPrice}
                                        totalLikes={item.totalLikes}
                                        key={item.id}
                                      />
                                  </SwiperSlide>
                                ))
                              : ""}
                          </Swiper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {!sectionInfo.loading ? (
                sectionInfo.sections.map((item) => (
                  <div className="container-fluid">
                    {item.offers?.content.length > 0 && (
                      <div className="row my-4">
                        <div className="col-md-9 ml-auto offset-sm-mr-1">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <h4 className="font-weight-bold text-uppercase mb-0">
                                  {item.section.name} Offers
                                </h4>
                                {item.offers.content.length >= 3 && (
                                  <button
                                    onClick={() => {
                                      history.push(
                                        `/${item.section.id}/allOffers/${
                                          params.tabName
                                        }`
                                      );
                                    }}
                                    className="shopee-button-no-outline"
                                    style={{
                                      color: COLORS.primary.main,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    View All &nbsp;
                                    <svg
                                      enable-background="new 0 0 11 11"
                                      viewBox="0 0 11 11"
                                      x="0"
                                      y="0"
                                      height="12px"
                                      width="12px"
                                      className="icon-arrow-right"
                                    >
                                      <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                                    </svg>
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="offer-card-main">
                                <Swiper
                                  spaceBetween={10}
                                  slidesPerView={3}
                                  slidesPerGroup={3}
                                  navigation
                                  loop={
                                    item.offers.content.length > 3
                                      ? true
                                      : false
                                  }
                                  // pagination={{ clickable: true }}
                                  onSlideChange={(swiper) => {
                                    console.log("swiper");
                                    // handlePause(swiper.previousIndex);
                                  }}
                                  onSwiper={(swiper) => console.log(swiper)}
                                >
                                  {item.offers.content.map((item, index) => (
                                    <SwiperSlide>
                                      <div
                                        style={{
                                          minWidth: "350px",
                                        }}
                                      >
                                        <OfferCard
                                          branchName={item.storeName}
                                          storeId={item.storeId}
                                          offerId={item._id}
                                          img={item.fullImage}
                                          branchLogo={item.logo}
                                          logoSmall={item.logoSmall}
                                          heading={item.heading}
                                          avgRating={item.avgRating}
                                          totalLikes={item.totalLikes}
                                          key={item._id}
                                        />
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {item.products?.content.length>0 && (
                      <div className="row my-4">
                        <div className="col-md-9 ml-auto offset-sm-mr-1">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <h4 className="font-weight-bold text-uppercase mb-0">
                                  {item.section.name} Products
                                </h4>
                                {item.products.content.length >= 3 && (
                                  <button
                                    onClick={() => {
                                      history.push(
                                        `/${item.section.id}/allProducts/${
                                          params.tabName
                                        }`
                                      );
                                    }}
                                    className="shopee-button-no-outline"
                                    style={{
                                      color: COLORS.primary.main,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    View All &nbsp;
                                    <svg
                                      enable-background="new 0 0 11 11"
                                      viewBox="0 0 11 11"
                                      x="0"
                                      y="0"
                                      height="12px"
                                      width="12px"
                                      className="icon-arrow-right"
                                    >
                                      <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                                    </svg>
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="offer-card-main">
                                <Swiper
                                  spaceBetween={10}
                                  slidesPerView={4}
                                  slidesPerGroup={4}
                                  navigation
                                  loop={
                                    item.products.content.length > 4
                                      ? true
                                      : false
                                  }
                                  // pagination={{ clickable: true }}
                                  onSlideChange={(swiper) => {
                                    console.log("swiper");
                                    // handlePause(swiper.previousIndex);
                                  }}
                                  onSwiper={(swiper) => console.log(swiper)}
                                >
                                  {item.products.content.map((item, index) => (
                                    <SwiperSlide>
                                      <div>
                                        <ProductCard
                                          branchName={item.storeName}
                                          storeId={item.storeId}
                                          productId={item._id}
                                          img={item.images}
                                          branchLogo={item.logo}
                                          heading={item.heading}
                                          avgRating={item.avgRating}
                                          discount={item.discountPerc}
                                          actualPrice={item.actualPrice}
                                          offerPrice={item.offerPrice}
                                          totalLikes={item.totalLikes}
                                          key={item._id}
                                        />
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <strong className="mt-4" align="center">
                  Loading Offer Section data ....
                </strong>
              )}
            </div>
          </div>
        </>
      ) : (
        <strong>Loading ....</strong>
      )}
    </>
  );
}

export default Offers;
